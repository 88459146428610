import React from "react";
import {
  InputGroup,
  Form,
  FormControl,
  Dropdown,
  DropdownButton,
  Card,
  Button,
  Row,
  Col,
  Container,
  Collapse,
} from "react-bootstrap";
import { Link, Route } from "react-router-dom";
import { BiRectangle } from "react-icons/bi";
import { FaHome } from "react-icons/fa";
import { BsPlusSquareFill } from "react-icons/bs";
import ios from "./images/qrcodes/apple.png";
import android from "./images/qrcodes/android.png";
import appstore from "./images/app-store.png";
import googlestore from "./images/google-play.png";
import phone from "./images/mob-app.png";

class Pricing extends React.Component {
  state = {
    colOne: false,
    colTwo: false,
    colThree: false,
    colFour: false,
    colFive: false,
    colSix: false,
    colSeven: false,
    colEight: false,
    colNine: false,
    colTen: false,
    colEleven: false,
    colTwelve: false,
    colThirteen: false,
  };

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  toggleCollapse = (event) => {
    this.setState({
      [event.target.name]: !this.state[event.target.name],
    });
  };

  openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  price = () => {
    return (
      <div className="price-page">
        <Container fluid className="price-jumbo">
          <Row>
            <Col sm={12}>
              <div className="jumbo-content-left">
                <h2>OUR PLANS</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="parallex-container">
          <Container
            fluid
            className="price-jumbo-parallex"
            style={{ position: "relative" }}
          >
            <div className="parallex-content">
              <br />
              <br />
              <div className="rectangle" />
              <h2 className="parallex-text">MOST POPULAR PLAN</h2>
              <div className="parallex-subtext">
                <span>
                  InvestFar is a real estate marketplace that uses powerful and
                  smart technologies to automate investing remotely, with access
                  to human experts for guidance.
                </span>
              </div>
              <div className="line" />
            </div>
            <Container className="pricing-cards-container">
              <Row className="price-rows">
                <Col sm={3}>
                  <Card className="price-card">
                    <Card.Header className="pricing-card-header">
                      <h5 className="card-header-text">À la carte Services</h5>
                      <div className="icon-container">
                        <FaHome
                          className=""
                          size={50}
                          color="rgb(35,119,186)"
                        />
                      </div>
                    </Card.Header>
                    <Card.Body className="price-card-body">
                      <h4>Per Service</h4>
                      <ul>
                        <li>$8.99 Per Valuation Report</li>
                        <li>$45.99 Mobile Notarization</li>
                        <li>$24.99 20 additional photos</li>
                        <li>$24.99 1-Minute Property Video</li>
                        <li>
                          $84.99 Residential Property Inspection (Exterior Only)
                        </li>
                        <li>
                          $99.99 Residential Property Inspection (Exterior &
                          Interior)
                        </li>
                        <li>
                          $184.99 Document pick-up & Delivery (Notary & title
                          closing)
                        </li>
                      </ul>
                      <Link
                        className="text-center"
                        to={{
                          pathname: "/payment",
                          plan: "À la carte Services",
                          toPay: true,
                        }}
                      >
                        <Button className="access-button btn-blue">
                          Get Access
                        </Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={3}>
                  <Card className="price-card">
                    <Card.Header className="novice-header">
                      <h5 className="card-header-text">Novice</h5>
                      <div className="icon-container">
                        <FaHome
                          className=""
                          size={50}
                          color="rgb(35,119,186)"
                        />
                      </div>
                    </Card.Header>
                    <Card.Body className="price-card-body">
                      <h4>$9.99/mo</h4>
                      <ul>
                        <li>5 Valuation / Analytic reports</li>
                        <li>Investor Consultants</li>
                        <li>Directory Access</li>
                        <li>InvestFar App</li>
                        <li>Free listing</li>
                        <li>Blog</li>
                        <li>$7.99 Per Valuation Report</li>
                        <li>$41.99 Mobile Notarization</li>
                        <li>$19.99 20 additional photos</li>
                        <li>$19.99 1-Minute Property Video</li>
                        <li>
                          $74.99 Residential Property Inspection (Exterior Only)
                        </li>
                        <li>
                          $94.99 Residential Property Inspection (Exterior &
                          Interior)
                        </li>
                        <li>
                          $84.99 Documents pick-up & Delivery (Notary docs,
                          notices etc)
                        </li>
                      </ul>
                      <Link
                        className="text-center"
                        to={{
                          pathname: "/payment",
                          plan: "Novice",
                          price: 24.99,
                          toPay: true,
                        }}
                      >
                        <Button className="access-button btn-blue">
                          Get Access
                        </Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={3}>
                  <Card className="price-card">
                    <Card.Header className="pricing-card-header">
                      <h5 className="card-header-text">Apprentice</h5>
                      <div className="icon-container">
                        <FaHome
                          className=""
                          size={50}
                          color="rgb(35,119,186)"
                        />
                      </div>
                    </Card.Header>
                    <Card.Body className="price-card-body">
                      <h4>$54.99/mo</h4>
                      <ul>
                        <li>15 Valuation / Analytic reports</li>
                        <li>Investor Consultants</li>
                        <li>Directory Access</li>
                        <li>InvestFar App</li>
                        <li>Free listing</li>
                        <li>Blog</li>
                        <li>Newsletter</li>
                        <li>Monthly Market report alerts</li>
                        <li>
                          FREE or discounted real estate seminars and events
                        </li>
                        <li>$7.99 Per Valuation Report</li>
                        <li>$41.99 Mobile Notarization</li>
                        <li>$19.99 20 additional photos</li>
                        <li>$19.99 1-Minute Property Video</li>
                        <li>
                          $74.99 Residential Property Inspection (Exterior Only)
                        </li>
                        <li>
                          $94.99 Residential Property Inspection (Exterior &
                          Interior)
                        </li>
                        <li>
                          $84.99 Documents pick-up & Delivery (Notary docs,
                          notices etc)
                        </li>
                      </ul>
                      <Link
                        className="text-center"
                        to={{
                          pathname: "/payment",
                          plan: "Apprentice",
                          price: 54.99,
                          toPay: true,
                        }}
                      >
                        <Button className="access-button btn-blue">
                          Get Access
                        </Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={3}>
                  <Card className="price-card">
                    <Card.Header className="pricing-card-header">
                      <h5 className="card-header-text">Investors Club</h5>
                      <div className="icon-container">
                        <FaHome
                          className=""
                          size={50}
                          color="rgb(35,119,186)"
                        />
                      </div>
                    </Card.Header>
                    <Card.Body className="price-card-body">
                      <h4>$149.99/mo</h4>
                      <ul>
                        <li>25 Valuation / Analytic reports</li>
                        <li>Real Estate legal forms</li>
                        <li>Investor Consultants</li>
                        <li>Directory Access</li>
                        <li>Exclusive Forum</li>
                        <li>InvestFar App</li>
                        <li>Market data</li>
                        <li>Free listing</li>
                        <li>Blog</li>
                        <li>Newsletter</li>
                        <li>Monthly Market report alerts</li>
                        <li>3-Year workmanship warranty</li>
                        <li>Investment criteria property match alerts</li>
                        <li>Private money introductions or joint ventures</li>
                        <li>
                          FREE or discounted real estate seminars and events
                        </li>
                        <li>
                          Contractor project matching&project completion
                          monitoring
                        </li>
                        <li>$7.99 Per Valuation Report</li>
                        <li>$41.99 Mobile Notarization</li>
                        <li>$19.99 20 additional photos</li>
                        <li>$19.99 1-Minute Property Video</li>
                        <li>
                          $74.99 Residential Property Inspection (Exterior Only)
                        </li>
                        <li>
                          $94.99 Residential Property Inspection (Exterior &
                          Interior)
                        </li>
                        <li>
                          $84.99 Documents pick-up & Delivery (Notary docs,
                          notices etc)
                        </li>
                      </ul>
                      <Link
                        className="text-center"
                        to={{
                          pathname: "/payment",
                          plan: "Investors Club",
                          price: 149.99,
                          toPay: true,
                        }}
                      >
                        <Button className="access-button btn-blue">
                          Get Access
                        </Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Container>
        </div>
        <Container fluid className="getstarted">
          <div className="getstarted-body">
            <br />
            <br />
            <h3>Get Started With A Free Consultation</h3>
            <div className="collapse-container">
              <Row>
                <Col sm={6}>
                  <Row>
                    <Button
                      name="colOne"
                      onClick={this.toggleCollapse}
                      className="collapse-button"
                    >
                      Online Real Estate Investment Platforms{" "}
                    </Button>
                    <Collapse className="collapse-body" in={this.state.colOne}>
                      <div id="example-collapse-text">
                        <div className="collapse-paragraph">
                          Online real estate platforms pool investments and
                          invest in real estate investment opportunities that
                          would otherwise be difficult to find or out of reach
                          on an individual level. Real estate platforms offer
                          investors the ability to invest in single investments
                          or a diversified portfolio of real estate. Some offer
                          only debt investments and others offer both debt and
                          equity investments. And, some focus on a specific city
                          or region and other invest across the country. Many
                          real estate investment platforms carry restrictions
                          such as accreditation requirements and high investment
                          minimums, but not all of them such as InvestFar.
                        </div>
                      </div>
                    </Collapse>
                  </Row>
                  <Row>
                    <Button
                      name="colTwo"
                      onClick={this.toggleCollapse}
                      className="collapse-button"
                    >
                      The Most Important Questions to Ask Before You Invest in
                      Real Estate
                    </Button>
                    <Collapse className="collapse-body" in={this.state.colTwo}>
                      <div id="example-collapse-text">
                        <div className="collapse-paragraph">
                          <ol>
                            <li>
                              Do you have the time and expertise to invest
                              actively, or would it make more sense for you to
                              invest passively?
                            </li>
                            <li>
                              What’s your investment timeline? And, how
                              important will liquidity be to you in the future?
                              What are the potential tax benefits?
                            </li>
                            <li>
                              How will real estate affect your overall
                              portfolio?
                            </li>
                            <li>
                              What makes a particular real estate investment
                              attractive to you? How would you define success?
                            </li>
                          </ol>
                        </div>
                      </div>
                    </Collapse>
                  </Row>
                  <Row>
                    <Button
                      name="colThree"
                      onClick={this.toggleCollapse}
                      className="collapse-button"
                    >
                      What asset type and strategy are right for you in your
                      given market?
                    </Button>
                    <Collapse
                      className="collapse-body"
                      in={this.state.colThree}
                    >
                      <div id="example-collapse-text">
                        <div className="collapse-paragraph">
                          The most obvious real estate assets for direct
                          investment are residential — houses or condos. But are
                          there any other variables that might make another
                          asset type a more promising option? Retail or office
                          space? Maybe there’s been a new influx of young
                          professionals, thanks to a change in local laws, and
                          office buildings are increasing in value. But, with a
                          change in real estate class, there’s also a change in
                          expertise required. Assess the options and make sure
                          your money is doing the most it can in any obvious
                          circumstances.
                        </div>
                      </div>
                    </Collapse>
                  </Row>
                  <Row>
                    <Button
                      name="colFour"
                      onClick={this.toggleCollapse}
                      className="collapse-button"
                    >
                      How well do you understand the specific city where you’re
                      planning to invest? What are the local opportunities and
                      risks?
                    </Button>
                    <Collapse className="collapse-body" in={this.state.colFour}>
                      <div id="example-collapse-text">
                        <div className="collapse-paragraph">
                          “Location, location, location” is a saying as accurate
                          as it is cliché. A warehouse in Orlando might have an
                          altogether different outlook from an identical
                          structure in Memphis. How can you expect the area
                          you’re eyeing to develop? You need to make sure you
                          account for as many location-specific variables as
                          possible, ranging from natural disaster risks to noise
                          pollution, all of which can make it difficult to
                          secure tenants. For example, maybe the building next
                          door is a school. That can have a major effect on how
                          you’re able to develop and use the property. Be honest
                          with the fidelity of your expertise, try to know what
                          you don’t know and fill in the gaps.
                        </div>
                      </div>
                    </Collapse>
                  </Row>
                  <Row>
                    <Button
                      name="colFive"
                      onClick={this.toggleCollapse}
                      className="collapse-button"
                    >
                      How much time do you have available to devote to your real
                      estate investment?
                    </Button>
                    <Collapse className="collapse-body" in={this.state.colFive}>
                      <div id="example-collapse-text">
                        <div className="collapse-paragraph">
                          For some investors, time is a more scarce commodity
                          than dollars. Likewise, some investment models
                          inherently apply far more of a time strain than
                          others. If your investment requires a landlord, can
                          you spare the hours? If not, can you afford to hire a
                          property manager? Unlike other investments, which
                          require attention only trading hours, an investment
                          property requires constant administration as long as
                          you own the investment.
                        </div>
                      </div>
                    </Collapse>
                  </Row>
                  <Row>
                    <Button
                      name="colSix"
                      onClick={this.toggleCollapse}
                      className="collapse-button"
                    >
                      6 Key Characteristics Of Successful Investors in Real
                      Estate
                    </Button>
                    <Collapse className="collapse-body" in={this.state.colSix}>
                      <div id="example-collapse-text">
                        <div className="collapse-paragraph">
                          <ol>
                            <li>Having a Clear Sense of Financial Goals.</li>
                            <li>Patience.</li>
                            <li>
                              Keeping your eyes on a big-picture view of
                              history.
                            </li>
                            <li>
                              Understanding how to balance risk and reward.
                            </li>
                            <li>
                              Diversifying into multiple economies and
                              alternative asset.
                            </li>
                            <li>
                              Diversifying into multiple economies and
                              alternative asset.
                            </li>
                          </ol>
                        </div>
                      </div>
                    </Collapse>
                  </Row>
                </Col>
                <Col sm={6}>
                  <Row>
                    <Button
                      name="colSeven"
                      onClick={this.toggleCollapse}
                      className="collapse-button"
                    >
                      Joint Venture Financing
                    </Button>
                    <Collapse
                      className="collapse-body"
                      in={this.state.colSeven}
                    >
                      <div id="example-collapse-text">
                        <div className="collapse-paragraph">
                          InvestFar has access to many sources and providers of
                          joint venture financing. This type of real estate
                          funding is a means of structuring a mortgage and
                          private, accredited equity. This use of other people’s
                          money (OPM) and their balance sheet can help the
                          Client utilize leverage, minimize their cash input,
                          help get their immediate project get funded, and free
                          up their cash reserves for other projects as well.
                          This is achieved by creating a team between the
                          clients with the project together with a
                          Lender/Investor with the added financial capacity. Our
                          sources have the expertise and relationships necessary
                          to secure equity for real estate projects that make
                          sense, whether it involves a single transaction or an
                          entire portfolio.{" "}
                          <b>
                            Typically, the range of funding can be from $75k to
                            $100MM or more.
                          </b>
                        </div>
                      </div>
                    </Collapse>
                  </Row>
                  <Row>
                    <Button
                      name="colEight"
                      onClick={this.toggleCollapse}
                      className="collapse-button"
                    >
                      Capitalization (Cap) Rate
                    </Button>
                    <Collapse
                      className="collapse-body"
                      in={this.state.colEight}
                    >
                      <div id="example-collapse-text">
                        <div className="collapse-paragraph">
                          The capitalization or cap rate measures a property’s
                          yield in a one-year time frame, making it easy to
                          compare one property’s cash flow to another on an
                          equal basis – without taking into account any debt on
                          the asset.
                        </div>
                      </div>
                    </Collapse>
                  </Row>
                  <Row>
                    <Button
                      name="colNine"
                      onClick={this.toggleCollapse}
                      className="collapse-button"
                    >
                      Capital Gains Taxes
                    </Button>
                    <Collapse className="collapse-body" in={this.state.colNine}>
                      <div id="example-collapse-text">
                        <div className="collapse-paragraph">
                          <b>Short-term capital gains</b>
                          <br />
                          Short-term capital gains do not benefit from any
                          special tax rate – they are taxed at the same rate as
                          your ordinary income. Ordinary tax rates range from 10
                          percent to 37 percent, depending on your total taxable
                          income. If you sell an asset you have held for one
                          year or less, any profit you make is considered a
                          short-term capital gain. The clock begins ticking from
                          the day after you acquire the asset up to and
                          including the day you sell it.
                          <br />
                          <b>Long-term capital gains</b>
                          <br />
                          If you can manage to hold your assets for longer than
                          a year, you can benefit from a reduced tax rate on
                          your profits. The long-term capital gains tax rates
                          are 0, 15, and 20 percent for most taxpayers. If your
                          ordinary tax rate is already less than 15 percent, you
                          could qualify for the zero percent long-term capital
                          gains rate. For high-income taxpayers, the capital
                          gains rate could save as much as 19.6 percent off the
                          ordinary income rate.
                          <br />
                          *Please consult a tax advisor
                        </div>
                      </div>
                    </Collapse>
                  </Row>
                  <Row>
                    <Button
                      name="colTen"
                      onClick={this.toggleCollapse}
                      className="collapse-button"
                    >
                      1031 Exchange
                    </Button>
                    <Collapse className="collapse-body" in={this.state.colTen}>
                      <div id="example-collapse-text">
                        <div className="collapse-paragraph">
                          A 1031 exchange gets its name from Section 1031 of the
                          U.S. Internal Revenue Code, which allows you to avoid
                          paying capital gains taxes when you sell an investment{" "}
                          <b>property</b> and reinvest the proceeds from the
                          sale within certain time limits in a <b>property</b>{" "}
                          or properties of like kind and equal or greater{" "}
                          <b>value</b>.
                        </div>
                      </div>
                    </Collapse>
                  </Row>
                  <Row>
                    <Button
                      name="colEleven"
                      onClick={this.toggleCollapse}
                      className="collapse-button"
                    >
                      3 WAYS TO MAKE REMOTE REAL ESTATE INVESTING EVEN EASIER
                    </Button>
                    <Collapse
                      className="collapse-body"
                      in={this.state.colEleven}
                    >
                      <div id="example-collapse-text">
                        <div className="collapse-paragraph">
                          <ol>
                            <li>
                              <b>Use technology to your advantage.</b> I
                              mentioned that technology was the biggest driver
                              for remote investing, as computers, the internet
                              and other advancements have made it less essential
                              to be on-site with an investment property.
                              Obviously, if you’re reading this, you’re already
                              making good use of your computer and internet, but
                              these aren’t the only tools at your disposal, and
                              narrowing your focus to specific websites and apps
                              that benefit you the most is key.
                            </li>
                            <li>
                              <b>Prioritize property management.</b> Another tip
                              for streamlining remote investing is to make
                              property management a priority from Day 1. From
                              the moment you take possession of the property,
                              it’s your job to maintain it properly. This
                              doesn’t just benefit your tenants, but it helps
                              you, as the owner, too. Fixing problems
                              immediately rather than sitting on them can help
                              prevent them from worsening, and keeping your
                              place in good shape also helps it retain/increase
                              its value. When you’re remote, however, it can be
                              more challenging to keep an eye on things. That’s
                              why it’s critical that you partner with a
                              reputable property manager who you can trust to
                              keep your property well-maintained and in good
                              repair.
                            </li>
                            <li>
                              <b>Use market data and local experts.</b> You can
                              take a lot of the guesswork out of remote
                              investing to score yourself an amazing,
                              cashflowing property that will help you reach your
                              investment goals faster. Use valuation tools,
                              market data and local experts to help you make
                              informed decisions.
                            </li>
                          </ol>
                        </div>
                      </div>
                    </Collapse>
                  </Row>
                  <Row>
                    <Button
                      name="colTwelve"
                      onClick={this.toggleCollapse}
                      className="collapse-button"
                    >
                      Putting it All Together
                    </Button>
                    <Collapse
                      className="collapse-body"
                      in={this.state.colTwelve}
                    >
                      <div id="example-collapse-text">
                        <div className="collapse-paragraph">
                          Real estate has a track record of strong performance.
                          Real estate investing offers the potential to earn
                          significant returns and add meaningful diversification
                          to your portfolio. When managed wisely it can become a
                          valuable source of cash flow in your investment
                          portfolio. As with any investment, real estate
                          investments require you to understand and weigh the
                          risks and potential rewards before beginning.
                          Depending on which way you choose invest in real
                          estate, you’ll need varying amounts of time, beginning
                          capital, knowledge, and patience.
                        </div>
                      </div>
                    </Collapse>
                  </Row>
                  <Row>
                    <Button
                      name="colThirteen"
                      onClick={this.toggleCollapse}
                      className="collapse-button"
                    >
                      Opportunity Zone Program
                    </Button>
                    <Collapse
                      className="collapse-body"
                      in={this.state.colThirteen}
                    >
                      <div id="example-collapse-text">
                        <div className="collapse-paragraph">
                          The Opportunity Zone program was created to revitalize
                          economically distressed communities using private
                          investments rather than taxpayer dollars. To stimulate
                          private participation in the Opportunity Zone program,
                          taxpayers who invest in Qualified Opportunity Zones
                          are eligible to benefit from capital gains tax
                          incentives available exclusively through the program.
                        </div>
                      </div>
                    </Collapse>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          <br />
          <br />
        </Container>
        <Container fluid className="pricing-end">
          <div className="end-body container">
            <br />
            <br />
            <Row>
              <Col sm={6}>
                <img src={phone} className="end-img" />
              </Col>
              <Col>
                <Row>
                  <h1>InvestFar</h1>
                  <br />
                  <p1>
                    InvestFar is the leading real estate investor marketplace
                    and database specifically dedicated to empowering real
                    estate investors with data, resources and knowledge to
                    instill confidence in investing in any market nationwide, by
                    connecting them with the best local investor consultants and
                    tools to make informed buying decisions.
                  </p1>
                  <br />
                  <br />
                </Row>
                <Row>
                  <img
                    src={appstore}
                    className="app-store"
                    onClick={() => {
                      this.openInNewTab(
                        "https://apps.apple.com/us/app/investfar-real-estate/id1327794180"
                      );
                    }}
                  />
                  <img
                    src={googlestore}
                    className="google-store"
                    onClick={() => {
                      this.openInNewTab(
                        "https://play.google.com/store/apps/details?id=com.investfar"
                      );
                    }}
                  />
                </Row>
                <Row>
                  <img src={ios} className="qrcode" />
                  <img src={android} className="qrcode" />
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  };

  render() {
    require("./Pricing.css");
    return <this.price />;
  }
}

export default Pricing;
