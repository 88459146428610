import React, { useEffect, useState } from 'react'
import {
    Carousel,
    Row,
    Col,
    Container,
    Modal,
} from 'react-bootstrap'
import { FiMaximize } from 'react-icons/fi'
import './Gallery.css'
// import ImageGallery from 'react-image-gallery';
// import "~react-image-gallery/styles/scss/image-gallery.scss"


function FullScreen(props) {

    return (
        <Modal
            dialogClassName="my-modal"
            show={props.show}
            onHide={() => props.close()}
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header style={{ border: '0', marginBottom: '-30px' }} closeButton>
                <Modal.Title as={"H5"}>
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ margin: 'auto' }}>
                {props.children}
            </Modal.Body>
        </Modal>
    );
}
export default function Gallery(props) {
    const [index, setIndex] = useState(0)
    const [display, setDisplay] = useState([])
    const [fullScreen, setFullScreen] = useState(false)

    require('./Gallery.css')
    var Images = () => {
        return (<div style={fullScreen ? { padding: '5px' } : null}>
            <Row style={{ height: '65vh', marginBottom: '10px' }}>
                <Carousel interval={fullScreen || true ?
                    null : 3000} fade slide={false}
                    activeIndex={index}
                    className='carousel'
                    onSelect={(selectedIndex, e) => setIndex(selectedIndex)}>
                    {
                        props.images.map((image, id) => {
                            return (
                                <Carousel.Item>
                                    <Container fluid>
                                        <div>
                                            {/* <div className='maximize'
                                            onClick={!fullScreen ? () => setFullScreen(true) :
                                                null}>
                                            <FiMaximize className='icon' />
                                        </div> */}
                                            <img
                                                onClick={() => setFullScreen(true)}
                                                className={fullScreen ? 'test' : "main-pic"}
                                                src={image}
                                                alt={`image ${id} `}
                                            />
                                        </div>
                                    </Container>
                                    <Carousel.Caption>
                                        {/* <label>image {id + 1} </label> */}
                                    </Carousel.Caption>
                                </Carousel.Item>

                            )
                        })
                    }

                </Carousel>
            </Row>
            <div style={{ maxWidth: '90vw' }}>
                <Container fluid>
                    <Row className="row selected-row" >
                        {props.images.map((image, idx) => {
                            return (
                                <Col sm={2} className='image-container'>
                                    <img
                                        className="d-block thumbnails"
                                        src={image}
                                        onClick={() => { setIndex(idx) }}
                                    />
                                </Col>
                            )
                        })}

                    </Row>
                </Container>
            </div>
        </div >)
    }


    return (
        <div>
            {fullScreen ? <div style={{ height: '50vh' }}></div> : <Images />}
            <FullScreen title={props.title} show={fullScreen}
                close={() => setFullScreen(false)}
            >
                <Images />
            </FullScreen>
        </div>)
}