import React from 'react';
import { Form, FormControl, Button, Row, Col, Card, Container, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { Error, Primary, Success, Info, Warning } from './../Alerts'

// store api key in env file
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PKEY_LIVE);
const stripeWebhookSigningSecret = process.env.REACT_APP_STRIPE_SIGNING_SECRET;

class Payment extends React.Component {
    state = {
        stripeCheckoutLoading: false,
        status: false,
        total: '',
        agreement: false, 
        total: 0.00,
        plans: [
            {
                type: 'additional',
                name: "Per Valuation Report",
                id: 'valuationReport',
                price: 7.99,
                check: false,
                stripe: {
                    price: process.env.REACT_APP_VALUATION_REPORT_LIVE,
                    quantity: 1,
                },
            },
            {
                type: 'additional',
                name: "Mobile Notarization. Coming Soon",
                id: 'mobileNotary',
                price: 41.99,
                check: false,
                stripe: {
                    price: process.env.REACT_APP_MOBILE_NOTARIZATION_LIVE,
                    quantity: 1,
                },
            },
            {
                type: 'additional',
                name: "20 additional photos. Coming Soon",
                id: 'additionalPhotos',
                price: 19.99,
                check: false,
                stripe: {
                    price: process.env.REACT_APP_ADDITIONAL_PHOTOS_LIVE,
                    quantity: 1,
                },
            },
            {
                type: 'additional',
                name: "1-Minute Property Video. Coming Soon",
                id: 'propertyVideo',
                price: 19.99,
                check: false,
                stripe: {
                    price: process.env.REACT_APP_PROPERTY_VIDEO_LIVE,
                    quantity: 1,
                },
            },
            {
                type: 'additional',
                name: "Residential Property Inspection (Exterior Only)",
                id: 'inspection_exterior',
                price: 74.99,
                check: false,
                stripe: {
                    price: process.env.REACT_APP_INSPECTION_EXTERIOR_LIVE,
                    quantity: 1,
                },
            },
            {
                type: 'additional',
                name: "Residential Property Inspection (Exterior & Interior). Coming Soon",
                id: 'inspection_all',
                price: 94.99,
                check: false,
                stripe: {
                    price: process.env.REACT_APP_INSPECTION_ALL_LIVE,
                    quantity: 1,
                },
            },
            {
                type: 'additional',
                name: "Documents pick-up & Delivery (Notary docs, notices etc). Coming Soon",
                id: 'doc_pickup',
                price: 184.99,
                check: false,
                stripe: {
                    price: process.env.REACT_APP_DOC_PICKUP_LIVE,
                    quantity: 1,
                },
            }
        ],
        novicePlan: {
            // price: 'price_1IzUr2JlOeGM2012x4GqI8HQ',
            type: 'plan',
            name: 'Novice Plan',
            stripe: {
                quantity: 1,
                price: process.env.REACT_APP_NOVICE_LIVE // TEST ID
            }
        },
        apprenticePlan: {
            type: 'plan',
            name: 'Apprentice Plan',
            stripe: {
                price: process.env.REACT_APP_APPRENTICE_LIVE,
                quantity: 1,
            }
        },
        investorsClubPlan: {
            type: 'plan',
            name: 'Investors Club Plan',
            stripe: {
                price: process.env.REACT_APP_INVESTORS_CLUB_LIVE,
                quantity: 1,
            }
        },
    }

    componentDidMount = () => {
        window.scrollTo(0, 0)
        if (this.props.location.plan) {
            var plans = this.state.plans
            this.setState({
                plans: plans.map(plan => {
                    if (plan.name == this.props.location.plan) {
                        console.log("Found plan", plan.name)
                        console.log(this.props.location.plan)
                        var temp = plan
                        plan.check = true
                        return plan;
                    } else {
                        return plan
                    }
                }),
                total: this.props.location.plan != 'À la carte Services' ? this.props.location.price : 0
            })
        }
        var alaCartePlans = null;
        if (this.props.location.plan == 'À la carte Services') {
            alaCartePlans = [
                {
                    type: 'additional',
                    name: "Per Valuation Report",
                    id: 'valuationReport',
                    price: 8.99,
                    check: false,
                    stripe: {
                        price: process.env.REACT_APP_VALUATION_REPORT_ALA_LIVE,
                        quantity: 1,
                    },
                },
                {
                    type: 'additional',
                    name: "Mobile Notarization. Coming Soon",
                    id: 'mobileNotary',
                    price: 45.99,
                    check: false,
                    stripe: {
                        price: process.env.REACT_APP_MOBILE_NOTARIZATION_ALA_LIVE,
                        quantity: 1,
                    },
                },
                {
                    type: 'additional',
                    name: "20 additional photos. Coming Soon",
                    id: 'additionalPhotos',
                    price: 24.99,
                    check: false,
                    stripe: {
                        price: process.env.REACT_APP_ADDITIONAL_PHOTOS_ALA_LIVE,
                        quantity: 1,
                    },
                },
                {
                    type: 'additional',
                    name: "1-Minute Property Video. Coming Soon",
                    id: 'propertyVideo',
                    price: 24.99,
                    check: false,
                    stripe: {
                        price: process.env.REACT_APP_PROPERTY_VIDEO_ALA_LIVE,
                        quantity: 1,
                    },
                },
                {
                    type: 'additional',
                    name: "Residential Property Inspection (Exterior Only)",
                    id: 'inspection_exterior',
                    price: 84.99,
                    check: false,
                    stripe: {
                        price: process.env.REACT_APP_INSPECTION_EXTERIOR_ALA_LIVE,
                        quantity: 1,
                    },
                },
                {
                    type: 'additional',
                    name: "Residential Property Inspection (Exterior & Interior). Coming Soon",
                    id: 'inspection_all',
                    price: 99.99,
                    check: false,
                    stripe: {
                        price: process.env.REACT_APP_INSPECTION_ALL_ALA_LIVE,
                        quantity: 1,
                    },
                },
                {
                    type: 'additional',
                    name: "Documents pick-up & Delivery (Notary docs, notices etc). Coming Soon",
                    id: 'doc_pickup',
                    price: 184.99,
                    check: false,
                    stripe: {
                        price: process.env.REACT_APP_DOC_PICKUP_ALA_LIVE,
                        quantity: 1,
                    },
                }
            ]
            this.setState({
                plans: alaCartePlans
            })
        }

        const { profile } = this.props;
        console.log(profile)
    }

    createCheckoutSession = async () => {
        const { profile } = this.props
        var itemsToSend = [];
        var priceId = null;
        var svc = '';

        itemsToSend = this.state.plans.filter(plan => plan.check)
        console.log("Items to Send: ", itemsToSend)

        switch (this.props.location.plan) {
            case 'Novice':
                itemsToSend.push(this.state.novicePlan)
                break;
            case 'Apprentice':
                itemsToSend.push(this.state.apprenticePlan)
                break;
            case 'Investors Club':
                itemsToSend.push(this.state.investorsClubPlan)
                break;
        }

        if (itemsToSend.length > 0) {

            if (this.props.location.plan == 'À la carte Services') {
                svc = "payment"
            }
            else {
                svc = "subscription"
            }

            if (svc == 'subscription' && profile.plan && profile.plan.status != 'cancelled') {
                Error({ title: 'Error Encountered', message: 'You currently have an active plan. Please cancel your subscription before purchasing a new one.' })
            }
            else {
                this.setState({
                    stripeCheckoutLoading: true,
                })
                const stripe = await stripePromise;
                var email = profile.email
                var userId = profile.user_id
                var session_id = '';
                console.log(email, userId)
                // call the backend to create a checkout session 
                const checkoutSession = await axios.post('/api/payments/checkout',
                    {
                        items: itemsToSend,
                        email: email,
                        userId: userId,
                        id: priceId,
                        service: svc,
                    }).then(response => {
                        if (response) {
                            console.log(response);
                            session_id = response.data.id
                            // set checkout session response 
                        }
                    }).catch(error => {
                        console.log(error);
                    });

                const result = await stripe.redirectToCheckout({
                    sessionId: session_id,
                })

                if (result.error) {
                    alert(result.error.message);
                }

                this.setState({
                    stripeCheckoutLoading: false,
                })
            }
        } else {
            Error({ title: 'Error Encountered', message: 'You must add items to purchase.' })
        }

    }

    changeQuantity = (event) => {
        console.log("PLAN REF: ", event.target.value, "QUANTITY CHANGE: ", event.target.name)
        var plans = this.state.plans
        var plansFilter = plans.map(plan => {
            if (plan.name == event.target.name) {
                plan.stripe.quantity = event.target.value
                return plan;
            } else {
                return plan
            }
        })
        this.setState({
            plans: plansFilter
        })
    }

    addPlan = (name) => {
        var plans = this.state.plans
        console.log(plans)
        var tempTotal = this.state.total
        var planPriceToAdd = this.state.total
        var plansFilter = plans.map(plan => {
            if (plan.name == name && plan.check === true) {
                planPriceToAdd = plan.price
            }
        })
        console.log(tempTotal)

        var tempPlan = plans.map(plan => {
            if (plan.name == name) {
                plan.check = !plan.check
                if (plan.check) {
                    tempTotal += Number(plan.price)
                } else {
                    tempTotal -= Number(plan.price)
                }
                return plan;
            } else {
                return plan
            }
        })
        this.setState({
            plans: tempPlan,
            total: Math.round(tempTotal * 100 + Number.EPSILON) / 100
        })
    }

    pay = () => {
        return (
            <div className="pay-page">
                <Container fluid className="jumbo">
                    <Row>
                        <Col sm={5}>
                            <div className="jumbo-content-left">
                                <h2>CHECKOUT</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="pay-body">
                    <div className="pay-card-container">
                        <Card className="pay-card">
                            {(this.props.location.toPay) ? (
                                <Row>
                                    <Col>
                                        <Card className="plan-card">
                                            <Card.Body className="plan-card-body">
                                                <p2>YOUR PLAN</p2>
                                                <h5>{this.props.location.plan}</h5>
                                                <Card style={{ padding: "20px" }}
                                                    className="plan-card-table">
                                                    <table>
                                                        <thead className="property-table-headers">
                                                            <tr style={{ borderBottom: 'solid 1px rgba(0,0,0,0.05)', }}>
                                                                <th style={{ textAlign: "left" }}>{' '}</th>
                                                                <th style={{ textAlign: "left" }}>Package</th>
                                                                <th>Quantity</th>
                                                                <th style={{ textAlign: "right" }}>Price</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{ fontSize: "10px" }}>
                                                            {
                                                                this.state.plans.map(plan => {
                                                                    return (<tr style={{ borderBottom: 'solid 1px rgba(0,0,0,0.05)', }}>
                                                                        <td style={{ textAlign: "left" }}>
                                                                            <Form.Check onChange={() => this.addPlan(plan.name)}
                                                                                checked={plan.check} name="add_" type="checkbox"
                                                                            /></td>
                                                                        <td style={{ textAlign: "left" }}>{plan.name}</td>
                                                                        <td>
                                                                            <Form.Control name={plan.name}
                                                                                onChange={this.changeQuantity.bind(this)}
                                                                                as="select" placeholder="qty">
                                                                                <option>1</option>
                                                                                <option>2</option>
                                                                                <option>3</option>
                                                                                <option>4</option>
                                                                            </Form.Control>
                                                                        </td>
                                                                        <td style={{ textAlign: "right" }}>{plan.price}</td>
                                                                    </tr>)
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </Card>
                                                {/* <h4><strong>Total:${this.state.total}</strong></h4> */}
                                                <br/>
                                                <Button
                                                    role="link"
                                                    onClick={() => this.createCheckoutSession()}>{!this.state.stripeCheckoutLoading ? 'BOOK NOW' :
                                                        (<Spinner animation="border"></Spinner>)}</Button>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            ) : (
                                <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <Container>
                                        <h4>Failed to load payment</h4>
                                        <p></p>
                                        <p></p>                                              <p></p>
                                        <p></p>
                                    </Container>
                                </div>)}
                        </Card>
                    </div>
                    <br /><br />
                </Container>
            </div>
        )
    }

    render() {
        require('./Payment.css')
        return (
            <this.pay />
        )
    }
}

const mapStateToProps = (state) => {
    console.log(state)
    return { auth: state.firebase.auth, profile: state.firebase.profile, user: state.user.user, authSuccess: state.auth.authSuccess, jwtToken: state.auth.jwtToken }
}

export default connect(mapStateToProps)(Payment)