import React from "react";
import {
  InputGroup,
  Form,
  FormControl,
  Dropdown,
  DropdownButton,
  Button,
  Row,
  Col,
  Container,
  Card,
} from "react-bootstrap";
import { Link } from "react-router-dom";

class ContractorRegister extends React.Component {
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  contractregister = () => {
    return (
      <div className="contractor-page">
        <div className="jumbo">
          <Row>
            <Col>
              <div className="jumbo-content-left">
                <h2>Registration</h2>
              </div>
            </Col>
          </Row>
        </div>
        <div className="contractor-account">
          <Row>
            <Card className="contractor-register-card">
              <Form className="register-form">
                <h2 style={{ marginTop: "40px" }}>Contractor Registration</h2>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control placeholder="Name" />
                </Form.Group>
                <Form.Row>
                  <Form.Group as={Col} sm={6} controlId="formGridEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                  </Form.Group>

                  <Form.Group as={Col} sm={6}>
                    <Form.Label>Website URL</Form.Label>
                    <Form.Control placeholder="Website URL" />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} sm={6}>
                    <Form.Label>Select Country</Form.Label>
                    <Form.Control as="select" defaultValue="Country Code">
                      <option>Country Code</option>
                      <option>...</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} sm={6}>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control placeholder="Phone Number" />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} sm={6}>
                    <Form.Label>Primary Address</Form.Label>
                    <Form.Control
                      type="address"
                      placeholder="Primary Business Address"
                    />
                  </Form.Group>
                  <Form.Group as={Col} sm={6}>
                    <Form.Label>Zip Code</Form.Label>
                    <Form.Control placeholder="0" />
                  </Form.Group>
                </Form.Row>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="Password" />
                </Form.Group>
                <div className="checkboxes">
                  <Form.Row>
                    <Form.Group>
                      <Form.Check
                        name="wealso"
                        type="checkbox"
                        label="We also operate in additional states"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group>
                      <Form.Check
                        name="areyou"
                        type="checkbox"
                        label="Are you a licensed agent?"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group>
                      <Form.Check
                        name="otherlicense"
                        type="checkbox"
                        label="Other Licenses"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group>
                      <Form.Check
                        name="brokerad"
                        type="checkbox"
                        label="Broker Address"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group>
                      <Form.Check
                        name="website"
                        type="checkbox"
                        label="Website"
                      />
                    </Form.Group>
                  </Form.Row>
                </div>
                <Form.Group as={Row}>
                  <Col>
                    <Button className="sign-up-button" type="submit">
                      Sign Up
                    </Button>
                  </Col>
                </Form.Group>
              </Form>
            </Card>
          </Row>
        </div>
      </div>
    );
  };

  render() {
    require("./ContractorRegister.css");
    return <this.contractregister />;
  }
}

export default ContractorRegister;
