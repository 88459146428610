import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Container,
    Button,
    Spinner,
} from 'react-bootstrap'
import {
    BsTrash,
    BsPencilSquare,
} from 'react-icons/bs'
import { connect } from 'react-redux';
import Valuation from './Valuation';

function ValuationFunction(props) {
    const [services, setServices] = useState([])
    const [redirect, setRedirect] = useState(false)
    const [current, setCurrent] = useState({})
    const [deleteService, setDeleteService] = useState(false)
    const [deleted, setDeleted] = useState(null)
    const [loading, setLoading] = useState(true)
    const [path, setPath] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0)
        const { authSuccess } = props;
        const { user, profile } = props;
        console.log('Current User', profile)
        var params = {
            type: 'offered',
            account: 'provider',
            id: profile.user_id,
        }

    }, [])

    var handleRedirect = (current, path) => {
        setRedirect(true)
        setCurrent(current)
        setPath(path)
        console.log('Redirecting to edit', current)
    }

    return (
        <div>
            <Valuation user={props}/>
        </div>
    )
}


const mapStateToProps = (state) => {
    console.log(state)
    return { auth: state.firebase.auth, profile: state.firebase.profile, user: state.user.user, authSuccess: state.auth.authSuccess, jwtToken: state.auth.jwtToken }
}

export default connect(mapStateToProps)(ValuationFunction)