import React from "react";
import { NavLink } from "react-router-dom";

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const Footer = () => {
  require("./Footer.css");
  return (
    <footer>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="footer-left">
                <a href="/" className="logo" style={{ cursor: "pointer" }}>
                  <img src="images/logo-white.svg" alt="" />
                </a>
                <p>
                  The future of real estate investing is now. Don’t let your
                  current location limit your success in real estate investing.
                </p>
                <div className="app-download">
                  <a
                    href="https://apps.apple.com/us/app/investfar-real-estate/id1327794180"
                    target="_blank"
                  >
                    <img src="images/app-store.svg" alt="" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.investfar"
                    target="_blank"
                  >
                    <img src="images/google-play.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-links">
                <div className="footerTitle">Pages</div>
                <ul>
                  <li>
                    <NavLink to="/valuation">Valuation</NavLink>
                  </li>
                  <li>
                    <NavLink to="/valuation">Reports/Comps</NavLink>
                  </li>
                  <li>
                    <NavLink to="/homeimprovementnetwork">
                      Home Improvement Network
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/pricing">Pricing</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-links">
                <div className="footerTitle">Information</div>
                <ul>
                  <li>
                    <NavLink to="/about">About Us</NavLink>
                  </li>
                  <li>
                    <NavLink to="/careers">Careers</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact">Contact</NavLink>
                  </li>
                  <li>
                    <NavLink to="/investfar">InvestFar</NavLink>
                  </li>
                  <li>
                    <NavLink to="/OpportunityZones">Opportunity Zones</NavLink>
                  </li>
                  <li>
                    <NavLink to="/privacypolicy">
                      Privacy Policy &amp; Terms
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-links footer-contact-info">
                <div className="footerTitle">Contact Info</div>
                <ul>
                  <li className="mb-2">
                    <div className="icon m-0">
                      <img src="images/icons-home.svg" alt="" />
                    </div>
                    <span>
                      <strong>US</strong>
                      <br />
                      12777 W. Jefferson Blvd
                      <br /> Los Angeles, CA 90094
                    </span>
                  </li>
                  <li>
                    <div className="icon m-0"></div>
                    <span>
                      <strong>International</strong>
                      <br />
                      Cra. 35 #2Sur-120
                      <br /> Medellin, Colombia
                    </span>
                  </li>
                  <li>
                    <div className="icon m-0">
                      <img src="images/icons-envelope.svg" alt="" />
                    </div>
                    <a href="mailto:Info@investfar.com">Info@investfar.com</a>
                  </li>
                  <li>
                    <div className="icon m-0">
                      <img src="images/icons-handset.svg" alt="" />
                    </div>
                    <a href="tel:800-717-5497">800-717-5497</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 order-2 order-md-0">
              <p className="copyright-text">
                ©2023 All Rights Reserved InvestFar
              </p>
            </div>
            <div className="col-md-6">
              <ul className="footer-social-link">
                <li style={{ listStyleType: "none" }}>
                  <a
                    href="https://www.facebook.com/pg/InvestFar/photos/?tab=album&album_id=266984223775461"
                    target="_blank"
                  >
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li style={{ listStyleType: "none" }}>
                  <a href="https://twitter.com/InvestFar" target="_blank">
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li style={{ listStyleType: "none" }}>
                  <a
                    href="https://www.linkedin.com/in/invest-far-abb853157"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin-in" />
                  </a>
                </li>
                <li style={{ listStyleType: "none" }}>
                  <a
                    href="https://www.instagram.com/investfar/"
                    target="_blank"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
