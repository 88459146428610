import firebase from 'firebase/app'
// import 'firebase/firestore'
import '@firebase/firestore';
import 'firebase/auth'
import 'firebase/firebase-storage'
import 'firebase/database';
import 'firebase/functions'


// Your web app's Firebase configuration
// var firebaseConfig = {
//   apiKey: "AIzaSyA2pq-jg4LFy8R_EgRCDmhD1qLZyZq7Xhw",
//   authDomain: "investfar-3d385.firebaseapp.com",
//   databaseURL: "https://investfar-3d385.firebaseio.com",
//   projectId: "investfar-3d385",
//   storageBucket: "investfar-3d385.appspot.com",
//   messagingSenderId: "569226398669",
//   appId: "1:569226398669:web:e791c562ef25359483d284"
// };

const firebaseConfig = {
  apiKey: "AIzaSyA2pq-jg4LFy8R_EgRCDmhD1qLZyZq7Xhw",
  authDomain: "investfar-b494a.firebaseapp.com",
  databaseURL: "https://investfar-b494a.firebaseio.com",
  projectId: "investfar-b494a",
  storageBucket: "investfar-b494a.appspot.com",
  messagingSenderId: "328462702651",
  appId: "1:328462702651:web:25e89ddd8c0260a385641e",
  measurementId: "G-MRT32PP2SW"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyBwR2ZAc0g_ZGLLwZRlh-Ct3qE4muTOZbY",
//   authDomain: "investfar-database-dev.firebaseapp.com",
//   projectId: "investfar-database-dev",
//   storageBucket: "investfar-database-dev.appspot.com",
//   messagingSenderId: "114374483265",
//   appId: "1:114374483265:web:f315daf27bac8f83d7d5c0"
// };

// var firebaseConfig = {
//   apiKey: "AIzaSyBwR2ZAc0g_ZGLLwZRlh-Ct3qE4muTOZbY",
//   authDomain: "investfar-database-dev.firebaseapp.com",
//   projectId: "investfar-database-dev",
//   storageBucket: "investfar-database-dev.appspot.com",
//   messagingSenderId: "114374483265",
//   appId: "1:114374483265:web:c06161f9e71c31ced7d5c0"
// };
// Initialize Firebase
// Initialize Firebase

// firebase.initializeApp(firebaseConfig);
// firebase.firestore();


// export default app;


// const app = firebase.initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);
firebase.firestore()  


// if (window.location.hostname === "localhost") {
//   // app.functions().useFunctionsEmulator("http://localhost:3000");
//   // app.functions().useFunctionsEmulator("http://localhost:5001");
//   // app.auth().useEmulator("http://localhost:9099");
//   // app.firestore().settings({
//   //   host: "localhost:8080",
//   //   ssl: false,
//   // });
// }

// export default app;

export default firebase;
export const auth = firebase.auth
export const provider = new firebase.auth.FacebookAuthProvider()
export const database = firebase.firestore()