import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { signOut } from "../store/actions/authActions";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { withRouter } from "react-router";
import GoogleTranslate from "./GoogleTranslate";
import { NavLink, useHistory } from "react-router-dom";

const NavigationBar = (props) => {
  const { match, location, history, auth } = props;
  const { authSuccess } = props;
  const { user, profile } = props;
  const [toggle, setToggle] = useState(false);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const toggleChange = () => {
    setToggle(!toggle);
  };

  const Logout = () => {
    props.signOut();
    history.push("/");
  };

  const logoClick = () => {
    history.push("/");
  };

  return (
    <div className={toggle ? "mobileMenu" : "main_navbar"}>
      <nav className="navbar investfar-navbar navbar-expand-md">
        <div className="container-fluid pr-0">
          <a className="navbar-brand" href="/" style={{ cursor: "pointer" }}>
            <img src={require("../Assets/images/logo-copy.svg")} alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => toggleChange()}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarNavDropdown"
            style={{ textDecoration: "none" }}
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <div id="google_translate_element"></div>
              </li>
             
                <li className="nav-item" style={{ listStyleType: "none" }}>
                  <NavLink  to="/invest" className="nav-link">
                    Invest
                  </NavLink>
                </li>
             
              <li className="nav-item" style={{ listStyleType: "none" }}>
                <DropdownButton title="Buy/Sell" id="input-group-dropdown-1">
                  <NavLink to="/sell">
                    <Dropdown.Item href="/sell">Sell</Dropdown.Item>
                  </NavLink>
                  <NavLink to="/buy">
                    <Dropdown.Item href="/buy">Buy</Dropdown.Item>
                  </NavLink>
                </DropdownButton>
              </li>
              <li className="nav-item" style={{ listStyleType: "none" }}>
                <DropdownButton title="Directory" id="input-group-dropdown-1">
                  <NavLink to="/company">
                    <Dropdown.Item href="/company">Company</Dropdown.Item>
                  </NavLink>
                  <NavLink to="/valuation">
                    <Dropdown.Item href="/valuation">Valuation</Dropdown.Item>
                  </NavLink>
                  <NavLink to="/contractor">
                    <Dropdown.Item href="/contractor">
                      Contractors
                    </Dropdown.Item>
                  </NavLink>
                  <NavLink to="/local-investor-consultant">
                    <Dropdown.Item href="/local-investor-consultant">
                      Local Investor Consultant
                    </Dropdown.Item>
                  </NavLink>
                  <NavLink to="/OpportunityZones">
                    <Dropdown.Item href="/OpportunityZones">
                      Opportunity Zones
                    </Dropdown.Item>
                  </NavLink>
                  <NavLink to="/investfar">
                    <Dropdown.Item href="/investfar">Investfar</Dropdown.Item>
                  </NavLink>
                  <NavLink to="/pricing">
                    <Dropdown.Item href="/pricing">Pricing</Dropdown.Item>
                  </NavLink>
                </DropdownButton>
              </li>
              {!auth.uid ? (
                <li className="nav-item" style={{ listStyleType: "none" }}>
                  <NavLink className="nav-link" to="/login">
                    Sign In
                  </NavLink>
                </li>
              ) : null}
              <li className="nav-item" style={{ listStyleType: "none" }}>
                <DropdownButton title="Requests" id="input-group-dropdown-1">
                  <NavLink to="/legal">
                    <Dropdown.Item href="/legal">Legal Forms</Dropdown.Item>
                  </NavLink>

                  <NavLink to="/mlspropertysearch">
                    <Dropdown.Item href="/mlspropertysearch">
                      MLS Property Search
                    </Dropdown.Item>
                  </NavLink>

                  <NavLink to="/openhomes">
                    <Dropdown.Item href="/openhomes">Open Homes</Dropdown.Item>
                  </NavLink>

                  <NavLink to="/featuredlisting">
                    <Dropdown.Item href="/featuredlisting">
                      Featured Listing
                    </Dropdown.Item>
                  </NavLink>

                  <NavLink to="/organizerlogin">
                    <Dropdown.Item href="/organizerlogin">
                      Organizer Login
                    </Dropdown.Item>
                  </NavLink>
                </DropdownButton>
              </li>
              {auth.uid ? (
                <li className="nav-item" style={{ listStyleType: "none" }}>
                  <NavLink to="/submit-property" className="nav-link">
                    Submit Property
                  </NavLink>
                </li>
              ) : null}
              {auth.uid ? (
                <li className="nav-item" style={{ listStyleType: "none" }}>
                  <DropdownButton
                    title={
                      profile.full_name
                        ? profile.full_name.split(" ")[0].toUpperCase()
                        : "PROFILE"
                    }
                    style={{ textDecoration: "none" }}
                    id="input-group-dropdown-1"
                  >
                    <NavLink to="/add-service">
                      <Dropdown.Item href="/add-service">
                        Add Service
                      </Dropdown.Item>
                    </NavLink>

                    <NavLink to="/my-offered-services">
                      <Dropdown.Item href="/my-offered-services">
                        My Offered Services
                      </Dropdown.Item>
                    </NavLink>

                    <NavLink to="/my-properties">
                      <Dropdown.Item href="/my-properties">
                        My Properties
                      </Dropdown.Item>
                    </NavLink>

                    <NavLink to="/my-properties">
                      <Dropdown.Item href="/my-properties">
                        My Owned Properties
                      </Dropdown.Item>
                    </NavLink>

                    <NavLink to="/my-favorites">
                      <Dropdown.Item href="/my-favorites">
                        My Favorites
                      </Dropdown.Item>
                    </NavLink>

                    <NavLink to="/my-purchase-history">
                      <Dropdown.Item href="/my-purchase-history">
                        My Purchase History
                      </Dropdown.Item>
                    </NavLink>

                    <NavLink to="/careers">
                      <Dropdown.Item href="/careers">Careers</Dropdown.Item>
                    </NavLink>

                    <NavLink to="/profile">
                      <Dropdown.Item href="/profile">
                        Edit Profile
                      </Dropdown.Item>
                    </NavLink>

                    <NavLink to="/forgot-password">
                      <Dropdown.Item href="/forgot-password">
                        Change Password
                      </Dropdown.Item>
                    </NavLink>

                    <Dropdown.Item onClick={() => Logout()}>
                      Logout
                    </Dropdown.Item>
                  </DropdownButton>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log(state);
  return {
    authSuccess: state.auth.authSuccess,
    user: state.user.user,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavigationBar)
);
