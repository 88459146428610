import React from 'react';
import { InputGroup, Form, FormControl, Button, Row, Col, Card, Container } from 'react-bootstrap';
import { FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { FaUserAlt } from 'react-icons/fa';
import { BiErrorCircle } from 'react-icons/bi';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

class checkout extends React.Component {
    state = {
        
    }

    componentDidMount = () => {

    }

    checkout = () => {
        return (
            <div className="pay-page">
                <Container fluid className="jumbo">
                    <Row>
                        <Col sm={5}>
                            <div className="jumbo-content-left">
                                <h2>CHECKOUT</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="pay-body">
                    <div className="pay-card-container">
                    <Card style={{background: 'rgb(35,119,186)', textAlign:'center', margin: '0 auto', width: '60%', color: 'white', }}>
                            <h2><BiErrorCircle style={{marginTop:'auto', marginBottom:'auto'}} size={35}/> Error Occured</h2>
                        </Card>
                    </div>
                </Container>
            </div>
        )
    }

    render() {
        require('./Payment.css')
        return (
            <this.checkout />
        )
    }
}

const mapStateToProps = (state) => {
    console.log(state)
    return { auth: state.firebase.auth, profile: state.firebase.profile, user: state.user.user, authSuccess: state.auth.authSuccess, jwtToken: state.auth.jwtToken }
}

export default connect(mapStateToProps)(checkout)