import React from "react";
import {
  InputGroup,
  Form,
  FormControl,
  Button,
  Row,
  Col,
  Card,
  Container,
} from "react-bootstrap";
import { FiDownload } from "react-icons/fi";
import { Link } from "react-router-dom";
import { FaUserAlt } from "react-icons/fa";
import { HiDocument } from "react-icons/hi";

class LegalForms extends React.Component {
  state = {
    purchaseforms: [
      {
        name: "Standard Purchase And Sale Agreement",
        directory: `/files/Long Form Standard Purchase and Sale Agreement (1).docx`,
      },
      {
        name: "Standard Purchase And Sale Agreement Short Form",
        directory: "/files/SHORT STANDARD PURCHASE AND SALE AGREEMENT 1.docx",
      },
      {
        name: "Trust Agreement And Declaration Of Trust",
        directory: "/files/Trust Agreement and Declaration of trust.doc",
      },
      {
        name: "Holdover Occupancy Agreement",
        directory: "/files/HOLDOVER OCCUPANCY AGREEMENT.docx",
      },
      {
        name: "Addendum To Purchase & Sale Agreement",
        directory: "/files/Addendum To Purchase & Sale Agreement (2).pdf",
      },
      {
        name: "Authorization To Release Loan Information (2nd Loan)",
        directory:
          "/files/Authorization To Release 2nd Loan Information (1).docx",
      },
      {
        name: "Joint Venture Agreement",
        directory: "/files/JOINT VENTURE AGREEMENT (2).docx",
      },
      {
        name: "Cost Estimator",
        directory: "/files/Cost_Estimator_Form.pdf",
      },
    ],
    existingloanforms: [
      {
        name: "Promissory Note",
        directory: "/files/PROMISSORY NOTE 1.docx",
      },
      {
        name: "Warranty Deed",
        directory: "/files/WARRANTY DEED (1).docx",
      },
      {
        name: "Bill Of Sale",
        directory: "/files/BILL OF SALE (1).docx",
      },
      {
        name: "Warranty Deed To Trustee",
        directory: "/files/WARRANTY DEED TO TRUSTEE (1).docx",
      },
      {
        name: "Quit-Claim Deed",
        directory: "/files/QUIT-CLAIM DEED (1).docx",
      },
      {
        name: "Limited Power of Attorney",
        directory: "/files/LIMITED POWER OF ATTORNEY (2).docx",
      },
      {
        name: "Promissory Note (Balloon Note)",
        directory: "/files/Balloon PROMISSORY NOTE (1).docx",
      },
      {
        name: "Seller Information Sheet",
        directory: "/files/Seller Information Sheet.docx",
      },
      {
        name: "Assignment Of Beneficial Interest In Land Trust",
        directory: "/files/ASSIGNMENT OF BENEFICIAL INTEREST.docx",
      },
      {
        name: "Affidavit Of Deed & Completed Sale",
        directory: "/files/Affidavit of Deed & Completed Sale.docx",
      },
      {
        name: "Seller's Affidavit (No Leins)",
        directory: "/files/Seller’s Affidavit (No Liens).docx",
      },
      {
        name: `"Due-On-Sale" Acknowledgement (No Promises) `,
        directory: "/files/No promises “Due-on-Sale” Acknowledgement.docx",
      },
      {
        name: `Due-On-Sale" Acknowledgement`,
        directory: "/files/Due-on-Sale Acknowledgement (1).docx",
      },
      {
        name: "Seller's Statement Of Understanding",
        directory: "/files/SELLER'S STATEMENT OF UNDERSTANDING.docx",
      },
      {
        name: "Escrow Account Letter",
        directory: "/files/Escrow Account letter.docx",
      },
      {
        name: "Fax Cover Page",
        directory: "/files/FAX Page.docx",
      },
    ],
  };

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  legal = () => {
    return (
      <div className="legal-page">
        <Container fluid className="jumbo">
          <Row>
            <Col sm={5}>
              <div className="jumbo-content-left">
                <h2>Real Estate Legal Forms</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="legal-body">
          <h4>Legal Forms</h4>
          <span>
            When you're buying properties in or out of state a key thing to
            avoid loss or legal action are correct legal forms. On InvestFar we
            make it easy for investors to find forms for their REI needs as we
            have categorized them based on most frequent transaction types. At
            closings you will need the right documents whether buying or selling
            a property, engaging n joint ventures or taking over a property
            subject to existing loan. We have you covered.
          </span>
          <br />
          <br />
          <span>Less stress more investing.</span>
          <div className="card-container">
            <Card className="card">
              <Card.Header className="legal-card-header">
                PURCHASE AND SALES AGREEMENT FORMS
              </Card.Header>
              {this.state.purchaseforms
                ? this.state.purchaseforms.map((item, index) => {
                    return (
                      <Link to={item.directory} target="_blank" download>
                        <Card
                          className="legal-form-card"
                          // onClick={()=>{this.showPDF(item.directory)}}
                        >
                          <Card.Body>
                            <Row>
                              <FaUserAlt
                                className="user-icon"
                                size={24}
                                color="gray"
                              />
                              <Col style={{ color: "black" }}>{item.name}</Col>
                              <HiDocument
                                className="doc-icon"
                                size={30}
                                color="rgb(35,119,186)"
                              />
                            </Row>
                          </Card.Body>
                        </Card>
                      </Link>
                    );
                  })
                : "no forms available"}
            </Card>
            <br />
            <br />
            <Card className="card">
              <Card.Header className="legal-card-header">
                SUBJECT TO EXISTING LOAN
              </Card.Header>
              {this.state.existingloanforms
                ? this.state.existingloanforms.map((item, index) => {
                    return (
                      <Link to={item.directory} target="_blank" download>
                        <Card
                          className="legal-form-card"
                          // onClick={()=>{this.showPDF(item.directory)}}
                        >
                          <Card.Body>
                            <Row>
                              <FaUserAlt
                                className="user-icon"
                                size={24}
                                color="gray"
                              />
                              <Col style={{ color: "black" }}>{item.name}</Col>
                              <HiDocument
                                className="doc-icon"
                                size={30}
                                color="rgb(35,119,186)"
                              />
                            </Row>
                          </Card.Body>
                        </Card>
                      </Link>
                    );
                  })
                : "no forms available"}
            </Card>
          </div>
        </Container>
      </div>
    );
  };

  render() {
    require("./LegalForms.css");
    return <this.legal />;
  }
}

export default LegalForms;
