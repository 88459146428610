// import { saveState, loadState } from '../localStorage'

// var initState = loadState()
// initState = initState ? initState : { user: null }

var initState = { user: null }




const userReducer = (state = initState, action) => {

    switch (action.type) {
        case 'CREATE_USER':
            console.log('Created User', action.user);
            // saveState({ ...state, user: action.user })
            return {
                ...state, user: action.user
            }
        case 'UPDATE_USER':
            console.log("HERE");
        case 'RETURN_USER':
            console.log("HEREUSERRETURN");
            return {
                ...state, user: action.userObj
            }
        default:
            return state;
    }
}


export default userReducer;