import React from "react";
import {
  InputGroup,
  Form,
  FormControl,
  Button,
  Row,
  Col,
  Card,
  Container,
} from "react-bootstrap";
import { FiDownload } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Error, Primary, Success, Info, Warning } from "../../Alerts";
import axios from "axios";

class Contact extends React.Component {
  state = {
    name: "",
    email: "",
    subject: "",
    description: "",
  };

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  changeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  submitContact = (query, cb) => {
    var email = this.state.email;
    axios
      .post("/api/services/send-contact", {
        contactName: `${this.state.name}`,
        contactEmail: `${email}`,
        contactSubject: `${this.state.subject}`,
        message: `${this.state.description}`,
      })
      .then((response) => {
        if (response) {
          console.log(response);
          Info({
            title: "Message Delivered",
            message:
              "An InvestFar representative will be in contact with you shortly! ",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Error({
          title: "Error Encountered",
          message: "Error encountered when sending contact email!",
        });
      });
  };

  contact = () => {
    return (
      <div className="contact-page">
        <div className="jumbo">
          <Row>
            <Col>
              <div className="jumbo-content-left">
                <h2>CONTACT US</h2>
              </div>
            </Col>
          </Row>
        </div>
        <Container fluid className="contact-body">
          <Row>
            <Col sm={4}>
              <Card className="contact-image" />
            </Col>
            <Col>
              <Card className="contact-form">
                <br />
                <br />
                <Form className="form">
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      onChange={this.changeHandler}
                      name="name"
                      type="name"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      onChange={this.changeHandler}
                      name="email"
                      type="email"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      onChange={this.changeHandler}
                      name="subject"
                      type="text"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      onChange={this.changeHandler}
                      className="form-description"
                      name="description"
                      type="text"
                    />
                  </Form.Group>
                  <div className="button-container">
                    <Button
                      onClick={() => this.submitContact()}
                      className="btn-blue"
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  render() {
    require("./Contact.css");
    return <this.contact />;
  }
}

export default Contact;
