import React from "react";
import { Redirect } from "react-router-dom";
import {
  Alert,
  InputGroup,
  Form,
  FormControl,
  Dropdown,
  DropdownButton,
  Button,
  Row,
  Col,
  Container,
  Card,
} from "react-bootstrap";

import { Link } from "react-router-dom";
import {
  TiSocialFacebookCircular,
  TiSocialLinkedinCircular,
} from "react-icons/ti";
import axios from "axios";
import { connect } from "react-redux";
import {
  createUser,
  loginWithFaceBook,
  resolveError,
} from "../../store/actions/authActions";
import { displayError } from "../../store/actions/authActions";
import { loginUser } from "../../store/actions/authActions";
import { provider, auth, database } from "../../config/fbConfig";
import jsonwebtoken from "jsonwebtoken";
import { User } from "../../Constructors/user";
import { Error, Primary, Success, Info, Warning } from "../../Alerts";
import { LinkedIn } from "react-linkedin-login-oauth2";
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import AutoComplete from "../../AutoComplete/AutoComplete";

import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
class Login extends React.Component {
  state = {
    name: "",
    country: "",
    city: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
    businessAddress: "",
    licensed: "",
    otherLicenses: "",
    brokerAddress: "",
    websiteUrl: "",
    website: "",
    email: "",
    password: "",
    moreDetails: false,
    facebook: true,
    linkedinCode: "",
    linkedinErrorMessage: "",
  };

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  handleLinkedinSuccess = (data) => {
    console.log("LINKEDIN SUCCESS: ", data);
    this.setState({
      linkedinCode: data.code,
      linkedinErrorMessage: "",
    });

    axios
      .get("/api/users/linkedin-login", { params: { id: data.code } })
      .then((res) => {
        if (res.data.success) {
          var email = res.data.msg;
          console.log("LINKEDIN RESPONSE: ", res.data, email);
          auth()
            .signInWithCustomToken(res.data.customToken)
            .then(({ user }) => {
              console.log("USER BAHA", user);
              database
                .collection("Users")
                .where("email", "==", email)
                .get()
                .then(function (snapShot) {
                  if (snapShot.empty) {
                    var userObj = {
                      user_id: user.uid,
                      avatar_url: user.photoURL,
                      full_name: email,
                      phone_number: user.phoneNumber,
                      email: email,
                      user_name: email,
                      registration_type: "Investor",
                      quickblox_user_id: "",
                      connection_list: {},
                      moreInformation: true,
                    };
                    console.log({ exists: false, data: "User does not exist" });
                    database
                      .collection("Users")
                      .doc(user.uid)
                      .set(userObj)
                      .then((results) => {
                        console.log(
                          "User object created, here is user",
                          results
                        );
                      })
                      .catch((err) => {
                        console.log("Faced an error, user object not created");
                      });
                    // this.setState({ moreDetails: true })
                  }
                  snapShot.forEach((doc) => {
                    if (doc.exists) {
                      console.log({ exists: true, data: doc.data() });
                    } else {
                      if (!doc.exists) {
                        //validate email
                        var userObj = {
                          user_id: user.uid,
                          avatar_url: user.photoURL,
                          full_name: email,
                          phone_number: user.phoneNumber,
                          email: email,
                          user_name: user.uid,
                          registration_type: "Investor",
                          quickblox_user_id: "",
                          connection_list: {},
                          moreInformation: true,
                        };
                        console.log({
                          exists: false,
                          data: "User does not exist",
                        });
                        database
                          .collection("Users")
                          .doc(user.uid)
                          .set(userObj)
                          .then((results) => {
                            console.log(
                              "User object created, here is user",
                              results
                            );
                          })
                          .catch((err) => {
                            console.log(
                              "Faced an error, user object not created"
                            );
                          });
                        // this.setState({ moreDetails: true })
                      }
                    }
                  });
                })
                .catch(function (error) {
                  console.log({ exists: false, data: error });
                });
            });
        }
      })
      .catch((error) => {
        console.error("LINKEDIN ERROR: ", error);
      });
  };

  handleLinkedinFailure = (error) => {
    console.log("LINKEDIN FAILURE: ", error);
    this.setState({
      linkedinCode: "",
      linkedinErrorMessage: error.errorMessage,
    });
  };
  selectCountry(val) {
    this.setState({ country: val });
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmitUser = (e) => {
    e.preventDefault();
    var errors = this.fieldCheck();
    if (errors.length > 0) {
      this.props.displayError(errors);
      window.scrollTo(0, 0);
    } else {
      let profileType;
      if (this.state.licensed == "Licensed Agent") {
        profileType = "Agent";
      }
      if (this.state.licensed == "Company") {
        profileType = "Company";
      }
      if (this.state.licensed == "Contractor") {
        profileType = "Contractor";
      }
      if (this.state.licensed == "None") {
        profileType = "Investor";
      }
      var addressDt = this.state.address;
      var user = new User(
        this.state.email,
        null,
        null,
        null,
        null,
        this.state.name,
        this.state.email,
        null,
        profileType,
        this.state.phoneNumber,
        null,
        null,
        this.state.websiteUrl,
        addressDt.address,
        this.state.city,
        this.state.state,
        this.state.country,
        this.state.zipCode,
        null,
        null
      );
      user.password = this.state.password;
      //console.log(user)
      this.props.createUser(user);
    }
  };

  fieldCheck = () => {
    var errorList = [];
    if (this.state.name == null || this.state.name == "") {
      errorList.push("Name Field is Required;");
    }
    if (this.state.country == null || this.state.country == "") {
      errorList.push("Country Field is Required;");
    }
    if (this.state.city == null || this.state.city == "") {
      errorList.push("City Field is Required;");
    }
    if (this.state.state == null || this.state.state == "") {
      errorList.push("State Field is Required;");
    }
    if (this.state.zipCode == null || this.state.zipCode == "") {
      errorList.push("Zip Code is Required;");
    }
    if (this.state.licensed == null || this.state.licensed == "") {
      errorList.push(
        "Select The Type of Profile You Want To Create: Licensed Agent, Company, Contractor, None.;"
      );
    }
    if (this.state.email == null || this.state.email == "") {
      errorList.push("Email Field is Required;");
    }
    if (this.state.password == null || this.state.password == "") {
      errorList.push("Password Field is Required;");
    }
    return errorList;
  };

  handleSubmitLogin = (e) => {
    e.preventDefault();
    this.props.loginUser(this.state);
  };
  validateEmail = (mail) => {
    if (
      /^ [a - zA - Z0 - 9.!#$ %& '*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    alert("You have entered an invalid email address!");
    return false;
  };

  handleFacebook = (e) => {
    this.setState({
      facebook: true,
    });
    var that = this;
    e.preventDefault();
    auth()
      .signInWithPopup(provider)
      .then(({ user }) => {
        console.log("User", user);
        // this.setState({ user })
        database
          .collection("Users")
          .where("email", "==", user.email)
          .get()
          .then(function (snapShot) {
            if (snapShot.empty) {
              var userObj = {
                user_id: user.uid,
                avatar_url: user.photoURL,
                full_name: user.providerData[0].displayName,
                phone_number: user.phoneNumber,
                email: user.email,
                user_name: user.email,
                registration_type: "Investor",
                quickblox_user_id: "",
                connection_list: {},
                moreInformation: true,
              };
              console.log({ exists: false, data: "User does not exist" });
              database
                .collection("Users")
                .doc(user.uid)
                .set(userObj)
                .then((results) => {
                  console.log("User object created, here is user", results);
                })
                .catch((err) => {
                  console.log("Faced an error, user object not created");
                });
              // this.setState({ moreDetails: true })
            }
            snapShot.forEach((doc) => {
              if (doc.exists) {
                console.log({ exists: true, data: doc.data() });
              } else {
                if (!doc.exists) {
                  //validate email
                  var userObj = {
                    user_id: user.uid,
                    avatar_url: user.photoURL,
                    full_name: user.providerData[0].displayName,
                    phone_number: user.phoneNumber,
                    email: user.email,
                    user_name: user.email,
                    registration_type: "Investor",
                    quickblox_user_id: "",
                    connection_list: {},
                    moreInformation: true,
                  };
                  console.log({ exists: false, data: "User does not exist" });
                  database
                    .collection("Users")
                    .doc(user.uid)
                    .set(userObj)
                    .then((results) => {
                      console.log("User object created, here is user", results);
                    })
                    .catch((err) => {
                      console.log("Faced an error, user object not created");
                    });
                  // this.setState({ moreDetails: true })
                }
              }
            });
          })
          .catch(function (error) {
            console.log({ exists: false, data: error });
          });
      });
  };

  login = () => {
    const { authError, auth } = this.props;
    const { authSuccess } = this.props;
    var setLocation = (loc) => {
      this.setState({ address: loc });
    };

    const { country } = this.state;
    //  alert(setLocation)
    if (authError) {
      if (typeof authError == "object") {
        authError.map((error) => {
          Error({ title: "Error", message: error });
        });
        this.props.resolveError();
      } else {
        Error({ title: "Error", message: authError });
        this.props.resolveError();
      }
    }

    if (auth.uid) {
      return <Redirect to="/profile" />;
    }
    return (
      <div className="login-page">
        <Container fluid className="jumbo">
          <Row>
            <Col sm={5}>
              <div className="jumbo-content-left">
                <h2>SIGN IN/SIGN UP</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="searchbox">
          <div className="searchbox-content">
            <Row>
              <Col>
                <InputGroup size="sm" className="searchdropdown">
                  <Form.Control as="select" custom>
                    <option>All Offer</option>
                    <option>Buy</option>
                    <option>Rent</option>
                    <option>Sell</option>
                    <option>Sold</option>
                  </Form.Control>
                  <Form.Control as="select" custom>
                    <option>City</option>
                  </Form.Control>
                  <Form.Control as="select" custom>
                    <option>All Types</option>
                    <option>Condo</option>
                    <option>Plot</option>
                    <option>Single Family</option>
                    <option>Multi Family</option>
                    <option>Apartment</option>
                    <option>Duplex</option>
                    <option>Office</option>
                    <option>Townhouse</option>
                  </Form.Control>
                  <Form.Control placeholder="Min price" />
                  <Form.Control placeholder="Max price" />
                  <Form.Control as="select" custom>
                    <option>$</option>
                    <option>EUR</option>
                  </Form.Control>
                </InputGroup>
                <div className="search-buttons">
                  <Button className="button" data-testid="home-clear-button">
                    Clear
                  </Button>
                  <Button className="button" data-testid="home-search-button">
                    Search
                  </Button>
                  <Button className="button" data-testid="home-advance-button">
                    Advance
                  </Button>
                </div>
              </Col>
            </Row>
            <br />
            <br />
          </div>
        </Container>
        <div className="account">
          <Row>
            <h6>
              Create a free account for access to advanced home-search features,
              and sign up for our newsletter to be among the first to learn
              about exclusive properties, receive insider real estate tips,
              discover the latest in home trends, and more.
            </h6>
          </Row>
          <Container className="sign">
            <Row>
              <Col sm={6}>
                {/* <Card className="signin-card"> */}
                <Form onSubmit={this.handleSubmitLogin.bind(this)} className="signin-card">
                  <br />
                  <br />
                  <h4>Sign in to your account</h4>
                  <Form.Group as={Col} className="email">
                    <Col>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        name="email"
                        onChange={this.handleChange.bind(this)}
                        className="form-control"
                        type="email"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Col} className="password">
                    <Col>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        name="password"
                        onChange={this.handleChange.bind(this)}
                        className="password-control"
                        type="password"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Col} className="remember">
                    <Col>
                      <Form.Check label="Remember me" />
                    </Col>
                    <Col>
                      <Link className="forgot" to="/forgot-password">
                        Forgot password?
                      </Link>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col>
                      <Button className="sign-button btn-blue" type="submit">
                        Sign in
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
                <h5>or</h5>
                <Form.Row>
                  <div className="alternate-login">
                    <TiSocialFacebookCircular
                      onClick={(event) => this.handleFacebook(event)}
                      className="oauth-login"
                      size={90}
                      color={"rgb(52,127,187)"}
                    />
                    {/* <TiSocialLinkedinCircular className="oauth-login" size={94} color={"rgb(52,127,187)"} /> */}
                    <LinkedIn
                      clientId="787pi6nx71fd7i"
                      onFailure={this.handleLinkedinFailure}
                      onSuccess={this.handleLinkedinSuccess}
                      redirectUri="http://localhost:3000/linkedin"
                    >
                      <TiSocialLinkedinCircular
                        className="oauth-login"
                        size={94}
                        color={"rgb(52,127,187)"}
                      />
                      {/* <img src={linkedin} alt="Log in with Linked In" style={{ maxWidth: '180px' }} /> */}
                    </LinkedIn>
                  </div>
                </Form.Row>
                {/* </Card> */}
              </Col>
              <Col>
                <div className="sign_with_google">
                  <br/>
                  <br/>
                  <h4>Sign up for an account</h4>
                  <div className="app-download-login">
                    <a
                      href="https://apps.apple.com/us/app/investfar-real-estate/id1327794180"
                      target="_blank"
                    >
                      <img src="images/App_Store_Badge.svg" className="app-download-login-img" alt="" />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.investfar"
                      target="_blank"
                    >
                      <img src="images/play-store-badge-en.svg" className="app-download-login-img" alt="" />
                    </a>
                  </div>
                </div>
                {/* <Card className="signin-card">
                  <Form onSubmit={this.handleSubmitUser.bind(this)}>
                    <br />
                    <br />
                    <h4>Sign up for an account</h4>
                    <div className="signup-content">
                      <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          name="name"
                          onChange={this.handleChange.bind(this)}
                          placeholder="Name"
                        />
                      </Form.Group>
                      <Form.Row>
                        <Form.Group as={Col} sm={6} controlId="formGridEmail">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            name="email"
                            onChange={this.handleChange.bind(this)}
                            type="email"
                            placeholder="Enter email"
                          />
                        </Form.Group>

                        <Form.Group as={Col} sm={6}>
                          <Form.Label>Website URL</Form.Label>
                          <Form.Control
                            name="websiteUrl"
                            onChange={this.handleChange.bind(this)}
                            placeholder="Website URL"
                          />
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} sm={6}>
                          <Form.Label> Country</Form.Label>

                          <CountryDropdown
                            className="form-control"
                            value={country}
                            onChange={(val) => this.selectCountry(val)}
                          />
                        </Form.Group>
                        <Form.Group as={Col} sm={6}>
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control
                            name="phoneNumber"
                            onChange={this.handleChange.bind(this)}
                            placeholder="Phone Number"
                          />
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} sm={6}>
                          <Form.Label>Primary Address</Form.Label>

                          <AutoComplete
                            name="address"
                            style={{ width: "100%" }}
                            setLocation={setLocation}
                          />
                          <Form.Control name="address" onChange={this.handleChange.bind(this)} type="address" placeholder="Primary Business Address" />
                        </Form.Group>
                        <Form.Group as={Col} sm={6}>
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            name="city"
                            onChange={this.handleChange.bind(this)}
                            placeholder="City"
                          />
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} sm={6}>
                          <Form.Label>State</Form.Label>
                          <Form.Control
                            name="state"
                            onChange={this.handleChange.bind(this)}
                            type="state"
                            placeholder="State"
                          />
                        </Form.Group>
                        <Form.Group as={Col} sm={6}>
                          <Form.Label>Zip Code</Form.Label>
                          <Form.Control
                            name="zipCode"
                            onChange={this.handleChange.bind(this)}
                            placeholder="Zip Code"
                          />
                        </Form.Group>
                      </Form.Row>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          name="password"
                          onChange={this.handleChange.bind(this)}
                          type="password"
                          placeholder="Password"
                        />
                      </Form.Group>
                      <Form.Row>
                        <Form.Group as={Col} sm={12}>
                          <Form.Label>
                            Are You A Licensed Agent, Company, or Contractor?{" "}
                          </Form.Label>
                          <Form.Control
                            name="licensed"
                            onChange={this.handleChange.bind(this)}
                            as="select"
                            defaultValue=""
                          >
                            <option></option>
                            <option>Licensed Agent</option>
                            <option>Company</option>
                            <option>Contractor</option>
                            <option>None</option>
                          </Form.Control>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} sm={6}>
                          <Form.Label>Other Licenses</Form.Label>
                          <Form.Control
                            name="otherLicenses"
                            onChange={this.handleChange.bind(this)}
                            placeholder="Other Licenses"
                          />
                        </Form.Group>
                        <Form.Group as={Col} sm={6}>
                          <Form.Label>Broker Address</Form.Label>
                          <Form.Control
                            name="brokerAddress"
                            onChange={this.handleChange.bind(this)}
                            placeholder="Broker Address"
                          />
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} sm={12}>
                          <Form.Label>Website</Form.Label>
                          <Form.Control
                            name="website"
                            onChange={this.handleChange.bind(this)}
                            placeholder="Website"
                          />
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group>
                          <Form.File
                            className="attach"
                            id="exampleFormControlFile1"
                            label="Please attach Certificates"
                          />
                        </Form.Group>
                      </Form.Row>
                      <Form.Group as={Row}>
                        <Col>
                          <Button
                            className="sign-button btn-blue"
                            type="submit"
                          >
                            Sign Up
                          </Button>
                        </Col>
                      </Form.Group>
                    </div>
                  </Form>
                </Card> */}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  };

  render() {
    require("./Login.css");
    return <this.login />;
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    authSuccess: state.auth.authSuccess,
    jwtToken: state.auth.jwtToken,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (user) => dispatch(createUser(user)),
    loginUser: (user) => dispatch(loginUser(user)),
    displayError: (error) => dispatch(displayError(error)),
    resolveError: () => dispatch(resolveError()),
    loginWithFaceBook: () => dispatch(loginWithFaceBook()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
