import React from "react";
import {
  InputGroup,
  Form,
  FormControl,
  Dropdown,
  DropdownButton,
  Card,
  Button,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
  TiSocialFacebookCircular,
  TiSocialLinkedinCircular,
} from "react-icons/ti";
import { Error, Primary, Success, Info, Warning } from "../Alerts";
import axios from "axios";
import { connect } from "react-redux";

class Valuation extends React.Component {
  state = {
    zipcode: "",
    address: "",
  };

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  changeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  RedirectToLogin = () => {
    window.location = "/login";
  };

  submitValuation = (query) => {
    var email = query;
    console.log("EMAIL HERE: ", email);
    if (email && email !== "undefined") {
      axios
        .post("/api/services/send-valuation-request", {
          zipcode: `${this.state.zipcode}`,
          address: `${this.state.address}`,
          contactEmail: `${email}`, // logged in user email
        })
        .then((response) => {
          if (response) {
            console.log(response);
            Info({
              title: "Valuation Request Sent!",
              message:
                "An InvestFar representative will be in contact with you shortly! ",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Error({
            title: "Error Encountered",
            message: "Error encountered when sending valuation request!",
          });
        });
    } else {
      this.RedirectToLogin();
    }
  };

  valuation = () => {
    const { user, profile } = this.props;
    console.log("Current User", profile);
    return (
      <div className="valuation-page">
        <Container fluid className="jumbo">
          <Row>
            <Col sm={5}>
              <div className="jumbo-content-left">
                <h2>Valuation Request Form</h2>
              </div>
            </Col>
          </Row>
        </Container>
        {/* <Container fluid className="searchbox">
                    <div className="searchbox-content">
                        <Row>
                            <Col>
                                <InputGroup size="sm" className="searchdropdown">
                                    <Form.Control as="select" custom>
                                        <option>All Offer</option>
                                        <option>Buy</option>
                                        <option>Rent</option>
                                        <option>Sell</option>
                                        <option>Sold</option>
                                    </Form.Control>
                                    <Form.Control as="select" custom>
                                        <option>City</option>
                                    </Form.Control>
                                    <Form.Control as="select" custom>
                                        <option>All Types</option>
                                        <option>Condo</option>
                                        <option>Plot</option>
                                        <option>Single Family</option>
                                        <option>Multi Family</option>
                                        <option>Apartment</option>
                                        <option>Duplex</option>
                                        <option>Office</option>
                                        <option>Townhouse</option>
                                    </Form.Control>
                                    <Form.Control placeholder="Min price" />
                                    <Form.Control placeholder="Max price" />
                                    <Form.Control as="select" custom>
                                        <option>$</option>
                                        <option>EUR</option>
                                    </Form.Control>
                                </InputGroup>
                                <div className="search-buttons">
                                    <Button
                                        onClick={() => this.test()}
                                        className="button" data-testid='home-clear-button'>Clear</Button>
                                    <Button className="button" data-testid='home-search-button'>Search</Button>
                                    <Button className="button" data-testid='home-advance-button'>Advance</Button>
                                </div>
                            </Col>
                        </Row>
                        <br /><br />
                    </div>
                </Container> */}
        <Container fluid className="valuation-body">
          <Container>
            <h6>
              Enter the zip code and address of the property so we can find the
              most comprehensive residential data impacting homes; including
              hostorial, macroeconomic, and local market analytics to create a
              home valuation report to aid in your buying decisions.
            </h6>
          </Container>
          <br />
          <Card className="value-search-card" style={{ padding: "20px" }}>
            <Form className="valuation-form">
              <Form.Group>
                <Form.Label>Enter Zip Code:</Form.Label>
                <Form.Control
                  name="zipcode"
                  onChange={this.changeHandler}
                  className="value-control"
                  placeholder="Enter 5 digit Zip Code"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Enter Address:</Form.Label>
                <Form.Control
                  name="address"
                  onChange={this.changeHandler}
                  className="value-control"
                  placeholder="E.g.7904 Verde Springs Dr"
                />
              </Form.Group>
              <Button
                onClick={() => this.submitValuation(profile.email)}
                className="btn-blue getdetails-button"
              >
                Get Details
              </Button>
            </Form>
          </Card>
          <br />
        </Container>
      </div>
    );
  };

  render() {
    require("./Valuation.css");
    return <this.valuation />;
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(Valuation);
