import React, { Component } from "react";
import "./UserProfile.css";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import jsonwebtoken from "jsonwebtoken";
import { Redirect } from "react-router-dom";
import {
  InputGroup,
  Dropdown,
  Form,
  Button,
  Row,
  Col,
  Card,
  Spinner,
  Container,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import { Error, Primary, Success, Info, Warning } from "../../Alerts";
import { updateProfile } from "../../store/actions/userActions";
import AutoComplete from "../../AutoComplete/AutoComplete";
import firebase, { provider, auth, database } from "../../config/fbConfig";
import Far from "../../Spinners/Far";

// import { delete } from '../../../../backend/functions/config/db';

class UserProfile extends React.Component {
  state = {
    profileImg: null,
    profileImgData: null,
    firstname: null,
    lastname: null,
    email: null,
    phoneNumber: null,
    address: null,
    bio: null,
    plan: {
      name: "Novice_Test",
      status: "Active",
      description:
        "5 Valuation/Analytic Reports, Investor Consultants, Directory Access, InvestFar App, Free listing, Blog",
    },
    moreInformation: false,
    updateInfo: {
      moreInformation: false,
    },
    updating: false,
    profile: false,
  };

  // componentDidMount = () => {
  //     console.log("User", firebase)
  //     window.scrollTo(0, 0)
  //     if (this.props.profile && this.props.profile.moreInformation) {
  //         console.log("Need More Informaton")
  //         this.setState({
  //             moreInformation: true
  //         })
  //     }
  // }

  // componentDidUpdate = (prevProps, prevState) => {
  //     if (prevProps.profile !== this.props.profile) {
  //         console.log("Profile Changed")
  //         this.setState({
  //             profile: this.props.profile
  //         })
  //     }
  // }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  handleChange = (e) => {
    console.log("field name", e.target.name);
    this.setState({ [e.target.name]: e.target.value });
  };

  imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ profileImg: reader.result });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    this.setState({ profileImgData: e.target.files[0] });
  };

  handleSubmitProfile = (e) => {
    e.preventDefault();
    this.props.updateProfile(this.state);
  };

  handleSubmitUser = (e) => {
    // e.preventDefault();
    // var errors = this.fieldCheck();
    // if (errors.length > 0) {
    //     this.props.displayError(errors);
    //     window.scrollTo(0, 0);
    // }
    // else {
    //     let profileType;
    //     if (this.state.licensed == "Licensed Agent") {
    //         profileType = "Agent"
    //     }
    //     if (this.state.licensed == "Company") {
    //         profileType = "Company"
    //     }
    //     if (this.state.licensed == "Contractor") {
    //         profileType = "Contractor"
    //     }
    //     if (this.state.licensed == "None") {
    //         profileType = "Investor"
    //     }
    //     var user = new User(this.state.email, null, null, null, null, this.state.name, this.state.email, null, profileType, this.state.phoneNumber, null, null, this.state.websiteUrl, this.state.businessAddress, this.state.city, this.state.state, this.state.country, this.state.zipCode, null, null);
    //     user.password = this.state.password;
    //     this.props.createUser(user);
    // }
  };

  cancelSubscription = async () => {
    const { profile } = this.props;
    var customerId = profile.plan.customer_id;

    if (profile.plan.status != "cancelled") {
      const checkoutSession = await axios
        .post("/api/payments/cancel-subscription", {
          customerId: customerId,
        })
        .then((response) => {
          if (response) {
            if (response.data.success === true) {
              Info({
                title: "Plan Cancelled",
                message: "A confirmation email will be sent to you shortly!",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      Error({
        title: "Error Encountered",
        message: "You do not have any active plans at this time.",
      });
    }
  };

  handleChange = (e) => {
    var updateInfo = this.state.updateInfo;
    updateInfo[`${e.target.name}`] = e.target.value;
    this.setState({ updateInfo });
  };

  handleUpdate = (e) => {
    var updatedFields = this.state.updateInfo;
    updatedFields["moreInformation"] = false;
    this.setState({
      updating: true,
    });
    Object.keys(updatedFields).map((field) => {
      if (
        updatedFields[field] == "undefined" ||
        updatedFields[field] == null ||
        updatedFields[field] == "" ||
        updatedFields[field] == " "
      ) {
        delete updatedFields[field];
      }
    });

    database
      .collection("Users")
      .doc(this.props.profile.user_id)
      .update(updatedFields)
      .then((results) => {
        console.log("Here are the results", results);
        this.setState({
          updating: false,
          moreInformation: false,
        });
      })
      .catch((err) => {
        this.setState({
          updating: false,
        });
        console.log("Here is the error", err);
      });

    console.log("Info", updatedFields);
  };

  // MoreInfo = () => {
  //     var handleClose = () => {
  //         this.setState({
  //             moreInformation: false
  //         })
  //     }
  //     var setLocation = (loc) => {
  //         console.log('location here', loc)
  //         var location = JSON.parse(JSON.stringify(loc))
  //         console.log("Location", location)
  //         var updateInfo = this.state.updateInfo
  //         updateInfo['location'] = location
  //         updateInfo['address'] = loc.address
  //         updateInfo['city'] = loc.city
  //         updateInfo['state'] = loc.state
  //         updateInfo['zip'] = loc.zip
  //         updateInfo['country'] = loc.country
  //         updateInfo['latitude'] = loc.geometry.location.lat()
  //         updateInfo['longitude'] = loc.geometry.location.lng()

  //         this.setState({
  //             updateInfo
  //         })
  //     }

  //     var setBrokerAddress = (loc) => {
  //         var updateInfo = this.state.updateInfo
  //         updateInfo['brokerAddress'] = JSON.parse(JSON.stringify(loc))
  //         this.setState({
  //             updateInfo
  //         })
  //     }

  //     const { profile } = this.state

  //     return (
  //         <Modal size={'xl'}
  //             show={this.state.moreInformation} onHide={handleClose}>
  //             <Modal.Header style={{ backgroundColor: 'white' }} closeButton>
  //                 <Modal.Title>We need to get a little more information</Modal.Title>
  //             </Modal.Header>
  //             <Modal.Body style={{ backgroundColor: 'white', padding: '20px' }}>

  //                 {this.state.updating ? <div>
  //                     <div style={{ textAlign: 'center', height: '200px' }}>
  //                         <div style={{ height: '30px' }} />
  //                         <h5 className='loading'>Loading</h5>
  //                         <div style={{ height: '20px' }} />
  //                         {/* <Spinner variant='primary' animation='border' /> */}
  //                         <Far />
  //                         <div syle={{ height: '200px' }}></div>
  //                     </div>
  //                 </div> : <Form onSubmit={this.handleSubmitUser.bind(this)}>
  //                         <br /><br />

  //                         <h4>Please fill in any missing information</h4>
  //                         <p></p>
  //                         <p></p>
  //                         <div className="signup-content">
  //                             <Form.Group>
  //                                 <Form.Label>Name</Form.Label>
  //                                 <Form.Control name="full_name" onChange={this.handleChange.bind(this)} placeholder="Name" defaultValue={profile.full_name} />
  //                             </Form.Group>
  //                             <Form.Row>
  //                                 <Form.Group as={Col} sm={6} controlId="formGridEmail">
  //                                     <Form.Label>Email</Form.Label>
  //                                     <Form.Control name="email"
  //                                         defaultValue={profile.email}
  //                                         onChange={this.handleChange.bind(this)} type="email" placeholder="Enter email" />
  //                                 </Form.Group>

  //                                 <Form.Group as={Col} sm={6}>
  //                                     <Form.Label>Website URL</Form.Label>
  //                                     <Form.Control name="company_website" onChange={this.handleChange.bind(this)} placeholder="Website URL" />
  //                                 </Form.Group>
  //                             </Form.Row>
  //                             <Form.Row>
  //                                 <Form.Group as={Col} sm={6}>
  //                                     <Form.Label>Select Country</Form.Label>
  //                                     <Form.Control name="country" onChange={this.handleChange.bind(this)}
  //                                         defaultValue={profile.country ? profile.country : "Country Code"} as="select"
  //                                         placeholder="Country Code">
  //                                         <option>Country Code</option>
  //                                         <option>United States of America</option>
  //                                     </Form.Control>
  //                                 </Form.Group>
  //                                 <Form.Group as={Col} sm={6}>
  //                                     <Form.Label>Phone Number</Form.Label>
  //                                     <Form.Control
  //                                         defaultValue={profile.phone_number ? profile.phone_number : null}
  //                                         name="phone_number" onChange={this.handleChange.bind(this)} placeholder="Phone Number" />
  //                                 </Form.Group>
  //                             </Form.Row>

  //                             <Form.Group>
  //                                 <Form.Label>Primary Address</Form.Label>
  //                                 <AutoComplete style={{ width: '100%' }} setLocation={setLocation} />
  //                                 {/* <Form.Control name="address" onChange={this.handleChange.bind(this)} type="address" placeholder="Primary Business Address" /> */}
  //                             </Form.Group>
  //                             {/* <Form.Group as={Col} sm={6}>
  //                                 <Form.Label>City</Form.Label>
  //                                 <Form.Control name="city" onChange={this.handleChange.bind(this)} placeholder="City" />
  //                             </Form.Group> */}

  //                             {/* <Form.Row>
  //                             <Form.Group as={Col} sm={6}>
  //                                 <Form.Label>State</Form.Label>
  //                                 <Form.Control name="state" onChange={this.handleChange.bind(this)} type="state" placeholder="State" />
  //                             </Form.Group>
  //                             <Form.Group as={Col} sm={6}>
  //                                 <Form.Label>Zip Code</Form.Label>
  //                                 <Form.Control name="zipCode" onChange={this.handleChange.bind(this)} placeholder="Zip Code" />
  //                             </Form.Group>
  //                         </Form.Row> */}
  //                             {/* <Form.Group controlId="formBasicPassword">
  //                             <Form.Label>Password</Form.Label>
  //                             <Form.Control name="password" onChange={this.handleChange.bind(this)} type="password" placeholder="Password" />
  //                         </Form.Group> */}
  //                             <Form.Row>
  //                                 <Form.Group as={Col} sm={6}>
  //                                     <Form.Label>Are You A Licensed Agent, Company, or Contractor? </Form.Label>
  //                                     <Form.Control name="registration_type" onChange={this.handleChange.bind(this)} as="select" defaultValue="">
  //                                         <option></option>
  //                                         <option>Licensed Agent</option>
  //                                         <option>Company</option>
  //                                         <option>Contractor</option>
  //                                         <option>None</option>
  //                                     </Form.Control>
  //                                 </Form.Group>
  //                                 <Form.Group as={Col} sm={6}>
  //                                     <Form.Label>Other Licenses</Form.Label>
  //                                     <Form.Control name="other_licenses" onChange={this.handleChange.bind(this)} placeholder="Other Licenses" />
  //                                 </Form.Group>
  //                             </Form.Row>
  //                             <Form.Row>
  //                                 <Form.Group as={Col} sm={6}>
  //                                     <Form.Label>Broker Address</Form.Label>
  //                                     <AutoComplete style={{ width: '100%' }} setLocation={setBrokerAddress} />
  //                                     {/* <Form.Control name="brokerAddress" onChange={this.handleChange.bind(this)} placeholder="Broker Address" /> */}
  //                                 </Form.Group>
  //                                 <Form.Group as={Col} sm={6}>
  //                                     <Form.Label>Website</Form.Label>
  //                                     <Form.Control name="company_website" onChange={this.handleChange.bind(this)} placeholder="Website" />
  //                                 </Form.Group>
  //                             </Form.Row>
  //                             <Form.Row>
  //                                 <Form.Group>
  //                                     <Form.File className="attach" id="exampleFormControlFile1" label="Please attach Certificates" />
  //                                 </Form.Group>
  //                             </Form.Row>
  //                             {/* <Form.Group as={Row}>
  //                             <Col><Button className="sign-up-button" type="submit">Sign Up</Button></Col>
  //                         </Form.Group> */}
  //                         </div>
  //                     </Form>
  //                 }

  //             </Modal.Body>
  //             <Modal.Footer >
  //                 <Button size={'md'} variant="secondary" onClick={handleClose}>
  //                     Close
  //       </Button>
  //                 <Button size={'lg'} variant="primary" onClick={() => this.handleUpdate()}>
  //                     Save Changes
  //       </Button>
  //             </Modal.Footer>
  //         </Modal>)
  // }
  userprofile = () => {
    // function imageHandler(e) {
    //     const reader = new FileReader();
    //     reader.onload = () => {
    //         if(reader.readyState === 2){
    //             this.setState({profileImg: reader.result});
    //             console.log("SET!")
    //         }
    //         reader.readAsDataURL(e.target.files[0]);
    //     }
    // }

    const { user, profile } = this.props;
    const { authSuccess } = this.props;
    // console.log('User', user, 'Profile', profile, 'authSuccess', authSuccess)

    return profile == null ? (
      <div className="profile-page">
        <Spinner animation="grow" variant="primary" />
        <Spinner animation="grow" variant="secondary" />
        <Spinner animation="grow" variant="success" />{" "}
      </div>
    ) : (
      <div className="profile-page">
        <section className="inner-banner">
          <div className="profile-page-title">
            <Container>
              <Row className="clearfix">
                <Col className="profile-title-column" sm={12} xs={12}>
                  <h1>Edit Profile</h1>
                </Col>
              </Row>
              {/* <this.MoreInfo /> */}
            </Container>
          </div>
        </section>
        <div style={{ height: "50px" }} />

        <div style={{ margin: "auto" }}>
          <Row>
            <Col lg={4}></Col>
            <Col lg={6}>
              <Container style={{ margin: "auto", width: "60em" }}>
                <div style={{ margin: "auto" }}>
                  <Row className="profile-form agent padd-top-70 padd-bott-60">
                    <Col sm={8} md={6}>
                      <div className="profile-form-outer form-block">
                        <div className="default-title">
                          <h2>Edit Profile</h2>
                        </div>
                        {/* <div className="profile-pic"></div> */}
                        {this.state.profileImg == null ? null : (
                          <div className="profile-pic">
                            <img
                              src={this.state.profileImg}
                              alt="img"
                              title="img"
                            />
                          </div>
                        )}
                        {this.state.profileImg == null &&
                        profile.avatar_url != null ? (
                          <div className="profile-pic">
                            <img
                              src={profile.avatar_url}
                              alt="img"
                              title="img"
                            />
                          </div>
                        ) : null}

                        {this.state.profileImg == null &&
                        profile.avatar_url == null ? (
                          <div className="profile-pic"></div>
                        ) : null}

                        <div className="changeProfilePicBtn">
                          <span className="changeProfileBtn">
                            <label for="profileChange">
                              <i className="fa fa-camera"></i>
                              Upload Image
                              <input
                                type="file"
                                accept="image/*"
                                hidden
                                id="profileChange"
                                onChange={this.imageHandler}
                              />
                            </label>
                          </span>
                        </div>
                        <Card className="plan-container">
                          <Card className="plan">
                            <Card.Body>
                              <Card.Title>
                                Plan:{" "}
                                {profile.plan
                                  ? profile.plan.name
                                  : "No Plan Found"}
                              </Card.Title>
                              <Card.Subtitle className="mb-2">
                                {profile.plan
                                  ? profile.plan.status
                                  : "unavailable"}
                              </Card.Subtitle>
                              <Card.Text>
                                {/* {this.state.plan.description} */}
                              </Card.Text>
                              <Button
                                onClick={() => this.cancelSubscription()}
                                variant="dark"
                                className="cancel-button"
                              >
                                Cancel
                              </Button>
                            </Card.Body>
                          </Card>
                        </Card>
                        <br />
                        <Form onSubmit={this.handleSubmitProfile.bind(this)}>
                          <Form.Group className="profile-form-group">
                            <div>First Name</div>
                            <Form.Control
                              name="firstname"
                              placeholder="First Name"
                              className="profile-form-control"
                              onChange={this.handleChange.bind(this)}
                              type="name"
                            />
                          </Form.Group>
                          <Form.Group className="profile-form-group">
                            <div>Last Name</div>
                            <Form.Control
                              onChange={this.handleChange.bind(this)}
                              name="lastname"
                              placeholder={profile.full_name || "Last Name"}
                              className="profile-form-control"
                              type="name"
                            />
                          </Form.Group>
                          <Form.Group className="profile-form-group">
                            <div>Email</div>
                            <Form.Control
                              onChange={this.handleChange.bind(this)}
                              name="email"
                              placeholder={profile.email || "Email"}
                              className="profile-form-control"
                              type="email"
                            />
                          </Form.Group>
                          <Form.Row>
                            <Form.Group className="profile-form-group">
                              <div>Select Country</div>
                              <InputGroup className="profile-form-dropdown">
                                <Form.Control
                                  onChange={this.handleChange.bind(this)}
                                  as="select"
                                  custom
                                >
                                  <option>US +1</option>
                                  <option>UZ +998</option>
                                  <option>VA +379</option>
                                  <option>VE +58</option>
                                  <option>VN +84</option>
                                  <option>ZW 260+</option>
                                </Form.Control>
                              </InputGroup>
                            </Form.Group>
                            <Form.Group className="profile-form-group">
                              <div>Phone Number</div>
                              <Form.Control
                                onChange={this.handleChange.bind(this)}
                                placeholder={
                                  profile.phone_number || "Phone Number"
                                }
                                name="priceHighBuy"
                                className="profile-form-group-control"
                                type="tel"
                              />
                            </Form.Group>
                          </Form.Row>
                          <Form.Group className="profile-form-group">
                            <div>Address</div>
                            <Form.Control
                              onChange={this.handleChange.bind(this)}
                              name="address"
                              placeholder={profile.address || "Address"}
                              className="profile-form-control"
                              type="address"
                            />
                          </Form.Group>
                          <Form.Group className="profile-form-group">
                            <div>Bio</div>
                            <Form.Control
                              name="bio"
                              placeholder={profile.bio || "Bio"}
                              className="profile-form-control"
                              type="text"
                            />
                          </Form.Group>
                          <Button
                            className="btn-style-two width-full text-uppercase"
                            variant="primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <p></p>
                          <Button
                            onClick={() =>
                              this.setState({
                                moreInformation: true,
                              })
                            }
                            className="btn-style-two width-full text-uppercase"
                            variant="primary"
                            type="submit"
                          >
                            More Information
                          </Button>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
              <div style={{ height: "50px" }} />
            </Col>
            <Col></Col>
          </Row>
        </div>
      </div>
    );
  };

  render() {
    require("./UserProfile.css");
    return <this.userprofile />;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    authSuccess: state.auth.authSuccess,
    jwtToken: state.auth.jwtToken,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProfile: (profile) => dispatch(updateProfile(profile)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
