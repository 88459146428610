import React from 'react';
import { InputGroup, Form, FormControl, Button, Row, Col, Card, Container } from 'react-bootstrap';
import { FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { FaUserAlt } from 'react-icons/fa';
import { HiDocument } from 'react-icons/hi';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import {AiFillCheckCircle} from 'react-icons/ai'; 
import axios from 'axios';

class success extends React.Component {
    state = {

    }

    componentDidMount = () => {

    }

    success = () => {
        return (
            <div className="pay-page">
                <Container fluid className="jumbo">
                    <Row>
                        <Col sm={5}>
                            <div className="jumbo-content-left">
                                <h2>Success</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="pay-body">
                    <div className="pay-card-container">
                        <Card style={{background: 'rgb(35,119,186)', textAlign:'center', margin: '0 auto', width: '60%', color: 'white', }}>
                            <h2><AiFillCheckCircle style={{marginTop:'auto', marginBottom:'auto'}} size={35}/> Thank you! Your order has been confirmed!</h2>
                            <p1>Thank you for shopping with us. We'll send a confirmation email once your order has processed.</p1>
                        </Card>
                    </div>
                </Container>
            </div>
        )
    }

    render() {
        require('./Payment.css')
        return (
            <this.success />
        )
    }
}

const mapStateToProps = (state) => {
    console.log(state)
    return { auth: state.firebase.auth, profile: state.firebase.profile, user: state.user.user, authSuccess: state.auth.authSuccess, jwtToken: state.auth.jwtToken }
}

export default connect(mapStateToProps)(success)