import React from "react";
import {
  InputGroup,
  Form,
  FormControl,
  Button,
  Row,
  Col,
  Card,
  Container,
} from "react-bootstrap";
import { FiDownload } from "react-icons/fi";
import { Link } from "react-router-dom";
import { FaUserAlt } from "react-icons/fa";
import { HiDocument } from "react-icons/hi";
import Script from "react-load-script";
import Iframe from "react-iframe";
import Far from "../Spinners/Far";

const { innerWidth: width, innerHeight: height } = window;

class OrganizerLogin extends React.Component {
  constructor(props) {
    super(props);
    this._ref = React.createRef();
  }
  state = {
    test: "",
    loading: true,
  };

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  org = () => {
    console.log("Heig", height);
    return (
      <div className="mls-page">
        <Container fluid className="jumbo">
          <Row>
            <Col sm={5}>
              <div className="jumbo-content-left">
                <h2>ORGANIZER LOGIN</h2>
              </div>
            </Col>
          </Row>
        </Container>
        {this.state.loading ? (
          <div style={{ textAlign: "center" }}>
            <div style={{ height: "30px" }} />
            <h5 className="loading">Loading</h5>
            <div style={{ height: "100px" }} />
            {/* <Spinner variant='primary' animation='border' /> */}
            <Far />
            <div style={{ height: height }} />
          </div>
        ) : null}
        <Iframe
          url="https://www.idxhome.com/organizer/login/155924"
          loading="LOading"
          width="100%"
          height={height * 1.5}
          id="myId"
          className="myClassname"
          display="initial"
          position="relative"
          onLoad={() => this.setState({ loading: false })}
        />
        <div style={{ height: "100px" }} />
      </div>
    );
  };

  render() {
    require("./mlsPropertySearch.css");
    return <this.org />;
  }
}

export default OrganizerLogin;
