import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button, Spinner } from "react-bootstrap";
import "./MyProperties.css";
import { BsTrash, BsPencilSquare, BsAlarm } from "react-icons/bs";
import { User, CURRENT } from "../../Constructors/user";
import { Error, Warning, Primary, Info, Success } from "../../Alerts";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import { store } from "react-notifications-component";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Far from "../../Spinners/Far";
import axios from "axios";
var request = require("request");

function MyProperties(props) {
  const [propertyList, setProperties] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [current, setCurrent] = useState({});
  const [deleteProperty, setDeleteProperty] = useState(false);
  const [deleted, setDeleted] = useState(null);
  const [loading, setLoading] = useState(true);
  const [path, setPath] = useState(null);
  const [delIndex, setDelIndex] = useState(null);
  // useEffect(() => {
  // const { authSuccess } = props;
  // const { user } = props;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const { authSuccess } = props;
    const { user, profile } = props;
    console.log("Current User", profile);
    axios
      .get(`/api/properties/user-properties?id=${profile.user_id}`)
      .then((res) => {
        if (res.data) {
          setProperties(res.data.msg);
        } else {
          setProperties([]);
        }
        setLoading(false);
      });
  }, []);

  var handleRedirect = (current, path) => {
    setRedirect(true);
    setCurrent(current);
    setPath(path);
    console.log("Redirecting to edit", current);
  };

  var DeletingProperty = () => {
    return (
      <div
        style={{ textAlign: "center", marginLeft: "auto", marginRight: "auto" }}
      >
        <Container>
          <h4>Deleting Property...</h4>
          <p></p>
          <p></p>
          <Spinner animation="border" variant="primary" />
          <p></p>
          <p></p>
        </Container>
      </div>
    );
  };

  var RenderRedirect = () => {
    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: path,
            state: { currentProperty: current },
            propertyProps: current.title,
            currentProperty: current,
            search: `${current.id}`,
          }}
        />
      );
    } else {
      return <></>;
    }
  };

  var Property = (props) => {
    var onDelete = (current) => {
      var that = this;

      console.log("property deleted");
      setDeleteProperty(true);
      setDelIndex(current.id);
      var options = {
        method: "POST",
        url: "http://localhost:8000/properties/delete",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ property_id: current.id }),
      };
      var body = JSON.stringify({ property_id: current.id });

      axios
        .post("/api/properties/delete", body, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.data) {
            setDeleted(res.data.msg);
            if (res.data.success) {
              var tmp = propertyList;
              setProperties(tmp.filter((el) => el !== current));
              console.log("Property Deleted");
            }
            setDeleteProperty(false);
          } else {
            setDeleted({ success: false, msg: "Failed to delete property" });
            setDeleteProperty(false);
          }
        })
        .catch((error) => {
          setDeleted({ success: false, msg: error.toString() });
          setDeleteProperty(false);
        });
    };

    var background;
    if (props.index % 2 == 0) {
      background = "rgba(209, 209, 218, 0.2)";
    } else {
      background = "#fff";
    }
    return (
      <tr className="proptables-row" style={{ backgroundColor: background }}>
        <td className="proptable-d">
          <div className="header-elem" style={{ margin: "20px" }}>
            {props.index}
          </div>
        </td>
        <td className="proptable-d" style={{ cursor: "pointer" }}>
          <img
            onClick={() => handleRedirect(props.current, "/property")}
            className="header-elem"
            style={{ width: "150px" }}
            src={props.current.images[0]}
          />
        </td>
        <td className="proptable-d">
          <div className="header-elem">{props.current.title}</div>
        </td>
        <td className="proptable-d">
          <div className="header-elem">{props.current.details.status}</div>
        </td>
        <td className="proptable-d">
          <div className="header-elem">
            <Button
              onClick={() => handleRedirect(props.current, "/edit-property")}
              style={{ borderRadius: "100%" }}
              variant="info"
              size="sm"
            >
              <BsPencilSquare />
            </Button>
          </div>
        </td>
        <td className="proptable-d">
          <div className="header-elem">
            {deleteProperty && delIndex == props.current.id ? (
              <div>
                {" "}
                Deleting...{" "}
                <Spinner
                  animation="border"
                  variant="danger"
                  style={{ width: "20px", height: "20px" }}
                />
              </div>
            ) : (
              <Button
                onClick={() => onDelete(props.current)}
                style={{ borderRadius: "100%" }}
                variant="danger"
                size="sm"
              >
                <BsTrash />
              </Button>
            )}
          </div>
        </td>
      </tr>
    );
  };
  return (
    <div className="submit-property-page property">
      <Container fluid className="jumbo">
        <Row>
          <Col sm={5}>
            <div className="jumbo-content-left">
              <h2>MY PROPERTIES</h2>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="my-properties-body">
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <h5 className="loading">Loading</h5>
            <div style={{ height: "20px" }} />
            {/* <Spinner variant='primary' animation='border' /> */}
            {/* <Invest/> */}
            <Far />
            <div style={{ height: "100px" }} />
          </div>
        ) : (
          <div>
            {!propertyList || (propertyList.length < 1 && !loading) ? (
              <Container>
                <div
                  style={{
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Container>
                    <h4>NO PROPERTIES</h4>
                    <p></p>
                    <p></p>
                    <p>No properties found</p> <p></p>
                    <p></p>
                  </Container>
                </div>
              </Container>
            ) : (
              <table className="proptables">
                <tbody>
                  <tr className="property-table-header">
                    <th className=".proptable-d">
                      <div className="header-elem">#</div>
                    </th>
                    <th className=".proptable-d">
                      <div className="header-elem">Image</div>
                    </th>
                    <th className=".proptable-d">
                      <div className="header-elem">Title</div>
                    </th>
                    <th className=".proptable-d">
                      <div className="header-elem">Status</div>
                    </th>
                    <th className=".proptable-d">
                      <div className="header-elem">Edit</div>
                    </th>
                    <th className=".proptable-d">
                      <div className="header-elem">Delete</div>
                    </th>
                  </tr>
                  {propertyList.map((currentProperty, key) => {
                    return (
                      <Property index={key + 1} current={currentProperty} />
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        )}
        <RenderRedirect />
        <div></div>
        <br />
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    user: state.user.user,
    authSuccess: state.auth.authSuccess,
    jwtToken: state.auth.jwtToken,
  };
};

export default connect(mapStateToProps)(MyProperties);
