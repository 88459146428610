import React from 'react';
import { InputGroup, Form, FormControl, Button, Row, Col, Card, Container } from 'react-bootstrap';
import { FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom';

class Careers extends React.Component {
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  career = () => {
    return (
      <div className="careers-page">
        <Container fluid className="jumbo">
          <Row>
            <Col sm={5}>
              <div className="jumbo-content-left">
                <h2>Careers</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="careers-body">
          <br />
          <Row>
            <Col sm={4}>
              <div className="careers-image" />
            </Col>
            <Col>
              <Card className="careers-text">
                <p1>
                  Do you love the excitement and changeable atmosphere of real
                  estate? Do you have a license and consider yourself to be an
                  expert to your markets? Our growing real estate investment
                  platform is hiring Investment Consultants to join our team and
                  add value to our business, while growing your business as
                  well.
                  <br />
                  <br />
                  <div
                    className="careers-link"
                    onClick={() => {
                      this.openInNewTab(
                        "https://www.investfar.com/assets/DescriptionPDF/InvestorConsultanJobDescription.pdf"
                      );
                    }}
                  >
                    <FiDownload /> Investor Consultant Job Description
                  </div>
                </p1>
              </Card>
            </Col>
          </Row>
          <br />
        </div>
      </div>
    );
  };

  render() {
    require("./Careers.css");
    return <this.career />;
  }
}

export default Careers