import React from 'react'
import './ContractorDirectory.css'

export default function ContractorDirctory(props) {

    return (
        <div>
            <p>Contructor Directory Page</p>
        </div>
    )

}