import React from "react";
import {
  InputGroup,
  Form,
  FormControl,
  Button,
  Row,
  Col,
  Card,
  Container,
} from "react-bootstrap";
import { FiDownload } from "react-icons/fi";
import { Link } from "react-router-dom";

class InvestFar extends React.Component {
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  invest = () => {
    return (
      <div className="investfar-page">
        <Container fluid className="jumbo">
          <Row>
            <Col sm={5}>
              <div className="jumbo-content-left">
                <h2>INVESTFAR</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="investfar-body">
          <div className="investfar-text">
            <h4>INVESTFAR</h4>
            <h6>Welcome to the future of real estate investing.</h6>
            <p1>
              Location, location. location! It's an old saying in real estate,
              but very true. The most important part of investing in out of
              market properties is picking location. Rising prices are forcing
              many real estate investors to consider in¬vesting in out-of-state
              properties. Out-of-state investing is not easy, but can be done
              and can produce great results. You must build a great team. buy
              below market value and keep a close eye on your properties.
              <br />
              <br />
              It will be virtually impossible to buy. repair and manage homes
              from anot¬her state by yourself. It will save you money and
              heartache in the long run to use local professionals who know the
              market. You will need a great team to handle buying and renting a
              long-distance property for you.
              <br />
              <br />
              With the resources on InvestFar you can build that team on one
              platform without ever leaving your home.
              <br />
              <br />
            </p1>
            <br />
          </div>
        </div>
      </div>
    );
  };

  render() {
    require("./InvestFar.css");
    return <this.invest />;
  }
}

export default InvestFar;
