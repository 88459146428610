import React from 'react';
import { InputGroup, Form, FormControl, Button, Row, Col, Card, Container } from 'react-bootstrap';
import { FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom';

class Privacy extends React.Component {
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  privacy = () => {
    return (
      <div className="privacy-page">
        <Container fluid className="jumbo">
          <Row>
            <Col sm={5}>
              <div className="jumbo-content-left">
                <h2>PRIVACY POLICY & TERMS</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="privacy-body">
          <h4>PRIVACY POLICY, TERMS & RESTRICTIONS ON USE OF MATERIALS</h4>
          <span>Privacy Policy & Terms </span>
          <br />
          <span>InvestFar, Inc. – Terms of Service Agreement</span>
          <div className="card-container">
            <Card className="card">
              <Card.Header className="privacy-card-header">
                1. Acceptance of Terms and Conditions
              </Card.Header>
              <p className="card-text">
                This Terms of Service Agreement governs your use of the website
                located at https://reinvestfar.com/ ( the “Site”), the InvestFar
                mobile app (the “Application”), and your use of any other
                applications, widgets, tools, features, content (including
                without limitation videos and images), services and options
                available through the Site or Application or otherwise provided
                by InvestFar, Inc. (“InvestFar, Inc,” the “Company,” “we,” “us,”
                or “our”) in connection with the Site or Application
                (collectively with the Site and Application, the “Services”). By
                accessing, browsing or using the Services or any pages of the
                Services, you are indicating that you have read and acknowledge
                and agree to be bound by this Terms of Service Agreement and any
                additional terms and conditions applicable to certain areas of
                the Services and posted by us in those areas of the Services,
                which are incorporated herein by reference (collectively, “Terms
                and Conditions”), and the Company’s Privacy Statement located
                at: https://reinvestfar.com/class="main-header" id="reqest"/ If
                you do not agree to every provision of these Terms and
                Conditions and the Company’s Privacy Statement, please do not
                access, browse or use the Services.
                <br />
                <br />
                These Terms and Conditions may be revised at any time for any
                reason, and we may provide you notice of these changes by any
                reasonable means, including by posting the revised version of
                the Terms and Conditions on the Services. You can determine when
                we last updated these Terms and Conditions by referring to the
                “Last Updated” legend at the bottom of these Terms and
                Conditions. By accessing, browsing or using the Services
                following the posting of changes to these Terms and Conditions,
                you accept such changes. You agree to use the Services for
                lawful purposes only in a manner consistent with any and all
                applicable rules, laws and regulations. Any use of the Services
                in a manner inconsistent with these Terms and Conditions is
                deemed unauthorized access and may subject the user to civil or
                criminal penalties. We strongly recommend that you periodically
                visit this page of the Services to review these Terms and
                Conditions.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                2. Important Information about the Services and InvestFar, Inc
              </Card.Header>
              <p className="card-text">
                <b>Scope and Limitation of Services</b>
                <br />
                The Services provide a technology platform that functions as a
                marketplace and database with a forum to allow real estate
                enthusiasts to connect and communicate with one another. The
                Services allow users to establish contacts, make inquiries and
                receive information and education about real estate and related
                business opportunities.
                <br />
                <br />
                Access to the Services is provided to users who agree to pay
                applicable subscription fees. InvestFar, Inc is not involved in
                negotiating, and is not compensated for any real estate or other
                transactions that arise out of interactions between users of the
                Services.
                <br />
                <br />
                We are not a licensed real estate agent, a registered
                broker-dealer, or a licensed investment adviser, and we do not
                provide any kind of advice, including but not limited to tax
                advice, legal advice, accounting advice, or investment advice.
                <br />
                <br />
                The Services are not provided to, and may not be used by, any
                user in a jurisdiction where they would be illegal, or where
                InvestFar, Inc is not authorized to provide Services.
                <br />
                <br />
                <b>Use and Restrictions Related to the Services</b>
                <br />
                You should not use the Services to execute any documents or
                instruments, or to make any offers or proposals that you intend
                to be legally binding. The functionality of the Services might
                not be technically adequate to support legally binding
                contracts.
                <br />
                <br />
                If you post content that may be viewed as an offer of real
                estate or securities or as investment advice or recommendation,
                you do so at your own risk and with an implied representation
                and warranty to us that you are complying with all applicable
                laws. If we incur any damages as a result of your failure to
                comply with all laws, including but not limited to reasonable
                attorneys’ fees, you will be responsible.
                <br />
                <br />
                Conversely, if other users post content that may be viewed as an
                offer of real estate or securities or as investment advice or
                recommendation, you acknowledge that the offer, advice, or
                recommendation comes solely from the other user, and not from
                InvestFar, Inc. We do not vouch for or verify any information
                posted by users and you proceed at your own risk.
                <br />
                <br />
                We are not responsible for User Content (as defined in Section
                15) or for any losses you incur dealing with other users.
                <br />
                <br />
                <b>Investment Warnings</b>
                <br />
                We may from time to time list investment opportunities on the
                Site, including but not limited to links from other websites.
                However, we do not make recommendations regarding the
                appropriateness of particular opportunity for any particular
                investor. We are not investment advisers. Users of the Site must
                make their own investment decisions, either alone or with their
                personal advisors. You acknowledge that you are not relying on
                us in making investment decisions.
                <br />
                <br />
                Real estate is notoriously speculative and unpredictable. For
                example, many very experienced, very informed people lost money
                when the real estate market declined in 2007 through 2008. When
                the real estate market is healthy, as it was from 2003 through
                2006, it appears that it will be healthy forever, but time after
                time history has shown that the real estate market goes down
                without warning, sometimes resulting in devastating losses. You
                should invest in real estate in general, and in opportunities
                listed at the Site in particular, only if you can afford to lose
                your investment and are willing to live with the ups and downs
                of the real estate industry.
                <br />
                <br />
                <b>Full Earnings Disclosure</b>
                <br />
                InvestFar, Inc (The Company) does not guarantee income or
                success, and do not represent an indication of future success or
                earnings. The Company declares all information provided directly
                by Company is true and accurate, and any claims made of actual
                earnings or examples of actual results can be verified upon
                request.
                <br />
                <br />
                The earnings, revenue and profit results that a customer will
                generally achieve in circumstances similar to those depicted in
                the endorsements and testimonials on this site depend on many
                factors and conditions, including but not limited to, work
                ethic, learning ability, use of the products and services,
                business experience, daily practices, business opportunities,
                business connections, market conditions, availability of
                financing, and local competition, to name a few. Because of
                impediments due to any one or more of the foregoing and other
                factors, it is generally expected that earnings, revenues or
                profits may not be achieved with the use of any products or
                services advertised on this site in circumstances similar to
                those referenced in any endorsement or testimonial.
                <br />
                <br />
                InvestFar, Inc platform is designed to connect real estate
                investors and provide services from users of the platform. Any
                income or product claims made by members or third parties have
                not been verified by InvestFar, Inc. InvestFar, Inc allows third
                parties to advertise on the site, these are not company
                endorsements.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                3. Subscription Pricing and Payments
              </Card.Header>
              <p className="card-text">
                <b>Free or Paid Trials</b>
                <br />
                If you receive a free or paid trial subscription for any of the
                Services, you will receive free or paid access to that Service
                for the duration of the free or paid trial period. If you cancel
                the subscription during the free or paid trial period you will
                not be billed further. If you do not cancel during the free or
                paid trial period, you will be billed at the end of the free or
                paid trial period for the first period of the subscription that
                you purchased at prevailing rates, which will commence at the
                end of the free or paid trial period. Access to free or paid
                trials may be limited to a certain number of free or paid trials
                for each user during a given period. To avoid any charges, you
                must cancel BEFORE the end of the trial period; for more
                information on canceling subscriptions, see Section 4.
                <br />
                <br />
                <b>Pricing and Changes</b>
                <br />
                InvestFar, Inc displays the pricing for the Services on the Site
                or Application, as applicable.
                <br />
                <br />
                Users of the Services can purchase several types of subscription
                plans (each, and “Account”) allowing you to access those
                portions of the Services described under each plan. You will be
                charged a subscription fee for the use of these Services.
                InvestFar, Inc reserves the right to change the fees at any
                time, upon notice to you. The increase will apply to the next
                payment due from you after the notice, provided that you have
                been given at least 21 days’ prior notice before the charge is
                made. If you are given less than 21 days’ prior notice, the
                price increase will not apply until the payment after the next
                payment due.
                <br />
                <br />
                Users of the Services can purchase several types of subscription
                plans (each, and “Account”) allowing you to access those
                portions of the Services described under each plan. You will be
                charged a subscription fee for the use of these Services.
                InvestFar, Inc reserves the right to change the fees at any
                time, upon notice to you. The increase will apply to the next
                payment due from you after the notice, provided that you have
                been given at least 21 days’ prior notice before the charge is
                made. If you are given less than 21 days’ prior notice, the
                price increase will not apply until the payment after the next
                payment due.
                <br />
                <br />
                By registering for an Account, you agree to pay InvestFar, Inc
                the fees for the services applicable to the account level
                chosen. For any upgrade or downgrade in subscription plan level,
                the credit card that you provided will automatically be charged
                the new rate immediately.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                4. Service Cancellations
              </Card.Header>
              <p className="card-text">
                Auto-recurring subscriptions to Services (whether monthly,
                quarterly, annually or any other billing cycle) renew
                automatically, unless you cancel the subscription BEFORE the end
                of the applicable billing cycle. The auto-subscription holder is
                responsible for tracking and managing their respective
                auto-subscription billing and renewal cycles. InvestFar, Inc may
                or may not send advance billing notifications on
                auto-subscriptions.
                <br />
                <br />
                If you purchase an auto-recurring periodic subscription to a
                Service, you may cancel that subscription in accordance with
                this Section 4 at any time BEFORE the end of the applicable
                billing cycle, and the cancellation will apply to the next
                period. For example, if you purchase a monthly subscription, you
                may cancel that subscription at any time during any month of the
                subscription, and the subscription will be cancelled as of the
                following month. You WILL NOT receive a refund or a partial
                refund for the current billing period subscription, whether on a
                monthly, quarterly, annual or any other billing cycle.
                <br />
                <br />
                The only acceptable method for you to cancel your
                subscription(s) is to contact InvestFar, Inc at 855-7337-4338
                and/or send a written email request to info@reinvestfar.com Once
                you have provided sufficient information, InvestFar, Inc will
                provide a follow up email or phone call to you confirming any
                cancellations. Cancellations are only effective upon receipt of
                a cancellation email confirmation from InvestFar, Inc.
                <br />
                <br />
                Voicemail messages, text messages or emails sent to any phone
                numbers and/or email addresses other than the contact
                information above is not an acceptable mechanism for submitting
                cancellation requests, and will not result in cancellation of
                any Service or the respective charges associated with that
                Service.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                5. Refunds
              </Card.Header>
              <p className="card-text">
                All recurring subscription fees for all apps, services and
                content are paid in advance and are non-refundable, except as
                otherwise provided in this Section. There will be no refunds or
                credits for partial months of service, upgrade/downgrade
                refunds, refunds for accounts that have had access to particular
                services restricted, refunds for accounts that have had upgrades
                cancelled for any reason, including violations of these Terms
                and Conditions, or refunds for months of unused service.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                6. Service Deactivations
              </Card.Header>
              <p className="card-text">
                We reserve the right to deactivate your access to the Services
                for your failure to pay applicable fees, including payment
                plans, or for violations of these Terms and Conditions. If you
                provide us with a credit card that expires during the term of
                these Terms and Conditions, we reserve the right to charge any
                renewal card issued to you as a replacement. You agree to
                promptly pay InvestFar, Inc the event of any refusal of your
                credit card issuer to pay any amount to InvestFar, Inc for any
                reason. You agree to pay all costs of collection, including
                attorneys’ fees and costs, on any outstanding balance. In the
                event you fail to pay any amount when due, InvestFar, Inc may
                immediately suspend or terminate your access to any or all of
                the Services.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                7. Payment Processing and Taxes
              </Card.Header>
              <p className="card-text">
                InvestFar, Inc may make available to you various
                payment-processing methods to facilitate the purchase of the
                Services. You must abide by any relevant terms and conditions
                and other legal agreement, whether with InvestFar, Inc or a
                third party, that governs your use of a given payment processing
                method. InvestFar, Inc may add or remove payment-processing
                methods at its sole discretion and without notice to you. You
                agree to pay for any Services that you order and that InvestFar,
                Inc may charge your credit card or other form of payment that
                you indicated for any Services ordered, along with any
                additional amounts. You agree that you are solely responsible
                for all fees associated with purchases you make through the
                Services.
                <br />
                <br />
                You are responsible for any Taxes, and must pay InvestFar, Inc
                for Services without any reduction in payment for any Taxes. If
                InvestFar, Inc is obligated to collect or pay Taxes, the Taxes
                will be invoiced to you, unless you provide InvestFar, Inc with
                a valid tax exemption certificate authorized by the appropriate
                taxing authority. If you are required by law to withhold any
                Taxes from your payments to InvestFar, Inc, you must provide
                InvestFar, Inc with an official tax receipt or other appropriate
                documentation to support such payments. “Taxes” means any
                duties, custom fees, or taxes (other than InvestFar, Inc income
                tax) associated with the sale or license of Services, including
                any related penalties or interest.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                8. Warranty Disclaimer
              </Card.Header>
              <p className="card-text">
                You agree that your use of the site, services and any content,
                products, services, or features made in conjunction with or
                through the services shall be at your sole risk and unless
                otherwise expressly stated by connected investors, are provided
                “as is” and “as available”. to the fullest extent permitted by
                law, investfar, inc, its affiliated entities disclaim all
                warranties, express or implied, in connection with the service
                and your use thereof, including, but not limited to, implied
                warranties of merchantability, fitness for a particular purpose,
                non-infringement of proprietary rights, compliance with united
                states federal and state securities or blue sky laws or
                regulations, securities exchange or self-regulatory
                organization’s rules or regulations and equivalent laws or
                regulations in foreign jurisdictions, correctness, accuracy and
                reliability.
                <br />
                <br />
                Investfar, inc does not warrant, endorse, guarantee, or assume
                responsibility for any product or service advertised or offered
                by a third party through the service or any hyperlinked services
                and investfar, inc will not be a party to or in any way be
                responsible for monitoring any transaction you pursue with other
                users or through the use of the services.
                <br />
                <br />
                Investfar, inc and its affiliates have no special relationship
                with or fiduciary duty to you and without limiting the
                foregoing, make no representation or warranty with respect to
                the merits and risks of any transaction you pursue with other
                users or through the use of the services. as with the purchase
                of a product or service through any medium or in any
                environment, you should use your best judgment and exercise
                caution where appropriate.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                9. Services Content
              </Card.Header>
              <p className="card-text">
                The Services and all material contained therein, all text,
                graphics, Applications, Images (as defined in Section 10) and
                other works, the design and coding, all computer programs used
                and licensed in connection with the Services, the business
                process, procedures, methods and techniques used in or in
                connection with the Services, the look and feel of the Services,
                and all data and reports generated by the Services
                (collectively, the “Services Content”) are owned by us or a
                third party. These materials are protected under copyright,
                trademark and other laws. You may not copy, download, transmit,
                modify, distribute or republish the Services or any portion of
                the Services, including without limitation any of the Services
                Content without the prior written consent of Company. You may
                not sell, publicly display, create derivative works of, reverse
                engineer, assign, sub-license, transfer or otherwise exploit the
                Services or any Services Content. Use of any Services Content is
                prohibited without the prior written permission of Company.
                <br />
                <br />
                As long as you comply with these Terms and Conditions, Company
                grants you a personal, non-exclusive, non-transferable,
                non-sublicenseable right to access and make personal,
                non-commercial use of the Services in compliance with these
                Terms and Conditions. You shall not, and shall not permit anyone
                else to, directly or indirectly: (i) remove or alter proprietary
                notices or labels on or in the Services or Services Content;
                (ii) engage in any activity that interferes with or disrupts the
                Services or Services Content; (iii) engage in any fraudulent
                activity or activity that facilitates fraud; or (iv) otherwise
                act in violation of these Terms and Conditions. All rights not
                expressly granted herein are reserved.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                10. InvestFar, Inc hereby grants you a personal, non-exclusive,
                non-transferable, non-sublicenseable, worldwide right to use,
                modify, and reproduce Images in the following ways, subject to
                the limitations set forth herein:
              </Card.Header>
              <p>
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                11. On websites;
              </Card.Header>
              <p>
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                12. In print media, digital media, product packaging and
                software including magazines, newspapers, books (including
                print-on-demand books), e-books, advertising collateral,
                letterhead, business cards, product labels, CD and DVD cover
                art, applications (including mobile “apps”), and opt-in e-mail
                marketing;
              </Card.Header>
              <p>
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                13. Incorporated into film, video, multimedia presentations, or
                advertising for broadcast, public performance, or streaming;
              </Card.Header>
              <p>
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                14. Incorporated into print or digital material intended for
                public display, including trade show booths or point of sale
                materials;
              </Card.Header>
              <p>
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                15. For decorative purposes solely for your own personal,
                non-commercial use, not for resale, download or distribution, or
                any other commercial use; and
              </Card.Header>
              <p>
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                16. If your desired use is not set forth above, please see
                contact us at info@reinvestfar.com.
              </Card.Header>
              <p className="card-text">
                In the event that you create a derivative work based on or
                incorporating one or more Images, all rights in and to such
                Images shall continue to be owned by InvestFar, Inc or its
                Contributor(s), subject to your rights to use such Image(s)
                pursuant to the terms and limitations set forth herein. All
                other rights in the Images are expressly reserved by InvestFar,
                Inc for itself and its Contributors.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                16. If your desired use is not set forth above, please see
                contact us at info@reinvestfar.com.
              </Card.Header>
              <p className="card-text">
                In the event that you create a derivative work based on or
                incorporating one or more Images, all rights in and to such
                Images shall continue to be owned by InvestFar, Inc or its
                Contributor(s), subject to your rights to use such Image(s)
                pursuant to the terms and limitations set forth herein. All
                other rights in the Images are expressly reserved by InvestFar,
                Inc for itself and its Contributors.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                PART II, Restrictions
              </Card.Header>
              <p className="card-text">
                <b>YOU MAY NOT:</b>
                <br />
                1. Use an Image or Video other than as specified in PART I;{" "}
                <br />
                <br />
                2. Make images or video available on a digital asset management
                system, shared drive or the like for the purposes of sharing or
                transferring such images; <br />
                <br />
                3. Resell, redistribute, provide access to, share or transfer
                any Image or video except as specifically provided herein.
                <br />
                <br />
                4. Publicly display an Image or Video: (i) as a standalone file
                in any digital format on the Internet; or (ii) in any digital
                format without imposing technical or written restrictions
                intended to prevent the use of such Images by third parties.
                Unless expressly permitted by these Terms and Conditions, no
                other person may use Images or Video which you have licensed
                hereunder. <br />
                <br />
                5. Produce or otherwise create for resale or distribution,
                printed reproductions of any Image or video as wallpaper or wall
                art, on billboards, or on canvas, paper, plastic or any other
                medium, unless such reproduction is expressly permitted in PART
                I. <br />
                <br />
                6. Use or display any Image or video on websites or in
                connection with any service designed to sell or induce sales of
                user-customized on-demand products of any kind using or
                incorporating Image(s), including, by way of example only,
                postcards, mugs, t-shirts, posters, wallpaper, artwork and other
                items. <br />
                <br />
                7. Use an Image or Video together with pornographic, defamatory,
                or otherwise unlawful or immoral content. <br />
                <br />
                8. Use an Image or Video in a manner that infringes upon any
                third party’s trademark or other intellectual property, or would
                give rise to a claim of deceptive advertising or unfair
                competition. <br />
                <br />
                9. Use an Image or Video in a way that depicts any person
                therein in a way that a reasonable person might find offensive –
                this includes, but is not limited to the use of Images: a) in
                pornography, “adult videos” or the like; b) in ads for tobacco
                products; c) in ads or promotional materials for adult
                entertainment clubs or similar venues, or for escort, dating or
                similar services; d) in connection with political endorsements;
                e) in advertisements or promotional materials for pharmaceutical
                or healthcare, herbal or medical products or services,
                including, but not limited to dietary supplements, digestive
                aids, herbal supplements, personal hygiene or birth control
                products; and f) uses that are defamatory, or contain otherwise
                unlawful, offensive or immoral content. You may not use an Image
                containing the likeness of a person if such use implies that the
                model engages in any immoral or illegal activity or suffers from
                a physical or mental infirmity, ailment or condition. <br />
                <br />
                10. Use any Image or Video on a social media platform or other
                third party website that claims to acquire rights in the Image
                or Video contrary to these Terms and Conditions as a result of
                such use. Upon InvestFar, Inc request, you shall immediately
                remove any Images or Video from such platform or website. If you
                require such use, please contact us at info@reinvestfar.com.{" "}
                <br />
                <br />
                11. illustration only and not by way of limitation, displaying
                Images or Video in any format (including thumbnails) for
                download on a website, offering InvestFar, Inc Images or Video
                for sale, or including InvestFar, Inc Images or Video in
                templates of any nature, including web templates, document
                templates, projects or otherwise for distribution and/or sale to
                third parties. <br />
                <br />
                12. Use Images or Video as the basis for any individual or
                collection of physical or digital merchandise or promotional
                items, which you offer for sale, including the creation of a
                digital or print greeting card line based on InvestFar, Inc
                Images or Video. <br />
                <br />
                13. Use any Image or Video (in whole or in part) as a trademark,
                service mark, logo, or other indication of origin, or as part
                thereof, or to otherwise endorse or imply the endorsement of any
                goods and/or services. <br />
                <br />
                14. Falsely represent, expressly or by way of reasonable
                implication, that any Image or video was created by you or a
                person other than the copyright holder(s) of that Image or
                Video. <br />
                <br />
                15. Stockpile or otherwise store downloaded Images or Video that
                are not used within twelve (12) months of the date on which you
                first downloaded such Image. If you fail to use an Image or
                Video within twelve (12) months from the date of your first
                download of that Image or Video, you lose all rights to use that
                Image or Video. <br />
                <br />
                16. Use automated programs, applets, bots or the like to access
                the InvestFar, Inc websites or any content thereon for any
                purpose, including, by way of example only, downloading Images
                or Video, indexing or caching the content on the InvestFar, Inc
                websites. <br />
                <br />
                “Non-transferable” as used herein means that except as
                specifically provided in these Terms and Conditions, you may not
                sell, rent, load, give, sublicense, or otherwise transfer to
                anyone, an Image or Video or the right to use an Image or Video.
                You agree to take all commercially reasonable steps to prevent
                third parties from duplicating any Image or Video. If you become
                aware of any unauthorized duplication of any InvestFar, Inc
                Image(s) or Video, please notify us via email at
                info@reinvestfar.com.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                PART III, Refunds
              </Card.Header>
              <p className="card-text">
                Any sales of InvestFar, Inc VALUATION REPORTS licenses, images,
                video and related content ARE FINAL, AND NON-REFUNDABLE. Such
                material is immediately available for consumption upon purchase,
                so NO REFUNDS WILL BE GRANTED FOR THESE PURCHASES. InvestFar,
                Inc shall be under no obligation to issue refunds under this
                Section 10 under any circumstances, unless required by law. In
                the event that InvestFar, Inc determines that you are entitled
                to a refund of all or part of the fees you paid, such refund
                shall only be made to the credit card account originally used by
                you to purchase your product. If your product was paid for by
                check, your refund will be made by check.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                11. Company Trademarks
              </Card.Header>
              <p className="card-text">
                All product and service names appearing in a typeface different
                from that of the surrounding text or with a trademark symbol,
                including without limitation the following:
                <br />
                <br />
                InvestFar, Inc Logos
                <br />
                <br />
                are registered and unregistered trademarks and service marks
                owned by Company or its subsidiaries or affiliates or a third
                party. The absence of a name, trademark or logo in this list
                does not constitute a waiver of any and all intellectual
                property rights that Company has established in any of its
                goods, services, names or logos. These trademarks and all other
                trademarks, service marks, logos, and company names (each a
                “Mark”) used in connection with the Services are the property of
                Company or third parties and shall remain the property of
                Company and such third parties. Nothing contained in the
                Services shall be construed as granting, by implication or
                otherwise, any license or right to use any such Mark without the
                prior written permission of Company or such third party that may
                own such Mark. Your misuse of any such Mark, or any other
                Services Content, is strictly prohibited.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                12. Registration
              </Card.Header>
              <p className="card-text">
                Each registration is for a single user only. You may not create
                an account for another individual or entity or allow another
                individual or entity to use your account on your behalf. In
                consideration of your use of the Services, you agree to provide
                accurate, current and complete information about yourself or
                your company as requested on the Services registration form and
                to maintain and promptly update the information (including, in
                particular, your e-mail address) you provide from time to time
                as necessary to keep the information true, accurate, current and
                complete. By accepting these Terms and Conditions, you represent
                and warrant that you are 18 years of age or older and that, if
                you have accepted these Terms and Conditions on behalf of any
                business (such as a corporation, partnership, limited liability
                company or other organization) or other entity, you represent
                and warrant that you have legal authority to do so.
                <br />
                <br />
                Any changes to your registration information should be made on
                the Services. If you provide information that is untrue,
                inaccurate, not current or incomplete, we may suspend or
                terminate your account and refuse any and all current or future
                use of the Services.
                <br />
                <br />
                In connection with registering for the Services, you may receive
                a password for your use of the Services. You are responsible for
                keeping your password confidential. You will be responsible for
                all uses and activity that occurs through your password or
                account. You will close the browser window for the Services at
                the end of each use, and you will immediately notify us of any
                unauthorized use of your password. We cannot and will not be
                liable for any loss or damage arising from your failure to
                comply with this Section 12.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                13. Dealing with Other Users
              </Card.Header>
              <p className="card-text">
                The Services may allow you to correspond or otherwise interact
                with other users who may provide you with investments,
                investment information, advice, services and/or events, or
                provide you with other information about various companies or
                organizations. You acknowledge that such other users are not
                affiliated with or controlled InvestFar, Inc or its affiliates,
                and InvestFar, Inc cannot influence the investments,
                information, advice or services provided by them. Your
                correspondence or interaction with other users is solely between
                you and such other users. You agree that InvestFar, Inc and its
                affiliates, officers, directors, partners, agents, and employees
                will not be responsible or liable for any loss or damage of any
                sort incurred as the result of any interactions between you and
                other users. Furthermore, if you provide any investments,
                information, advice or services to other users through the
                Services, you acknowledge that you are not affiliated with, or
                controlled or influenced in any way by, InvestFar, Inc or its
                affiliates. InvestFar, Inc is under no obligation to become
                involved in disputes between users of the Services or arising
                from use of the Services, or between users of the Services and
                any third party. In the event of a dispute, you release Company
                and its Affiliated Entities and successors and assigns from
                claims, damages and demands of every kind, known or unknown,
                suspected or unsuspected, disclosed or undisclosed, arising out
                of or in any way related to such disputes and such services.
                <br />
                <br />
                If you are a California resident, to the extent allowed by
                applicable law, you waive California Civil Code Section 1542,
                which states: “A general release does not extend to claims which
                the creditor does not know or suspect to exist in his or her
                favor at the time of executing the release, which if known by
                him or her must have materially affected his or her settlement
                with the debtor.”
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                14. Legal Requirements
              </Card.Header>
              <p className="card-text">
                Where Company has a good faith belief that such action is
                necessary to comply with a judicial proceeding, court order,
                warrant, administrative order, civil investigative demand,
                subpoena, or other valid process, Company may disclose IP
                addresses, personal information, and any contents of the
                Services where it is legally compelled to do so. Please see the
                Company’s Privacy Statement located at:
                https://reinvestfar.com/privacy-policy-terms/ for additional
                information relating to the privacy and security of information
                collected hereunder.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                15. Your Use of the Services
              </Card.Header>
              <p className="card-text">
                You are solely responsible and liable for all data, information
                and other materials (“User Content”) that you submit, upload,
                post, e-mail or otherwise transmit (“Transmit”) in connection
                with the Services. In addition, we have no control over, and
                shall have no liability for, any damages resulting from the use
                (including without limitation republication) or misuse by any
                third party of information made public through the Services. If
                you choose to submit to us, or otherwise make any personal
                information or other information publicly available, you do so
                at your own risk and company shall have no responsibility or
                liability therefor.
                <br />
                <br />
                You agree that you will not, and will not permit anyone else to,
                directly or indirectly: (a) Transmit any User Content that is
                unlawful, harmful, threatening, abusive, hateful, obscene,
                harassing, tortious, defamatory, libelous, slanderous,
                pornographic, profane, vulgar, offensive, lewd, dishonest or
                misleading, invasive of another’s privacy or racially,
                ethnically or otherwise objectionable; (b) use the Services to
                harm minors in any way or to stalk, threaten, or otherwise
                violate the rights of others, including without limitation
                others’ privacy rights or rights of publicity, or harvest or
                collect personal information, including e-mail addresses, about
                other users of the Services; (c) Transmit any User Content: (i)
                that you do not have the right to Transmit, under any law or
                contractual or fiduciary relationships, including, without
                limitation, any inside information or proprietary or
                confidential information; (ii) that infringes any patent,
                copyright, trademark or other intellectual property right or
                misappropriates any trade secret or right of privacy of any
                third-party; (iii) that constitutes unsolicited or unauthorized
                advertising or promotional materials, “spam,” “chain letters,”
                or pyramid schemes; or (iv) that contains any software routine,
                code, instruction or virus that is designed to disable, delete,
                modify, damage or erase software, hardware or data; (d) forge
                headers or otherwise manipulate identifiers in order to disguise
                any User Content Transmitted through the Services; (e) interfere
                with the Services or servers or networks used in connection with
                the Services; (f) interfere with the ability of others to use
                the Services; (g) copy, download, transmit, modify, reproduce,
                sell, resell, sub-license, distribute, publish create derivative
                works of, reverse engineer, assign, transfer or exploit for any
                commercial purposes, any portion of the Services, the Services
                Content or any User Content contained therein; (h) conduct your
                business using the Services in a way that is unfair, unlawful,
                or constitutes a deceptive business practice; (i) use any robot,
                spider, or other automatic device to monitor or copy portions of
                the Services or the Services Content without Company’s prior
                written permission; (j) include in any thirty party website any
                hypertext link to any page or location within the Services
                without Company’s prior written permission; (k) mirror or
                display the Services or any portion thereof in frames without
                Company’s prior written permission; (l) download, reproduce,
                duplicate, copy or otherwise exploit any portion of the Services
                for the purpose of sale, resale or making other commercial use
                thereof; or (m) impersonate any person or entity, including, but
                not limited to, other users of the Services, falsely state or
                otherwise misrepresent your affiliation with any person or
                entity, or express or imply that we endorse any statement you
                make.
                <br />
                <br />
                You acknowledge and agree that Company may disclose or use any
                User Content that you Transmit for purposes that include, but
                are not limited to: (a) enforcing these Terms and Conditions;
                (b) complying with any laws, regulations or rules of any
                federal, state or local government or agency; (c) responding to
                claims that any User Content violates the rights of third
                parties; or (d) protecting the rights or property of Company,
                its customers or the public. With respect to User Content that
                you Transmit to the Services, you grant Company a perpetual,
                worldwide, royalty-free, non-exclusive transferable,
                sub-licensable (through multiple tiers) license to use, copy,
                excerpt, reproduce, display, aggregate, de-identify, publish,
                modify, distribute and create derivative works of such User
                Content in any form or media, and to allow others to do so,
                however, Company will only share personally identifiable
                information that you provide in accordance with Company’s
                privacy statement at:
                https://reinvestfar.com/privacy-policy-terms/As between the
                parties, we own all right, title, and interest in and to all
                intellectual property rights in all materials, products or
                services developed by us, or on behalf of us by third parties,
                based on or including as a component thereof any such
                information as described above, and all generalized knowledge,
                skill, know-how and expertise relating to such information.
                <br />
                <br />
                Company does not and cannot verify the identity of all
                registered users of the Services, review all User Content posted
                to the Services, or created by users accessing the Services, and
                is not in any manner responsible for the content of any User
                Content. You acknowledge that by providing you with the ability
                to view and distribute user-generated content on the Services,
                Company is merely acting as a passive conduit for such
                distribution and is not undertaking any obligation or liability
                relating to any User Content or activities on the Services.
                However, Company reserves the right to block, remove, move or
                edit any of the submissions in its sole discretion.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                16. Linked Sites
              </Card.Header>
              <p className="card-text">
                Company has not reviewed all of the websites linked to the
                Services and is not responsible for the content of any
                third-party pages or any other websites linked to the Services.
                Nothing in the Services, including, without limitation, any
                links to other websites, should be construed as an endorsement
                of any products, services or information of any other persons or
                companies by Company. Your choice to link to any other website
                is at your own risk, and you agree to comply with all terms and
                conditions relating to such websites. Company reserves the right
                not to link, or to remove the link, to a particular website at
                any time. Any links to third party websites are provided as a
                convenience to you and are neither owned nor operated by
                Company. Company has no control over these linked websites and
                makes no representations or warranties with respect to these
                linked websites.Your viewing and use of any third party websites
                is at your sole discretion and risk.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                17. Special Admonitions for International Use
              </Card.Header>
              <p className="card-text">
                All matters relating to the Services are governed exclusively by
                the laws of the State of California in the United States of
                America and not the jurisdiction in which you are located. If
                you are located outside of the United States of America and you
                contact us, please be advised that any information you provide
                to us will be transferred to the United States of America and
                that by submitting information, you explicitly authorize such
                transfer. Accessing the Services is prohibited from territories
                where the Site, Services or any content thereon is illegal. If
                you access the Services from other locations, you do so at your
                own initiative and are responsible for compliance with local
                laws.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                18. Indemnification
              </Card.Header>
              <p className="card-text">
                You agree to defend, indemnify and hold harmless Company and its
                Affiliated Entities from and against any claims, liabilities,
                costs or damages, including reasonable attorneys’ fees and
                paralegal fees through final appeals, made by any third party,
                relating to or arising from (i) your use of the Services, (ii)
                your interactions with other users of the Services, (iii) any
                User Content that you Transmit to or through the Services,
                including without limitation any intellectual property, (iv) any
                violation of these Terms and Conditions by you, (v) your
                non-compliance with any federal, state, or local law or
                regulation, or (vi) any other act or omission by you, including
                your violation of any rights of another, arising from your use
                of the Services.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                19. Termination
              </Card.Header>
              <p className="card-text">
                You may terminate your use of the Application at any time for
                any reason by uninstalling the application from your mobile
                device. You may delete your account by contacting us at:
                info@reinvestfar.com
                <br />
                <br />
                You acknowledge and agree that Company may terminate your
                account or access to use of the Services for any reason,
                including, without limitation, your violation of these Terms and
                Conditions. You agree that Company may terminate your access to
                and use of the Services without prior notice and without any
                liability to you or any third party. You acknowledge and agree
                that Company may modify, limit, suspend or discontinue the
                Services or any part of the Services at any time, without notice
                or liability to you. Company may also, from time to time,
                establish general rules and policies regarding use of the
                Services. Company will post such rules and policies on the
                Services, and you agree that your compliance with such rules and
                policies shall be a condition of your use or continued use of
                the Services. Company shall have no liability or responsibility
                with respect to any lost Services Content, User Content, or
                other data, such as the deletion of or failure to store Services
                Content, User Content, or other data. All provisions of these
                Terms and Conditions that by their nature should survive
                termination of your right to access and use the Services shall
                survive (including, but not limited to, all limitations on
                liability, releases, and indemnification obligations,
                disclaimers of warranties, and intellectual property protections
                and licenses). Company reserves the right to, but has no
                obligation to, store or keep copies of any Services Content,
                User Content, or other information, unless otherwise required by
                law or court order.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                20. Disclaimer of Consequential Damages
              </Card.Header>
              <p className="card-text">
                To the fullest extent permitted by law, in no event shall
                company, its affiliated entities be liable for any damages
                whatsoever, including without limitation, incidental and
                consequential damages, lost profits, or damages resulting from
                lost data, lost employment opportunity or business interruption,
                resulting from the use or inability to use the site or any
                related products or services or the site content, whether based
                on warranty, contract, tort (including without limitation
                negligence) or any other legal theory, and whether or not the
                company is advised of the possibility of such damages.
                <br />
                <br />
                You specifically agree that company is not responsible or liable
                to you or anyone else for unlawful, harassing, defamatory,
                abusive, threatening, harmful, vulgar, obscene, sexually
                explicit, or otherwise objectionable conduct or speech of any
                other party on or through the site, or for any infringement or
                violation of your rights by any other party, including, but not
                limited to, intellectual property rights, rights of publicity,
                or rights of privacy. your sole and exclusive remedy for
                dissatisfaction with the site is to stop using the site.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                21. Limitation of Liability
              </Card.Header>
              <p className="card-text">
                The maximum liability of company and the affiliated entities for
                all damages, losses and causes of action, whether in contract,
                tort (including without limitation negligence) or otherwise will
                be the total amount, if any, paid by you to company to access
                and use the services. If you live in a jurisdiction whose laws
                prevent you from taking full responsibility and risk for your
                use of the site in accordance with these terms and conditions,
                company’s liability is limited to the greatest extent allowed by
                the law of that jurisdiction.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                22. Governing Law and Jurisdiction
              </Card.Header>
              <p className="card-text">
                Company operates the Services from its offices in California in
                the, U.S.A. These Terms and Conditions and the transactions they
                contemplate, including without limitation their interpretation,
                construction, performance and enforcement shall be governed by
                the laws of the State of California, U.S.A. without reference to
                conflict or choice of law provisions, as applicable to contracts
                made and performed entirely within such State. The International
                Convention on the Sale of Goods, and other international
                treaties that are not mandatory with respect to contracts made
                and performed entirely in the United States, shall not apply.
                The exclusive forum for the resolution of any dispute relating
                to these Terms and Conditions shall be the state and federal
                courts in Los Angeles County, California, U.S.A., and you agree
                to personal jurisdiction of such courts over you with regard to
                any dispute relating to these Terms and Conditions and agree to
                service of process on you by e-mail to the address you have
                submitted on the Services, if any, and by any other means
                permitted by law.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                23. Arbitration
              </Card.Header>
              <p className="card-text">
                You and company agree that any dispute, claim or controversy
                arising out of or relating in any way to this website terms of
                use or related products or services shall be finally decided by
                binding arbitration under the rules of the american arbitration
                association governing consumer disputes. Arbitration uses a
                single, neutral arbitrator to decide a dispute (instead of a
                judge or jury); arbitration allows for more limited discovery
                than in a court case; and the arbitration process and result is
                subject to very limited review by courts. In arbitration you
                have the right, at your expense, to be represented by an
                attorney of your choosing. Arbitrators can award the same
                damages and relief under this eula that a court can award under
                this eula. You and company agree that any in-person arbitral
                hearing would occur in the same county and state as your billing
                address. Company further agrees that your filing fee for
                arbitration will be capped at the amount set by the american
                arbitration association. You agree that, by agreeing to this
                eula, the u.S. Federal arbitration act governs the
                interpretation and enforcement of this provision, and that you
                and company are each waiving the right to a trial by jury or to
                participate in a class action. This arbitration provision shall
                survive termination of this eula and the termination of your use
                of the mobile mail. Regardless of the forum, you and company
                agree that each may bring claims against the other only in your
                or its individual capacity and not as a plaintiff or class
                member in any purported class or representative proceeding.
                Further, unless both you and company agree otherwise, the
                arbitrator may not consolidate more than one person’s claims
                with your claims, and may not otherwise preside over any form of
                a representative or class proceeding. If this specific provision
                is found to be unenforceable, then the entirety of this
                arbitration provision shall be null and void. The arbitrator may
                award declaratory or injunctive relief only in favor of the
                individual party seeking relief and only to the extent necessary
                to provide relief warranted by that party’s individual claim.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                24. Notice
              </Card.Header>
              <p className="card-text">
                All notices, demands, or consents given by you under these Terms
                and Conditions will be in writing and will be deemed given when
                delivered to Company at the following contact: InvestFar, Inc
                11611 San Vicente Blvd Los Angeles, Ca 90049.
                <br />
                <br />
                Any notices to you may be made via either e-mail or postal mail
                to the address in Company’s records or via positing on the
                Services.
                <br />
                <br />
                Please report any violations of these Terms and Conditions to
                Company at the contact listed above.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                25. Miscellaneous
              </Card.Header>
              <p className="card-text">
                You may not assign, sublicense or otherwise transfer any of your
                rights under these Terms and Conditions. If any provision of
                these Terms and Conditions is found to be invalid by any court
                having competent jurisdiction, the invalidity of that provision
                shall not affect the validity of the remaining provisions of
                this Agreement, which shall remain in full force and effect.
                Headings in these Terms and Conditions are for convenience only
                and shall have no legal meaning or effect. No action arising
                under this Agreement may be brought at any time more than twelve
                (12) months after the facts occurred upon which the cause of
                action arose. These Terms and Conditions, and not the conduct
                between us or any trade practice, shall control the
                interpretation of these Terms and Conditions between the parties
                respecting the Services. Company’s failure to enforce a
                particular provision of these Terms and Conditions does not mean
                that Company waives the right to enforce it in the future;
                Company shall waive such a right only in writing.
                <br />
                <br />
                These Terms and Conditions and all other written agreements duly
                executed between you and Company in connection with your use of
                the Services constitute the entire agreement between you and
                Company with respect to the subject matter hereof and supersede
                any prior or contemporaneous proposals, discussions,
                communications, or oral agreements heretofore made.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                26. Getting Information from Lenders
              </Card.Header>
              <p className="card-text">
                The Services might allow you to get information from, and/or be
                contacted by, lenders. If so, we will have you complete a
                separate form on the Site for that purpose. You understand that
                (a) we are not a lender ourselves, nor a mortgage broker; (b)
                the information you submit will not be treated as a loan
                application for purposes of the Real Estate Settlement
                Procedures Act; (c) by submitting information you are giving us
                permission to forward the information to lenders, and for
                lenders to contact you; (d) lenders may provide us with
                information concerning your loan; (e) we are independent of any
                lender who contacts you; (f) we do not guaranty that the lenders
                who contact you will offer the best terms available, or even
                competitive terms; (g) we do not guaranty that the information
                provided to you by lenders will be accurate; (h) should you
                submit an application to a lender, you proceed at your own risk,
                and will not hold us responsible for any claims you might have
                against the lender, including but not limited claims for fraud;
                and (i) we might also provide the information you give us,
                including your name and email address, to other service
                providers (not just to lenders), such as title companies or
                insurance agencies.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                27. Procedure for Making Claims of Copyright Infringement
              </Card.Header>
              <p className="card-text">
                We expect users of the Services to respect the intellectual
                property rights of others. If you believe in good faith that any
                of the content on the Services infringes your copyright, please
                provide our copyright agent the written information specified
                below.
                <br />
                <br />
                A. An electronic or physical signature of the person authorized
                to act on behalf of the owner of the copyright interest;
                <br />
                <br />
                B. A description of the copyrighted work that you claim has been
                infringed;
                <br />
                <br />
                C. A description of where the material that you claim is
                infringing is located on the Services;
                <br />
                <br />
                D. Your address, telephone number and email address;
                <br />
                <br />
                E. A statement by you that you have a good faith belief that the
                disputed use is not authorized by the copyright owner, its agent
                or the law; and
                <br />
                <br />
                F. A statement by you, made under penalty of perjury, that the
                information in the notice is accurate and that you are the
                copyright owner, or are authorized to act on behalf of the
                owner, of an exclusive right that is allegedly infringed.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                PART IIII, Membership
              </Card.Header>
              <p className="card-text">
                1.1. Your InvestFar membership will continue month-to-month and
                automatically renew until terminated. To use the InvestFar
                service you must have Internet access and a InvestFar ready
                device and provide us with one or more Payment Methods. "Payment
                Method" means a current, valid, accepted method of payment, as
                may be updated from time to time and which may include payment
                through your account with a third party. You must cancel your
                membership before it renews each month in order to avoid billing
                of the next month's membership fees to your Payment Method (see
                "Cancellation" below).
                <br />
                <br />
                1.2. We may offer a number of membership plans, including
                special promotional plans or memberships offered by third
                parties in conjunction with the provision of their own products
                and services. We are not responsible for the products and
                services provided by such third parties. Some membership plans
                may have differing conditions and limitations, which will be
                disclosed at your sign-up or in other communications made
                available to you. You can find specific details regarding your
                membership by visiting our website.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                Free Trials
              </Card.Header>
              <p className="card-text">
                2.1. Your InvestFar membership may start with a free trial. The
                free trial period of your membership lasts for one month, or as
                otherwise specified during sign-up and is intended to allow new
                and certain former members to try the service.
                <br />
                <br />
                2.2. Free trial eligibility is determined by InvestFar at its
                sole discretion and we may limit eligibility or duration to
                prevent free trial abuse. We reserve the right to revoke the
                free trial and put your account on hold in the event that we
                determine that you are not eligible. We may use information such
                as device ID, method of payment or an account email address used
                with an existing or recent membership to determine eligibility.
                For combinations with other offers, restrictions may apply.
                <br />
                <br />
                2.3. We will charge your Payment Method for your monthly
                membership fee at the end of the free trial period and your
                membership will automatically renew monthly unless you cancel
                your membership prior to the end of the free trial period. To
                view the monthly membership price and end date of your free
                trial period, visit our website and click the "profile" on the
                "Account" page.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                Free Trials
              </Card.Header>
              <p className="card-text">
                <b>3.1. Billing Cycle. </b>
                The membership fee for the InvestFar service any other charges
                you may incur in connection with your use of the service, such
                as taxes and possible transaction fees, will be charged on a
                monthly basis to your Payment Method on the calendar day
                corresponding to the commencement of the paying portion of your
                membership until your membership is cancelled. Membership fees
                are fully earned upon payment. In some cases your payment date
                may change, for example if your Payment Method has not
                successfully settled or if your paid membership began on a day
                not contained in a given month. We may authorize your Payment
                Method in anticipation of membership or service-related charges
                through various methods, including authorizing it up to
                approximately one month of service as soon as you register. In
                some instances, your available balance or credit limit may be
                reduced to reflect the authorization during your free trial
                period.
                <br />
                <br />
                <b>3.2. Payment Methods. </b>
                To use the InvestFar service you must provide one or more
                Payment Methods. You can update your Payment Methods by going to
                the "profile" page. We may also update your Payment Methods
                using information provided by the payment service providers.
                Following any update, you authorize us to continue to charge the
                applicable Payment Method(s). You authorize us to charge any
                Payment Method associated to your account in case your primary
                Payment Method is declined or no longer available to us for
                payment of your subscription fee. You remain responsible for any
                uncollected amounts. If a payment is not successfully settled,
                due to expiration, insufficient funds, or otherwise, and you do
                not cancel your account, we may suspend your access to the
                service until we have successfully charged a valid Payment
                Method. For some Payment Methods, the issuer may charge you
                certain fees, such as foreign transaction fees or other fees
                relating to the processing of your Payment Method. Check with
                your Payment Method service provider for details.
                <br />
                <br />
                <b>3.3. Cancellation. </b>
                You can cancel your InvestFar membership at any time, and you
                will continue to have access to the service through the end of
                your monthly billing period. We do not provide refunds or
                credits for any partial-month membership periods. To cancel, go
                to the "Profile" page on our website or app and follow the
                instructions for cancellation. If you cancel your membership,
                your account will automatically close at the end of your current
                billing period. To see when your account will close, click
                "subscription details" on the "profile" page.
                <br />
                <br />
                <b>3.4. Changes to the Price and Subscription Plans. </b>
                We reserve the right to change our subscription plans or adjust
                pricing for our service or any components thereof in any manner
                and at any time as we may determine in our sole and absolute
                discretion. Except as otherwise expressly provided for in these
                Terms of Use, any price changes or changes to your subscription
                plan will take effect following notice to you.
                <br />
                <br />
                <b>No Refunds. </b>
                Payments are nonrefundable and there are no refunds or credits
                for partially used periods. Following any cancellation, however,
                you will continue to have access to the service through the end
                of your current billing period. At any time, and for any reason,
                we may provide a refund, discount, or other consideration to
                some or all of our members ("credits"). The amount and form of
                such credits, and the decision to provide them, are at our sole
                and absolute discretion. The provision of credits in one
                instance does not entitle you to credits in the future for
                similar instances, nor does it obligate us to provide credits in
                the future, under any circumstance.
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                Free Trials
              </Card.Header>
              <p className="card-text">
                4.1. You must be 18 years of age, or the age of majority in your
                province, territory or country, to become a member of the
                InvestFar service. Individuals under the age of 18, or
                applicable age of majority, may utilize the service only with
                the involvement of a parent or legal guardian, under such
                person's account and otherwise subject to these Terms of Use.
                <br />
                <br />
                <b>Passwords and Account Access. </b>
                The member who created the InvestFar account and whose Payment
                Method is charged (the "Account Owner") has access and control
                over the account and the InvestFar ready devices that are used
                to access our service and is responsible for any activity that
                occurs through the account. To maintain control over the account
                and prevent anyone from accessing the account (which could
                include information on viewing history for the account), the
                Account Owner should maintain control over the InvestFar ready
                devices that are used to access the service and not reveal the
                password or details of the Payment Method associated to the
                account to anyone. You are responsible for updating and
                maintaining the accuracy of the information you provide to us
                relating to your account. We can terminate your account or place
                your account on hold in order to protect you, InvestFar or our
                partners from identity theft or other fraudulent activity.
                InvestFar is not obligated to credit or discount a membership
                for holds placed on the account by either a representative of
                InvestFar or by the automated processes of InvestFar.
                <br />
                <br />
                <b>Disclaimers of Warranties and Limitations on Liability </b>
                6.1. THE INVESTFAR SERVICE AND ALL CONTENT AND SOFTWARE
                ASSOCIATED THEREWITH, OR ANY OTHER FEATURES OR FUNCTIONALITIES
                ASSOCIATED WITH THE INVESTFAR SERVICE, ARE PROVIDED "AS IS" AND
                "AS AVAILABLE" WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND.
                INVESTFAR DOES NOT GUARANTEE, REPRESENT, OR WARRANT THAT YOUR
                USE OF THE INVESTFAR SERVICE WILL BE UNINTERRUPTED OR
                ERROR-FREE. INVESTFAR SPECIFICALLY DISCLAIMS LIABILITY FOR THE
                USE OF APPLICATIONS, INVESTFAR READY DEVICES, AND INVESTFAR
                SOFTWARE (INCLUDING THEIR CONTINUING COMPATIBILITY WITH OUR
                SERVICE).
                <br />
                <br />
                6.2. TO THE EXTENT PERMISSIBLE UNDER APPLICABLE LAWS, IN NO
                EVENT SHALL INVESTFAR, OR ITS SUBSIDIARIES OR ANY OF THEIR
                SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES OR LICENSORS BE
                LIABLE (JOINTLY OR SEVERALLY) TO YOU FOR PERSONAL INJURY OR ANY
                SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY
                KIND, OR ANY DAMAGES WHATSOEVER.
                <br />
                <br />
                6.3. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN
                WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR
                CERTAIN TYPES OF DAMAGES. THEREFORE, SOME OF THE ABOVE
                LIMITATIONS IN THIS SECTION MAY NOT APPLY TO YOU.
                <br />
                <br />
                6.4. NOTHING IN THESE TERMS OF USE SHALL AFFECT ANY NON-WAIVABLE
                STATUTORY RIGHTS THAT APPLY TO YOU
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                Arbitration Agreement
              </Card.Header>
              <p className="card-text">
                7.1. If you are a InvestFar member in the United States
                (including its possessions and territories), you and InvestFar
                agree that any dispute, claim or controversy arising out of or
                relating in any way to the service, these Terms of Use and this
                Arbitration Agreement, shall be determined by binding
                arbitration or in small claims court. Arbitration is more
                informal than a lawsuit in court. Arbitration uses a neutral
                arbitrator instead of a judge or jury, allows for more limited
                discovery than in court, and is subject to very limited review
                by courts. Arbitrators can award the same damages and relief
                that a court can award and nothing in this Arbitration Agreement
                shall be interpreted as limiting any non-waivable statutory
                rights. You agree that, by agreeing to these Terms of Use, the
                U.S. Federal Arbitration Act governs the interpretation and
                enforcement of this provision, and that you and InvestFar are
                each waiving the right to a trial by jury or to participate in a
                class action. This arbitration provision shall survive
                termination of this Agreement and the termination of your
                InvestFar membership.F
                <br />
                <br />
              </p>
            </Card>
            <Card className="card">
              <Card.Header className="privacy-card-header">
                Miscellaneous
              </Card.Header>
              <p className="card-text">
                <b>8.1. Governing Law. </b>
                These Terms of Use shall be governed by and construed in
                accordance with the laws of the state of Delaware, U.S.A.
                without regard to conflict of laws provisions. These terms will
                not limit any consumer protection rights that you may be
                entitled to under the mandatory laws of your state of residence.
                <br />
                <br />
                <b>8.2. Feedback. </b>
                InvestFar is free to use any comments, information, ideas,
                concepts, reviews, or techniques or any other material contained
                in any communication you may send to us ("Feedback"), including
                responses to questionnaires or through postings to the InvestFar
                service, including the InvestFar website and user interfaces,
                worldwide and in perpetuity without further compensation,
                acknowledgement or payment to you for any purpose whatsoever
                including, but not limited to, developing, manufacturing and
                marketing products and creating, modifying or improving the
                InvestFar service. In addition, you agree not to enforce any
                "moral rights" in and to the Feedback, to the extent permitted
                by applicable law.
                <br />
                <br />
                <b>8.3. Customer Support. </b>
                To find more information about our service and its features, or
                if you need assistance with your account, please visit our
                website. In certain instances, Customer Service may best be able
                to assist you by using a remote access support tool through
                which we have full access to your computer. If you do not want
                us to have this access, you should not consent to support
                through the remote access tool, and we will assist you through
                other means. In the event of any conflict between these Terms of
                Use and information provided by Customer Service or other
                portions of our website, these Terms of Use will control.
                <br />
                <br />
                <b>8.4. Survival. </b>
                If any provision or provisions of these Terms of Use shall be
                held to be invalid, illegal, or unenforceable, the validity,
                legality and enforceability of the remaining provisions shall
                remain in full force and effect.
                <br />
                <br />
                <b>8.5. Changes to Terms of Use and Assignment. </b>
                InvestFar may, from time to time, change these Terms of Use.
                Such revisions shall be effective immediately; provided however,
                for existing members, such revisions shall, unless otherwise
                stated, be effective 30 days after posting. We may assign our
                agreement with you to any affiliated company or to any entity
                that succeeds to all or substantially all of our business or
                assets related to the applicable InvestFar service.
                <br />
                <br />
                <b>8.6. Communication Preferences. </b>
                We will send you information relating to your account (e.g.
                payment authorizations, invoices, changes in password or Payment
                Method, confirmation messages, notices) in electronic form only,
                for example via emails to your email address provided during
                registration. You agree that any notices, agreements,
                disclosures or other communications that we send to you
                electronically will satisfy any legal communication
                requirements, including that such communications be in writing.
                <br />
                <br />
                Materials on this website and mobile app are copyrighted and all
                rights are reserved. Text, graphics, functionality, databases,
                HTML code, and other intellectual property are protected by US
                and International Copyright Laws, and may not be copied,
                reprinted, published, reengineered, translated, hosted, or
                otherwise distributed by any means without explicit permission.
                All of the trademarks on this site are trademarks of InvestFar,
                Inc. Legal owners and authors of InvestFar, Inc or of other
                material only used with their permission. We also use a Google
                Analytics Advertising remarketing feature to collect data for
                future advertising to help consumer understanding.
                <br />
                <br />
                We and third-party vendors use first-party cookies (such as the
                Google Analytics cookie) or other first-party identifiers, and
                third-party cookies (such as Google advertising cookies) or
                other third-party identifiers together to collect this data.
                <br />
                <br />
                Visitors can opt-out of the Google Analytics Advertising
                Features used, including through Ads Settings, Ad Settings for
                mobile apps, or any other available means
                <br />
                <br />
                By using this site, you signify your Assent and Agreement to
                these Terms of Use. If you do not agree to these Terms of Use,
                do not use the site.
                <br />
                <br />
                Company’s copyright agent for notice of claims of copyright
                infringement can be reached as follows:
                <br />
                <br />
                InvestFar, Inc 11611 San Vicente Blvd, Los Angeles, CA 90049
                <br />
                <br />
                855-737-4338, info@reinvestfar.com
                <br />
                <br />
                Last Updated: December 20, 2017
                <br />
                <br />
              </p>
            </Card>
          </div>
        </Container>
      </div>
    );
  };

  render() {
    require("./Privacy.css");
    return <this.privacy />;
  }
}

export default Privacy