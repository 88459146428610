import React from "react";
import { Redirect } from "react-router-dom";
import {
  Alert,
  InputGroup,
  Form,
  FormControl,
  Dropdown,
  DropdownButton,
  Button,
  Row,
  Col,
  Container,
  Card,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  TiSocialFacebookCircular,
  TiSocialLinkedinCircular,
} from "react-icons/ti";
import axios from "axios";
import { connect } from "react-redux";
import {
  createUser,
  passwordReset,
  resolveError,
  resolveField,
} from "../../store/actions/authActions";
import { displayError } from "../../store/actions/authActions";
import { loginUser } from "../../store/actions/authActions";
import jsonwebtoken from "jsonwebtoken";
import { User } from "../../Constructors/user";
import { Error, Primary, Success, Info, Warning } from "../../Alerts";
class ForgotPassword extends React.Component {
  state = {
    name: "",
    country: "",
    city: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
    businessAddress: "",
    licensed: "",
    otherLicenses: "",
    brokerAddress: "",
    websiteUrl: "",
    website: "",
    email: "",
    password: "",
  };

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    var errors = this.fieldCheck();
    if (errors.length > 0) {
      this.props.displayError(errors);
      window.scrollTo(0, 0);
    } else {
      this.props.passwordReset(this.state.email);
    }
  };

  handleBack = (e) => {
    e.preventDefault();
    this.props.history.push("/login");
    this.props.resolveField();
  };

  fieldCheck = () => {
    var errorList = [];
    if (this.state.email == null || this.state.email == "") {
      errorList.push("Email Field is Required;");
    }
    return errorList;
  };

  forgotPassword = () => {
    const { authError, passwordResetError, passwordResetSuccess } = this.props;
    const { authSuccess } = this.props;

    if (passwordResetError) {
      console.log("EROROROR");
      if (typeof passwordResetError == "object") {
        passwordResetError.map((error) => {
          Error({ title: "Error", message: error });
        });
        this.props.resolveError();
      } else {
        Error({ title: "Error", message: passwordResetError });
        this.props.resolveError();
      }
    }

    // if (auth.uid) {
    //     return <Redirect to="/login" />
    // }
    return (
      <div className="login-page">
        <Container fluid className="jumbo">
          <Row>
            <Col sm={5}>
              <div className="jumbo-content-left">
                <h2>Forgot Password</h2>
              </div>
            </Col>
          </Row>
        </Container>

        <div className="account">
          <Row>
            <h6>
              Please enter the email address that is assocaited with your User
              Account.
            </h6>
          </Row>
          <Container className="sign">
            <Row>
              <Col sm={6}>
                <Card className="signin-card">
                  {passwordResetSuccess == false ? (
                    <Form onSubmit={this.handleSubmit.bind(this)}>
                      <br />
                      <br />
                      <h4>Password Reset</h4>
                      <Form.Group as={Col} className="email">
                        <Col>
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            name="email"
                            onChange={this.handleChange.bind(this)}
                            className="form-control"
                            type="email"
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Col>
                          <Button
                            className="btn-blue sign-button"
                            type="submit"
                          >
                            Send Email
                          </Button>
                        </Col>
                      </Form.Group>
                    </Form>
                  ) : (
                    <Form onSubmit={this.handleBack.bind(this)}>
                      <br />
                      <br />
                      <h4>Password Reset Success!</h4>
                      <Form.Group as={Col} className="email">
                        <Col>
                          <h5>
                            Please check your email for the password reset link.
                          </h5>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Col>
                          <Button className="sign-button" type="submit">
                            Back
                          </Button>
                        </Col>
                      </Form.Group>
                    </Form>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  };

  render() {
    require("./Login.css");
    return <this.forgotPassword />;
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    authSuccess: state.auth.authSuccess,
    jwtToken: state.auth.jwtToken,
    auth: state.firebase.auth,
    passwordResetSuccess: state.auth.passwordResetSuccess,
    passwordResetError: state.auth.passwordResetError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (user) => dispatch(createUser(user)),
    loginUser: (user) => dispatch(loginUser(user)),
    displayError: (error) => dispatch(displayError(error)),
    resolveError: () => dispatch(resolveError()),
    passwordReset: (email) => dispatch(passwordReset(email)),
    resolveField: (field) => dispatch(resolveField(field)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
