import React, { useState } from "react";
import {
  Col,
  Row,
  Card,
  Container,
  Form,
  Button,
  Collapse,
  Modal,
  Spinner,
  Badge,
} from "react-bootstrap";
import { CSVReader } from "react-papaparse";
import "./SubmitProperty.css";
// import ReactFirebaseFileUpload from '../ImageUpload/ImageUpload'
import {
  PropertyLocation,
  Description,
  Details,
  Property,
} from "../../Constructors/property";
import { Multiselect } from "multiselect-react-dropdown";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import { User, CURRENT } from "../../Constructors/user";
import { connect } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import { BsTrash } from "react-icons/bs";
import { uploadImages } from "../../store/actions/userActions";
import AutoComplete from "../../AutoComplete/AutoComplete";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import Far from "../../Spinners/Far";
import axios from "axios";
import Geocode from "react-geocode";
import { add } from "@amcharts/amcharts4/.internal/core/utils/Array";

Geocode.setApiKey("AIzaSyBwR2ZAc0g_ZGLLwZRlh-Ct3qE4muTOZbY");

const { v4: uuidv4 } = require("uuid");
require("./SubmitProperty.css");

const buttonRef = React.createRef();

class SubmitProperty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      target: null,
      amenities: [
        { value: "Air Condition", id: 1 },
        { value: "Carpet", id: 2 },
        { value: "Fire Place", id: 3 },
        { value: "Garage", id: 4 },
        { value: "High Ceilings", id: 5 },
        { value: "Laundry", id: 6 },
        { value: "Pool", id: 7 },
        { value: "Wood Floor", id: 8 },
        { value: "Year Build", id: 9 },
      ],
      searchType: ["Buy", "Rent", "Sell", "Sold"],
      propertyCategory: [
        "Condo",
        "Plot",
        "Single Family",
        "Multi Family",
        "Apartment",
        "Duplex",
        "Office",
        "Townhouse",
      ],
      floorPlan: false,
      bulkProperties: [],
      bulkUpload: false,
      propertyForm: {},
      openSubmit: false,
      bulkDone: false,
      loading: false,
      uploadResponse: null,
      fileName: null,
      features: [],
      featuresObj: {},
      images: null,
      imagesData: null,
      currentUser: null,
      location: null,
    };

    this.onSelect = this.onSelect.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  componentDidMount = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const { authSuccess } = this.props;
    const { profile } = this.props;
    this.setState({ currentUser: profile });
    console.log("User profile", profile);
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={this.state.target} />;
    }
  };

  handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  handleOnFileLoad = (data, fileInfo) => {
    console.log("---------------------------");
    this.setState({ fileName: fileInfo.name });
    console.log("---------------------------");
    var headers = data.shift();
    var allProperties = [];
    data.map((entry, key) => {
      var propertyStructure = {};
      entry.data.map((value, id) => {
        propertyStructure[`${headers.data[id]}`] = value;
        if (id == entry.data.length - 1) {
          allProperties.push(propertyStructure);
        }
      });
    });

    this.setState({ bulkProperties: allProperties, bulkUpload: true });
    console.log("All Properties", allProperties);

    if (allProperties.length == data.length - 1) {
      console.log("All Properties", allProperties);
      this.setState({ bulkProperties: allProperties, bulkUpload: true });
    }
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOnRemoveFile = (data) => {
    console.log(data);
  };

  handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  handleFieldChange = (field, value) => {
    var propertyEntry = this.state.propertyForm;

    if (field == "keywords") {
      var words = value.split(",").map((keyword) => keyword.trim());
      var keywords = {};
      words.map((word, id) => {
        keywords[`${word}`] = true;
        if (id == words.length - 1) {
          console.log("Keywords", keywords);
          propertyEntry[field] = keywords;
        }
      });
    } else {
      propertyEntry[field] = value;
    }
    this.setState({ propertyForm: propertyEntry });
  };

  onSelect(selectedList, selectedItem) {
    this.setState({ features: selectedList });
    console.log(selectedList);
    var featuresObject = {};
    selectedList.map((item, key) => {
      featuresObject[`${item.value}`] = true;
      if (key == selectedList.length - 1)
        this.setState({ featuresObj: featuresObject });
    });
  }

  onRemove(selectedList, removedItem) {
    this.setState({ features: selectedList });
    console.log(selectedList);
    var featuresObject = {};
    selectedList.map((item, key) => {
      featuresObject[`${item.value}`] = true;
      if (key == selectedList.length - 1)
        this.setState({ featuresObj: featuresObject });
    });
  }

  imageHandler = (e) => {
    var allImages = this.state.images ? this.state.images : [];
    var allImagesData = this.state.imagesData ? this.state.imagesData : [];
    if (e.target.files) {
      for (var i = 0; i < e.target.files.length; i++) {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.readyState === 2) {
            allImages.push(reader.result);
            this.setState({ images: allImages });
          }
        };
        reader.readAsDataURL(e.target.files[i]);
        allImagesData.push(e.target.files[i]);
        this.setState({ imagesData: allImagesData });
        console.log(allImagesData);
        console.log(allImages);
      }
    }
  };

  removeImage = (idx) => {
    var allImages = this.state.images;
    var allImagesData = this.state.imagesData;
    allImages.splice(idx, 1);
    allImagesData.splice(idx, 1);
    this.setState({ images: allImages, imagesData: allImagesData });
  };

  submitImages = (e, cb) => {
    e.preventDefault();
    this.props.uploadImages(
      this.state.imagesData,
      "property_images",
      (response) => {
        cb(response);
      }
    );
  };

  handleSubmit = (e) => {
    window.scrollTo(0, 0);
    const { currentUser } = this.state;
    var user = currentUser;

    this.setState({ openSubmit: true });

    const {
      propertyType,
      offerType,
      user_description,
      bed,
      bath,
      parking,
      size,
      year,
      price,
      fees,
    } = this.state.propertyForm;
    const amenities = {
      data: this.state.features,
      obj: this.state.featuresObj,
    };
    const { value, living, reason } = this.state.propertyForm;
    const { address, city, state, zip, country } = this.state.propertyForm;
    const { title, keywords, valuation, owner } = this.state.propertyForm;
    const timestamp = new Date().toISOString();

    var description = new Description(
      propertyType,
      offerType,
      bed,
      bath,
      parking,
      size,
      year,
      price,
      timestamp,
      amenities,
      fees,
      user_description
    );
    var details = new Details(value, living, reason);
    var location = this.state.location
      ? this.state.location
      : new PropertyLocation(address, city, state, zip, country);

    console.log("Location", location);

    // if (this.state.imagesData && this.state.imagesData.length >= 1) {
    //     this.submitImages(e, (images) => {
    //         console.log('Images from submitImages handler', images.length)
    //         if (images.length >= 1) {
    //             var property = new Property(title, location, description, details, keywords, 'hi',
    //                 {
    //                     name: user.full_name, id: user.user_id, avator_url: user.avatar_url,
    //                     email: user.email, phone: user.phone_number ? user.phone_number : "+ 1-855-737-4338"
    //                 }, uuidv4(), { total: 0, average: null }, images) //uuidv4()
    //             console.log('Property', property)
    //             var that = this
    //             var request = require('request');
    //             var body = JSON.stringify({ "property": property })
    //             axios.post(`/api/properties/submit`, body, { headers: { 'content-type': 'application/json' } }).then(res => {
    //                 if (res.data) {
    //                     that.setState({
    //                         redirect: true,
    //                         target: '/my-properties'
    //                     });
    //                     console.log(res.data);
    //                     that.setState({ openSubmit: false, uploadResponse: res.data.msg });
    //                 } else {
    //                     console.log('Submit Error');
    //                 }
    //             }).catch(error => {
    //                 console.log("Error: ", error);
    //             });
    //         } else {
    //             alert('Something went wrong')
    //         }

    //     })
    // } else {

    //     var property = new Property(title, location, description, details, keywords, 'hi',
    //         {
    //             name: user.full_name, id: user.user_id, avator_url: user.avatar_url,
    //             email: user.email,
    //             phone: user.phone_number ? user.phone_number : "+ 1-855-737-4338"
    //         }, uuidv4(), { total: 0, average: null }, [])
    //     console.log('Property', property)
    //     var that = this
    //     var request = require('request');
    //     var body = JSON.stringify({ "property": property })

    //     axios.post(`/api/properties/submit`, body, { headers: { 'content-type': 'application/json' } }).then(res => {
    //         if (res.data) {
    //             that.setState({
    //                 redirect: true,
    //                 target: '/my-properties'
    //             });
    //             console.log('submit property', res.data);
    //             that.setState({ openSubmit: false, uploadResponse: res.data.msg });
    //         } else {
    //             console.log('Submit Error');
    //         }
    //     }).catch(error => {
    //         console.log("Error: ", error);
    //     });

    // }
  };

  // ADDRESS: "200 S College St"
  // CITY: "Boston"
  // HOA Fees: "0"
  // Home: "80000"
  // Keyword: "Pre-foreclosure "
  // LATITUDE: "42.2881"
  // LONGITUDE: "-71.0532"
  // Live: "Live"
  // "Parking ": "3"
  // Price: "57000"
  // Selling: "Avoiding foreclosure"
  // YEAR: "1960"
  // Zipcode: "2122"
  // bathRooms: "1"
  // bedRooms: "2"
  // description: "This is demo property description"
  // feature: "0"
  // propertyCategory: "1"
  // propertySize: "715"
  // searchType: "1"
  // title: "Notice of Default "
  // userID: "10001"

  handleBulkSubmit = () => {
    var properties = [];
    // this.setState({ uploadResponse: null, loading: true })
    console.log(this.state.bulkProperties);
    const { currentUser } = this.state;
    var user = currentUser;
    this.state.bulkProperties.map((property, index) => {
      if (Object.keys(property).length > 2) {
        const price =
          !property.Price || isNaN(property.Price) ? 0 : property.Price;
        const value =
          !property.Home || isNaN(property.Home) ? 0 : property.Home;
        const living = !property.Live ? "unkown" : property.Live;
        const reason = property.Selling
          ? property.Selling
          : "NO REASON PROVIDED";
        const address = property.ADDRESS;
        const city = property.CITY;
        const zip = property.Zipcode;
        const state = null;
        const country = "United States";
        const title = property.title;
        const keywords = [property.Keyword];
        const bed = property.bedRooms;
        const bath = property.bedRooms;
        const parking = property["Parking "];
        const size = property.propertySize;
        const year = property.Zipcode;
        var cat = Number(property.propertyCategory);
        var search = Number(property.searchType);
        const propertyType = this.state.propertyCategory[cat];
        const offerType = this.state.searchType[search];
        const owner = {
          name: user.full_name,
          id: user.user_id,
          avator_url: user.avatar_url,
          email: user.email,
          phone: user.phone_number ? user.phone_number : "+ 1-855-737-4338",
        };
        const fees = property["HOA Fees"];
        const user_description = properties.description;
        var data =
          property.feature && !isNaN(property.feature)
            ? [this.state.amenities[Number(property.feature)]]
            : [];
        const amenities = {
          data: data,
          obj:
            data.length > 0
              ? {
                  [`${data[0].value}`]: true,
                }
              : {},
        };

        if (!property.LATITUDE || !property.LONGITUDE) {
          property["LATITUDE"] = "48.8583701";
          property["LONGITUDE"] = "2.2922926";
        }

        Geocode.fromLatLng(property.LATITUDE, property.LONGITUDE).then(
          (response) => {
            const geometry = response.results[0].geometry;
            const address = response.results[0].formatted_address;
            let city, state, country;
            for (
              let i = 0;
              i < response.results[0].address_components.length;
              i++
            ) {
              for (
                let j = 0;
                j < response.results[0].address_components[i].types.length;
                j++
              ) {
                switch (response.results[0].address_components[i].types[j]) {
                  case "locality":
                    city = response.results[0].address_components[i].long_name;
                    break;
                  case "administrative_area_level_1":
                    state = response.results[0].address_components[i].long_name;
                    break;
                  case "country":
                    country =
                      response.results[0].address_components[i].long_name;
                    break;
                }
              }
            }
            var location = {
              address: address,
              full: address,
              city: city,
              state: state,
              zip: zip,
              country: country,
              geometry: geometry,
            };
            const timestamp = new Date().toISOString();
            var description = new Description(
              propertyType,
              offerType,
              bed,
              bath,
              parking,
              size,
              year,
              price,
              timestamp,
              amenities,
              fees,
              user_description
            );
            var details = new Details(value, living, reason);
            var propertyObj = new Property(
              title,
              location,
              description,
              details,
              keywords,
              "hi",
              owner,
              uuidv4(),
              { total: 0, average: null },
              []
            ); //uuidv4()
            properties.push(propertyObj);
            console.log(
              "Prop len",
              properties.length,
              "Bulk length",
              this.state.bulkProperties.length
            );
            if (properties.length == this.state.bulkProperties.length - 1) {
              var body = {
                properties: properties,
              };
              console.log("Body", body);
              axios
                .post(`/api/properties/submit/bulk`, body, {
                  headers: { "content-type": "application/json" },
                })
                .then((res) => {
                  if (res.data) {
                    console.log("Response", res.data);
                    // that.setState({
                    //     redirect: true,
                    //     target: '/my-properties'
                    // });
                    // console.log('submit property', res.data);
                    // that.setState({ openSubmit: false, uploadResponse: res.data.msg });
                  } else {
                    console.log("Submit Error");
                  }
                })
                .catch((error) => {
                  console.log("Error: ", error);
                });
            }
          },
          (error) => {
            if (properties.length == this.state.bulkProperties.length) {
              var body = {
                properties: properties,
              };
              console.log("Body", body);
              axios
                .post(`/api/properties/submit/bulk`, body, {
                  headers: { "content-type": "application/json" },
                })
                .then((res) => {
                  if (res.data) {
                    console.log("Response", res.data);
                    // that.setState({
                    //     redirect: true,
                    //     target: '/my-properties'
                    // });
                    // console.log('submit property', res.data);
                    // that.setState({ openSubmit: false, uploadResponse: res.data.msg });
                  } else {
                    console.log("Submit Error");
                  }
                })
                .catch((error) => {
                  console.log("Error: ", error);
                });
            }
            console.error(error);
          }
        );
      }
    });
  };

  onDelete = (idx) => {
    var bulk = this.state.bulkProperties;
    bulk.splice(idx, 1);
    this.setState({ bulkProperties: bulk });
  };
  PropertyCard = (props) => {
    return (
      <Row className="property-submit-card">
        <Col sm={4}>
          <Row>
            <Col xs lg="3">
              {/* <img className="rounded-circle" style={{ width: '30px' }} src={member.avatar_url} alt="activity-user" /> */}
              <div
                style={{ width: "30px", backgroundColor: "red" }}
                className="rounded-circle"
              ></div>
            </Col>
            <Col sm={6}>
              <h6 className="mb-1">{props.address}</h6>
            </Col>
            <h6 className="m-0 mt-1" style={{ "font-size": "10px" }}>
              {" "}
              {props.title}
            </h6>
            <Col></Col>
          </Row>
        </Col>
        <Col sm>
          <h6 className="text-muted">
            <i className="" />
            {props.sell ? <p>Sell</p> : <p>Rent</p>}{" "}
          </h6>
        </Col>
        <Col sm>
          <h6 className="text-muted">
            <i className="" />
            <Badge variant="info">
              {" "}
              {this.currencyFormat(props.price)}
            </Badge>{" "}
          </h6>
        </Col>
        <Col sm>
          <Button
            onClick={() => this.onDelete(props.idx)}
            style={{ borderRadius: "100%" }}
            variant="danger"
            size="sm"
          >
            <BsTrash />
          </Button>
        </Col>
      </Row>
    );
  };

  currencyFormat = (num) => {
    return (
      "$" +
      Number.parseFloat(num)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  };
  UploadProperty = () => {
    var setLocation = (loc) => {
      console.log("location", loc);
      this.setState({ location: loc });
    };

    const loc = this.state.location;
    return (
      <div>
        <div className="submit-property-card">
          <div className="submit-property-header">
            <Row>
              <Col>
                <Row>
                  <h6>UPLOAD PROPERTY</h6>
                </Row>
                <Row>
                  <div className="small-header-row-submit"></div>
                </Row>
              </Col>
              <Col>
                <div className="upload-button">
                  <CSVReader
                    ref={buttonRef}
                    onFileLoad={this.handleOnFileLoad}
                    onError={this.handleOnError}
                    noClick
                    noDrag
                    onRemoveFile={this.handleOnRemoveFile}
                  >
                    {({ file }) => (
                      <aside>
                        <Button
                          className="bulk-upload-button"
                          type="button"
                          onClick={this.handleOpenDialog}
                        >
                          Bulk Upload
                        </Button>
                      </aside>
                    )}
                  </CSVReader>
                  {this.state.fileName ? <p>{this.state.fileName}</p> : null}
                  <a
                    className="link-text"
                    href="https://www.investfar.com/assets/images/sample.csv"
                  >
                    Download Sample CSV
                  </a>
                </div>
              </Col>
            </Row>
            {this.state.bulkUpload ? (
              <div>
                <p>Submit These properties?</p>
                {this.state.bulkProperties.map((property, idx) => {
                  if (property.ADDRESS) {
                    return (
                      <>
                        <this.PropertyCard
                          idx={idx}
                          sell={true}
                          title={property.Keyword}
                          address={property.ADDRESS}
                          price={property.Price}
                        />
                        <p />
                      </>
                    );
                  }
                })}
                <div className="submit-button-container">
                  <Button
                    className="submit-button"
                    type="submit"
                    onClick={(e) => this.handleBulkSubmit(e)}
                  >
                    Submit
                  </Button>
                  {/* <this.SubmitModal /> */}
                </div>
              </div>
            ) : null}
            {this.state.bulkUpload ? null : (
              <Row>
                <Col sm={2}>
                  <input
                    multiple
                    className="custom-input"
                    type="file"
                    accept="image/*"
                    id="profileChange"
                    onChange={(e) => this.imageHandler(e)}
                  />
                </Col>
                <Col>
                  <Row>
                    {this.state.images == null
                      ? null
                      : this.state.images.map((image, idx) => {
                          return (
                            <Col sm={2} className="pic-holder">
                              <div className="pic">
                                <div
                                  className="remove"
                                  onClick={() => this.removeImage(idx)}
                                >
                                  <TiDeleteOutline className="icon" />
                                </div>
                                <img
                                  className="img-responsive"
                                  src={image}
                                  alt="img"
                                  title="img"
                                />
                              </div>
                            </Col>
                          );
                        })}
                  </Row>
                </Col>
              </Row>
            )}
          </div>
          {this.state.bulkUpload ? null : (
            <div className="submit-content">
              {/* <ReactFirebaseFileUpload /> */}
              <Form.Group>
                <Form.Label>Property Title *</Form.Label>
                <Form.Control
                  className="form-input"
                  placeholder="Property Title"
                  onChange={(event) =>
                    this.handleFieldChange("title", event.target.value)
                  }
                />
              </Form.Group>
              <Form.Row>
                <Form.Group as={Col} sm={6}>
                  <Form.Label>Property Type *</Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue="Select Property"
                    onChange={(event) =>
                      this.handleFieldChange("propertyType", event.target.value)
                    }
                  >
                    <option>Select Property</option>
                    <option>Condo</option>
                    <option>Plot</option>
                    <option>Single Family</option>
                    <option>Multi Family</option>
                    <option>Apartment</option>
                    <option>Duplex</option>
                    <option>Office</option>
                    <option>Townhouse</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <Form.Label>Offer Type *</Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue="Select Offer"
                    onChange={(event) =>
                      this.handleFieldChange("offerType", event.target.value)
                    }
                  >
                    <option>Select Offer</option>
                    <option>Buy</option>
                    <option>Rent</option>
                    <option>Sell</option>
                    <option>Sold</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={6} controlId="formGridEmail">
                  <Form.Label>Description *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter property description"
                    onChange={(event) =>
                      this.handleFieldChange(
                        "user_description",
                        event.target.value
                      )
                    }
                  />
                </Form.Group>

                <Form.Group as={Col} sm={6}>
                  <Form.Label>Property Size (Ft²) *</Form.Label>
                  <Form.Control
                    placeholder="Enter property size"
                    onChange={(event) =>
                      this.handleFieldChange("size", event.target.value)
                    }
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Please search for address below</Form.Label>
                  <AutoComplete
                    style={{ width: "100%" }}
                    setLocation={setLocation}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={6}>
                  <Form.Label>Property Address</Form.Label>
                  <Form.Control
                    disabled
                    type="address"
                    placeholder={loc ? loc.address : "Property Address"}
                    onChange={(event) =>
                      this.handleFieldChange("address", event.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    disabled
                    placeholder={loc ? loc.city : "City"}
                    onChange={(event) =>
                      this.handleFieldChange("city", event.target.value)
                    }
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={6}>
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    disabled
                    type="state"
                    placeholder={loc ? loc.state : "State"}
                    onChange={(event) =>
                      this.handleFieldChange("state", event.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <Form.Label>Zip Code</Form.Label>
                  <Form.Control
                    disabled
                    placeholder={loc ? loc.zip : "Zip Code"}
                    onChange={(event) =>
                      this.handleFieldChange("zip", event.target.value)
                    }
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={6}>
                  <Form.Label>Bedrooms*</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter number of bedrooms"
                    onChange={(event) =>
                      this.handleFieldChange("bed", event.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} sm={6} controlId="formGridEmail">
                  <Form.Label>Bathrooms *</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter number of bathrooms"
                    onChange={(event) =>
                      this.handleFieldChange("bath", event.target.value)
                    }
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={6}>
                  <Form.Label>Parking *</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter number of parking spots"
                    onChange={(event) =>
                      this.handleFieldChange("parking", event.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} sm={6} controlId="formGridEmail">
                  <Form.Label>Keywords *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter property keywords"
                    onChange={(event) =>
                      this.handleFieldChange("keywords", event.target.value)
                    }
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={6}>
                  <Form.Label>Year Built*</Form.Label>
                  <Form.Control
                    type="year"
                    placeholder="Enter number of parking spots"
                    onChange={(event) =>
                      this.handleFieldChange("year", event.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <Form.Label>Amenities/Features</Form.Label>
                  <Multiselect
                    placeholder="Select Features"
                    options={this.state.amenities} // Options to display in the dropdown
                    onSelect={this.onSelect} // Function will trigger on select event
                    onRemove={this.onRemove} // Function will trigger on remove event
                    displayValue="value" // Property name to display in the dropdown options
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={6}>
                  <Form.Label>Asking Price *</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="$0.00"
                    onChange={(event) =>
                      this.handleFieldChange("price", event.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} sm={6} controlId="formGridEmail">
                  <Form.Label>
                    How Much Do You Think The Home is Worth? *
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="$0.00"
                    onChange={(event) =>
                      this.handleFieldChange("value", event.target.value)
                    }
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={6}>
                  <Form.Label>HOA Fees *</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="$0.00"
                    onChange={(event) =>
                      this.handleFieldChange("fees", event.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <Form.Label>
                    Is The Home Vacant, Rented Or Lived In? *
                  </Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue="Select"
                    onChange={(event) =>
                      this.handleFieldChange("living", event.target.value)
                    }
                  >
                    <option>Select</option>
                    <option>Vacant</option>
                    <option>Lived In</option>
                    <option>Rented</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Group>
                <Form.Label>Why Are You Selling? *</Form.Label>
                <Form.Control
                  className="form-input"
                  placeholder="Listing Reason"
                  onChange={(event) =>
                    this.handleFieldChange("reason", event.target.value)
                  }
                />
              </Form.Group>
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  onChange={(event) =>
                    this.setState({ floorPlan: !this.state.floorPlan })
                  }
                />
                <p>I HAVE A FLOOR PLAN</p>
                <div className="small-header-row-submit-plan"></div>
              </Form.Group>
              <Collapse in={this.state.floorPlan}>
                <div id="example-collapse-text">
                  <p>Upload Plan: </p>
                  <p></p>
                  <p></p>
                </div>
              </Collapse>
              <div className="submit-button-container">
                <Button
                  className="submit-button"
                  type="submit"
                  onClick={(e) => this.handleSubmit(e)}
                >
                  Submit
                </Button>
                {/* <this.SubmitModal /> */}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  SubmitingProperty = () => {
    if (this.state.bulkUpload) {
      return (
        <div>
          <div style={{ textAlign: "center" }}>
            {this.state.loading ? (
              <p>Please wait ...</p>
            ) : (
              <div>
                <p>Submit These properties?</p>
                {this.state.bulkProperties.map((property) => {
                  return (
                    <>
                      <this.PropertyCard
                        sell={true}
                        title={property.Keyword}
                        address={property.ADDRESS}
                        price={property.Price}
                      />
                      <p />
                    </>
                  );
                })}
              </div>
            )}
            {!this.state.uploadResponse ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <p>{this.state.uploadResponse}</p>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Container>
            <h5 className="loading">Submitting Property...</h5>
            <p></p>
            <p></p>
            {/* <Spinner animation='border' variant='primary' /> */}
            {/* <Invest /> */}
            <Far />
            <p></p>
            <p></p>
          </Container>
        </div>
      );
    }
  };
  render() {
    return (
      <div className="submit-property-page property">
        {this.renderRedirect()}
        <Container fluid className="jumbo">
          <Row>
            <Col sm={5}>
              <div className="jumbo-content-left">
                <h2>Submit Property</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="submit-property-body">
          {this.state.openSubmit ? (
            <this.SubmitingProperty />
          ) : (
            <this.UploadProperty />
          )}
          <br />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    user: state.user.user,
    authSuccess: state.auth.authSuccess,
    jwtToken: state.auth.jwtToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadImages: (images, location, cb) =>
      dispatch(uploadImages(images, location, cb)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitProperty);

/**
 * ADDRESS: "163 Hillside St"
CITY: "Boston"
HOA Fees: "0"
Home: "120000"
Keyword: "Pre-foreclosure "
LATITUDE: "42.3331"
LONGITUDE: "-71.0966"
Live: "Live"
"Parking ": "1"
Price: "45000"
Selling: "Avoiding foreclosure"
YEAR: "1935"
Zipcode: "2120"
bathRooms: "3"
bedRooms: "6"
description: "This is demo property description"
feature: "1"
propertyCategory: "6"
propertySize: "3281"
searchType: "1"
title: "Notice of Default "
userID: "10000"
 */
