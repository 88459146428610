import React from "react";
import {
  InputGroup,
  Form,
  FormControl,
  Button,
  Row,
  Col,
  Card,
  Container,
} from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

class LocalConsult extends React.Component {
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  localconsult = () => {
    return (
      <div className="consult-page">
        <Container fluid className="jumbo">
          <Row>
            <Col sm={5}>
              <div className="jumbo-content-left">
                <h2>LOCAL INVESTOR CONSULTANT</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="consult-body">
          <Row>
            <Col sm={4}>
              <Card className="consult-image" />
            </Col>
            <Col className="consult-content">
              <Card className="consult-text">
                <p1>
                  Everyone is starting to realize now is the greatest time in
                  history to invest in Real Estate, but many don’t know where to
                  start and some are in fear of branching to other markets which
                  are unfamiliar. We’ve just made this easy for you. On
                  InvestFar we have vetted and certified Real Estate investors
                  in multiple cities and states across the nation with years of
                  experience. Our local investor consultants are currently
                  active in real estate and are doing deals in the areas in
                  which they are consulting.
                  <br />
                  <br />
                  If you’re looking to invest locally or outside of your area
                  and need boots on the ground to farm out an area, we’ll
                  schedule an appointment with a local investor consultant in
                  the state and city you are looking to invest in and schedule a
                  time to connect. Once you’ve been connected with a local
                  investor consultant, build that relationship and add them to
                  your team of contractors, private lenders, and other companies
                  you’ve connected with on InvestFar.
                  <br />
                  We’re here to make sure you don’t go at it alone.
                  <br />
                  To speak with an investor consultant, download our app now.
                  <br />
                  Looking to be an Investor Consultant for InvestFar, please
                  register below.
                </p1>
                <br />
                <Link to="./login">
                  <Button className="register-button">
                    Agent Registration <BsArrowRight />
                  </Button>
                </Link>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  render() {
    require("./LocalConsult.css");
    return <this.localconsult />;
  }
}

export default LocalConsult;
