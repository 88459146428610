/**
 * Favorite Property Object Constructor
 * @prop {Object} property the property object to be favoured
 */
function Favorite(property, timestamp) {
    this.address = property.location.full
    this.city = property.location.city
    this.price = property.description.price
    this.title = property.title
    this.rating = property.rating ? property.rating.average : null
    this.owner = property.owner
    this.timestamp = timestamp
    this.id = property.id
    this.image = property.images ? property.images[0] : null
}

module.exports = {
    Favorite
}