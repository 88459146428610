import React, { useState } from "react";
import "./Buy.css";
import { connect } from "react-redux";
import jsonwebtoken from "jsonwebtoken";
import { Redirect, Link } from "react-router-dom";
import axios from "axios";
import {
  InputGroup,
  Form,
  Button,
  Row,
  Col,
  Container,
  Card,
} from "react-bootstrap";
import NumberFormat from "react-number-format";
import { Error, Primary, Success, Info, Warning } from "../Alerts";

class Buy extends React.Component {
  state = {
    searchResults: [],
    query: {},
    anyType: false,
    savePropState: [],
    properties: [],
    loading: false,
    error: null,
    filter: null,
    enableFilter: false,
  };

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  getCities = (cb) => {
    axios
      .get(`/api/additional/cities`)
      .then((res) => {
        if (res.data.success) {
          console.log("These are the available cities", res.data.msg);
          cb(res.data.msg.available);
        } else {
          cb(["Failed to get cities"]);
          console.log("Failed to get them", res);
        }
      })
      .catch((error) => {
        cb(["Failed to get cities"]);
        console.log("Failed to get them" + String(error));
      });
  };
  buy = () => {
    const { jwtToken } = this.props;
    const { authSuccess } = this.props;
    const [fetching, setFetching] = React.useState(false);
    const [done, setDone] = React.useState(false);
    const [cityOptions, setCityOptions] = React.useState(["City"]);

    function getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    }
    var searchProperty = (cb) => {
      var finalQuery = this.state.query;
      Object.keys(finalQuery).map((key) => {
        if (Array.isArray(finalQuery[key])) {
          if (finalQuery[key].length < 1) delete finalQuery[key];
        } else if (
          !finalQuery[key] ||
          (typeof finalQuery[key] == "string" && finalQuery[key].length == 0)
        ) {
          delete finalQuery[key];
        }
      });

      console.log("Final query", finalQuery);

      var data = {
        query: finalQuery,
      };

      axios
        .post(`/api/properties/search`, data)
        .then(function (response) {
          if (response.data) {
            cb(response.data);
          } else {
            console.log("error here on 59");
          }
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log("Error here on 62");
          Error({
            name: "Search is not yet available",
            message:
              "We have aplogize for the inconvinience, we are now adding this search now, please come back later",
          });
          console.log(error);
          cb({ msg: String(error), success: false });
        });
    };

    var handleSubmit = () => {
      window.scrollTo(0, 500);
      this.setState({ loading: true });
      searchProperty((response) => {
        this.setState({ loading: false });

        console.log("Response", response);
        if (response.success) {
          this.setState({ searchResults: response.msg.list });
          // setSearchResults(response.msg.list)
          // setLoading(false)

          if (response.msg.filter.length >= 1) {
            //handle filter before setting propeties
            console.log(response.msg);
            var unfiltered = response.msg.list
              ? response.msg.list
              : response.msg;
            // var filtered = []
            //  unfiltered.filter(property=> {

            // })
            this.setState({
              properties: response.msg.list ? response.msg.list : response.msg,
              filter: response.msg.filter,
              enableFilter: true,
            });
            // setProperties(response.msg.list ? response.msg.list : response.msg)
            console.log(this.state.properties);
          } else {
            // setProperties(response.msg.list)
            this.setState({ properties: response.msg.list });
          }
        } else {
          // setError(String(response.msg))
          // setLoading(false)
          this.setState({ error: response.msg });
        }
      });
    };

    var handleFieldChange = (field, event) => {
      var tmp = this.state.query;
      switch (field) {
        case "propertyType":
          var type = event.target.id;
          var value = event.target.checked;
          if (type == "Any") {
            // setAnytype(value)
            this.setState({ anyType: value });
            if ((value = true && this.state.query["description.type"])) {
              // setSavePRopState(query['description.type'])
              this.setState({
                savePropState: this.state.query["description.type"],
              });
              delete this.state.query["description.type"];
            } else if (!value && this.state.savePropState.length >= 1) {
              var tmp = this.state.query;
              tmp["description.type"] = this.state.savePropState;
            }
          } else {
            var tmp = this.state.query["description.type"]
              ? this.state.query["description.type"]
              : [];
            if (
              !value &&
              (tmp.includes(type) ||
                this.state.query["description.type"].includes(type))
            )
              tmp = tmp.filter((item) => item !== type);
            else tmp.push(type);
            var newQuery = this.state.query;
            newQuery["description.type"] = tmp;
            this.setState({ savePropState: tmp });
            this.setState({ query: newQuery });
            // setSavePRopState(tmp)
            // setQuery(newQuery)
          }
          break;
        case "City":
          tmp["location.city"] = event.target.value;
          this.setState({ query: tmp });
          // this.setState({query: tmp})
          break;
        case "Price":
          var type = event.target.id;
          if (type == "min") {
            tmp["description.price"] = {
              min: event.target.value,
              max: tmp["description.price"]
                ? tmp["description.price"].max
                : 1000000000,
            };
          } else {
            tmp["description.price"] = {
              max: event.target.value,
              min: tmp["description.price"] ? tmp["description.price"].min : 0,
            };
          }
          this.setState({ query: tmp });
          break;
        case "keyword":
          tmp["keyword"] = event.target.value;
          this.setState({ query: tmp });
          break;
        case "bedrooms":
          var type = event.target.id;
          if (type == "min") {
            tmp["description.bed"] = {
              min: event.target.value,
              max: tmp["description.bed"]
                ? tmp["description.bed"].max
                : 1000000000,
            };
          } else {
            tmp["description.bed"] = {
              max: event.target.value,
              min: tmp["description.bed"] ? tmp["description.bed"].min : 0,
            };
          }
          this.setState({ query: tmp });
          break;
        case "bathrooms":
          var type = event.target.id;
          if (type == "min") {
            tmp["description.bath"] = {
              min: event.target.value,
              max: tmp["description.bath"]
                ? tmp["description.bath"].max
                : 1000000000,
            };
          } else {
            tmp["description.bath"] = {
              max: event.target.value,
              min: tmp["description.bath"] ? tmp["description.bath"].min : 0,
            };
          }
          this.setState({ query: tmp });
          break;
        case "size":
          var type = event.target.id;
          if (type == "min") {
            tmp["description.size"] = {
              min: event.target.value,
              max: tmp["description.size"]
                ? tmp["description.size"].max
                : 1000000000,
            };
          } else {
            tmp["description.size"] = {
              max: event.target.value,
              min: tmp["description.size"] ? tmp["description.size"].min : 0,
            };
          }
          this.setState({ query: tmp });
          break;
        case "fees":
          tmp["description.fees"] = event.target.value;
          this.setState({ query: tmp });
          break;
        case "year":
          var type = event.target.id;
          if (type == "min") {
            tmp["description.year"] = {
              min: event.target.value,
              max: tmp["description.year"]
                ? tmp["description.year"].max
                : 1000000000,
            };
          } else {
            tmp["description.year"] = {
              max: event.target.value,
              min: tmp["description.year"] ? tmp["description.year"].min : 0,
            };
          }
          this.setState({ query: tmp });
          break;
        case "features":
          var type = event.target.id;
          var value = event.target.checked;
          var temp = this.state.query["description.amenities"]
            ? this.state.query["description.amenities"]
            : [];
          if (
            !value &&
            (temp.includes(type) ||
              this.state.query["description.amenities"].includes(type))
          )
            temp = temp.filter((item) => item !== type);
          else temp.push(type);
          tmp["description.amenities"] = temp;
          this.setState({ query: tmp });
          break;
        case "parking":
          tmp["description.park"] = event.target.value;
          this.setState({ query: tmp });
          break;
        case "rating":
          var type = event.target.id;
          if (type == "min") {
            tmp["description.rating.average"] = {
              min: event.target.value,
              max: tmp["description.rating.average"]
                ? tmp["description.rating.average"].max
                : 1000000000,
            };
          } else {
            tmp["description.rating.average"] = {
              max: event.target.value,
              min: tmp["description.rating.average"]
                ? tmp["description.rating.average"].min
                : 0,
            };
          }
          this.setState({ query: tmp });
          break;
        case "status":
          tmp["details.status"] = event.target.value;
          this.setState({ query: tmp });
          break;
        default:
          console.log("Default switch");
          break;
      }
      console.log("Query", this.state.query);
    };

    // if (jwtToken != null && jwtToken != "") {
    //     var decodedToken = jsonwebtoken.decode(jwtToken);
    // }
    // else {
    //     // return <Redirect to="/login" />
    //     console.log('No token')
    // }
    // var dateNow = new Date();
    // if (!(authSuccess && decodedToken && ((decodedToken.exp * 1000) > dateNow.getTime()))) {
    //     // return <Redirect to="/login" />
    //     console.log('no auth')
    // }
    return (
      <div className="buy-page">
        <section className="buy-page-title">
          <Container className="auto-container">
            <Row className="clearfix">
              <Col lg="auto" md="auto" sm="auto" xs="auto">
                <h1 className="text-uppercase">BUY</h1>
              </Col>
            </Row>
          </Container>
        </section>
        <Container>
          <div className="content-side">
            <div className="content-section services-content">
              <div className="text-block text-justify">
                <div className="buy-sec-title">
                  <h3>INVESTFAR</h3>
                </div>
                <h4>Welcome to the future of real estate investing.</h4>
                <p>
                  Location, location, location! It's an old saying in real
                  estate, but very true. The most important part of investing in
                  out of market properties is picking location. Rising prices
                  are forcing many real estate investors to consider investing
                  in out-of-state properties. Out-of-state investing is not
                  easy, but can be done and can produce great results. You must
                  build a great team. buy below market value and keep a close
                  eye on your properties.
                </p>
                <p>
                  It will be virtually impossible to buy. repair and manage
                  homes from another state by yourself. It will save you money
                  and heartache in the long run to use local professionals who
                  know the market. You will need a great team to handle buying
                  and renting a long-distance property for you.
                </p>
                <p>
                  With the resources on InvestFar you can build that team on one
                  platform without ever leaving your home.
                </p>
              </div>
            </div>
          </div>
        </Container>

        <Container>
          <Row>
            <Col className="buy-options" sm={5} md={4}>
              <Form>
                <h4 className="buy-form-label">Property Type</h4>
                <Form.Group controlId="propertyType">
                  <Form.Check
                    onChange={(event) =>
                      handleFieldChange("propertyType", event)
                    }
                    id="Any"
                    type="checkbox"
                    label="Any"
                  />
                  <Form.Check
                    onChange={(event) =>
                      handleFieldChange("propertyType", event)
                    }
                    id="Condo"
                    type="checkbox"
                    disabled={this.state.anyType}
                    label="Condo"
                  />
                  <Form.Check
                    onChange={(event) =>
                      handleFieldChange("propertyType", event)
                    }
                    id="Plot"
                    type="checkbox"
                    disabled={this.state.anyType}
                    label="Plot"
                  />
                  <Form.Check
                    onChange={(event) =>
                      handleFieldChange("propertyType", event)
                    }
                    id="Single Family"
                    type="checkbox"
                    disabled={this.state.anyType}
                    label="Single Family"
                  />
                  <Form.Check
                    onChange={(event) =>
                      handleFieldChange("propertyType", event)
                    }
                    id="Multi Family"
                    type="checkbox"
                    disabled={this.state.anyType}
                    label="Multi Family"
                  />
                  <Form.Check
                    onChange={(event) =>
                      handleFieldChange("propertyType", event)
                    }
                    id="Apartment"
                    type="checkbox"
                    disabled={this.state.anyType}
                    label="Apartment"
                  />
                  <Form.Check
                    onChange={(event) =>
                      handleFieldChange("propertyType", event)
                    }
                    id="Duplex"
                    type="checkbox"
                    disabled={this.state.anyType}
                    label="Duplex"
                  />
                  <Form.Check
                    onChange={(event) =>
                      handleFieldChange("propertyType", event)
                    }
                    id="Office"
                    type="checkbox"
                    disabled={this.state.anyType}
                    label="Office"
                  />
                  <Form.Check
                    onChange={(event) =>
                      handleFieldChange("propertyType", event)
                    }
                    id="Townhouse"
                    type="checkbox"
                    disabled={this.state.anyType}
                    label="Townhouse"
                  />
                </Form.Group>
                <h4 className="buy-form-label">City</h4>
                <InputGroup className="buy-form-dropdown">
                  {fetching && !done ? (
                    <Form.Control as="select" disabled custom>
                      <option>Loading...</option>
                    </Form.Control>
                  ) : (
                    <Form.Control
                      as="select"
                      custom
                      onClick={() => {
                        if (!done) {
                          setFetching(true);
                          this.getCities((cities) => {
                            setCityOptions(["Select City"].concat(cities));
                            setFetching(false);
                            setDone(true);
                          });
                        }
                      }}
                      onChange={(event) => handleFieldChange("City", event)}
                    >
                      {fetching ? (
                        <option>{cityOptions[0]}</option>
                      ) : (
                        cityOptions.map((option) => {
                          return <option>{option}</option>;
                        })
                      )}
                    </Form.Control>
                  )}
                </InputGroup>
                <h4 className="buy-form-label">Price ($)</h4>
                <Form.Row>
                  <Form.Group as={Col} className="buy-form-group">
                    <Col sm={10}>
                      <Form.Control
                        onChange={(event) => handleFieldChange("Price", event)}
                        placeholder="Low"
                        name="priceLowBuy"
                        id="min"
                        className="buy-form-control"
                        type="number"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Col} className="buy-form-group">
                    <Col sm={10}>
                      <Form.Control
                        onChange={(event) => handleFieldChange("Price", event)}
                        placeholder="High"
                        name="priceHighBuy"
                        id="max"
                        className="buy-form-control"
                        type="number"
                      />
                    </Col>
                  </Form.Group>
                </Form.Row>
                <h4 className="buy-form-label">Keyword</h4>
                <Form.Group className="buy-form-group">
                  <Form.Control
                    onChange={(event) => handleFieldChange("keyword", event)}
                    name="name"
                    placeholder="Keyword"
                    className="buy-form-control"
                    type="name"
                  />
                </Form.Group>
                <h4 className="buy-form-label">Bedrooms</h4>
                <Form.Row>
                  <Form.Group as={Col} className="buy-form-group">
                    <Col sm={10}>
                      <InputGroup className="buy-form-dropdown">
                        <Form.Control
                          onChange={(event) =>
                            handleFieldChange("bedrooms", event)
                          }
                          id="min"
                          as="select"
                          custom
                        >
                          <option>Low</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5+</option>
                        </Form.Control>
                      </InputGroup>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Col} className="buy-form-group">
                    <Col sm={10}>
                      <InputGroup className="buy-form-dropdown">
                        <Form.Control
                          as="select"
                          custom
                          onChange={(event) =>
                            handleFieldChange("bedrooms", event)
                          }
                          id="max"
                        >
                          <option>High</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5+</option>
                        </Form.Control>
                      </InputGroup>
                    </Col>
                  </Form.Group>
                </Form.Row>
                <h4 className="buy-form-label">Bathrooms</h4>
                <Form.Row>
                  <Form.Group as={Col} className="buy-form-group">
                    <Col sm={10}>
                      <InputGroup className="buy-form-dropdown">
                        <Form.Control
                          as="select"
                          custom
                          onChange={(event) =>
                            handleFieldChange("bathrooms", event)
                          }
                          id="min"
                        >
                          <option>Low</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5+</option>
                        </Form.Control>
                      </InputGroup>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Col} className="buy-form-group">
                    <Col sm={10}>
                      <InputGroup className="buy-form-dropdown">
                        <Form.Control
                          as="select"
                          custom
                          onChange={(event) =>
                            handleFieldChange("bathrooms", event)
                          }
                          id="max"
                        >
                          <option>High</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5+</option>
                        </Form.Control>
                      </InputGroup>
                    </Col>
                  </Form.Group>
                </Form.Row>
                <h4 className="buy-form-label">Property Size (FT2)</h4>
                <Form.Row>
                  <Form.Group as={Col} className="buy-form-group">
                    <Col sm={10}>
                      <Form.Control
                        onChange={(event) => handleFieldChange("size", event)}
                        id="min"
                        placeholder="Low"
                        name="priceLowBuy"
                        className="buy-form-control"
                        type="number"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Col} className="buy-form-group">
                    <Col sm={10}>
                      <Form.Control
                        onChange={(event) => handleFieldChange("size", event)}
                        id="max"
                        placeholder="High"
                        name="priceHighBuy"
                        className="buy-form-control"
                        type="number"
                      />
                    </Col>
                  </Form.Group>
                </Form.Row>
                <h4 className="buy-form-label">HOA Fees ($)</h4>
                <Form.Group className="buy-form-group">
                  <Form.Control
                    onChange={(event) => handleFieldChange("fees", event)}
                    name="name"
                    placeholder="HOA Fees"
                    className="buy-form-control"
                    type="name"
                  />
                </Form.Group>
                <h4 className="buy-form-label">Year Built</h4>
                <Form.Row>
                  <Form.Group as={Col} className="buy-form-group-2">
                    <Col sm={10}>
                      <InputGroup className="buy-form-dropdown">
                        <Form.Control
                          as="select"
                          custom
                          onChange={(event) => handleFieldChange("year", event)}
                          id="min"
                        >
                          <option>Low</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5+</option>
                        </Form.Control>
                      </InputGroup>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Col} className="buy-form-group-2">
                    <Col sm={10}>
                      <InputGroup className="buy-form-dropdown">
                        <Form.Control
                          as="select"
                          custom
                          onChange={(event) => handleFieldChange("year", event)}
                          id="max"
                        >
                          <option>High</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5+</option>
                        </Form.Control>
                      </InputGroup>
                    </Col>
                  </Form.Group>
                </Form.Row>
                <h4 className="buy-form-label">Features</h4>
                <Form.Group controlId="propertyFeatures">
                  <Form.Check
                    id="Air Condition"
                    onChange={(event) => handleFieldChange("features", event)}
                    type="checkbox"
                    label="Air Condition"
                  />
                  <Form.Check
                    onChange={(event) => handleFieldChange("features", event)}
                    type="checkbox"
                    id="Fire Place"
                    label="Fire Place"
                  />
                  <Form.Check
                    onChange={(event) => handleFieldChange("features", event)}
                    type="checkbox"
                    label="High Ceilings"
                    id="High Ceilings"
                  />
                  <Form.Check
                    onChange={(event) => handleFieldChange("features", event)}
                    type="checkbox"
                    label="Wood Floor"
                    id="Wood Floor"
                  />
                  <Form.Check
                    onChange={(event) => handleFieldChange("features", event)}
                    type="checkbox"
                    label="Laundry"
                    id="Laundry"
                  />
                  <Form.Check
                    onChange={(event) => handleFieldChange("features", event)}
                    type="checkbox"
                    label="Carpet"
                    id="Carpet"
                  />
                  <Form.Check
                    onChange={(event) => handleFieldChange("features", event)}
                    type="checkbox"
                    label="Pool"
                    id="Pool"
                  />
                  <Form.Check
                    onChange={(event) => handleFieldChange("features", event)}
                    type="checkbox"
                    label="Garage"
                    id="Pool"
                  />
                </Form.Group>
                <h4 className="buy-form-label">Parking</h4>
                <Form.Group className="buy-form-group">
                  <Form.Control
                    onChange={(event) => handleFieldChange("parking", event)}
                    name="name"
                    placeholder="Number Of Parking Spaces"
                    className="buy-form-control"
                    type="number"
                  />
                </Form.Group>
                <h4 className="buy-form-label">Rating</h4>
                <Form.Row>
                  <Form.Group as={Col} className="buy-form-group-2">
                    <Col sm={10}>
                      <InputGroup className="buy-form-dropdown">
                        <Form.Control
                          as="select"
                          custom
                          id="min"
                          onChange={(event) =>
                            handleFieldChange("rating", event)
                          }
                        >
                          <option>Low</option>
                          <option>0.5</option>
                          <option>1</option>
                          <option>1.5</option>
                          <option>2</option>
                          <option>2.5</option>
                          <option>3</option>
                          <option>3.5</option>
                          <option>4</option>
                          <option>4.5</option>
                          <option>5</option>
                        </Form.Control>
                      </InputGroup>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Col} className="buy-form-group-2">
                    <Col sm={10}>
                      <InputGroup className="buy-form-dropdown">
                        <Form.Control
                          as="select"
                          custom
                          onChange={(event) =>
                            handleFieldChange("rating", event)
                          }
                          id="max"
                        >
                          <option>High</option>
                          <option>0.5</option>
                          <option>1</option>
                          <option>1.5</option>
                          <option>2</option>
                          <option>2.5</option>
                          <option>3</option>
                          <option>3.5</option>
                          <option>4</option>
                          <option>4.5</option>
                          <option>5</option>
                        </Form.Control>
                      </InputGroup>
                    </Col>
                  </Form.Group>
                </Form.Row>
                <h4 className="buy-form-label">
                  Is The Home Vacant Or Occupied?
                </h4>
                <InputGroup className="buy-form-dropdown">
                  <Form.Control
                    as="select"
                    custom
                    onChange={(event) => handleFieldChange("status", event)}
                  >
                    <option>Vacant</option>
                    <option>Occupied</option>
                  </Form.Control>
                </InputGroup>
                <Row className="rent-row">
                  <Col xs={6}>
                    <Button
                      onClick={() => handleSubmit()}
                      variant="primary"
                      className="btn-blue"
                    >
                      Submit
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn-blue"
                      style={{ marginLeft: "40px" }}
                    >
                      Clear
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col sm={7} md={8} className="latest-listings no-padding">
              <Col className="buy-sec-title margin-bott-30">
                <h3>Townhouse</h3>
              </Col>
              <div className="found mb_10">
                <strong>
                  {this.state.properties ? this.state.properties.length : 0}{" "}
                  FOUND
                </strong>
              </div>

              {this.state.loading ? (
                <div>Loading...</div>
              ) : (
                <div>
                  <Row>
                    {this.state.properties ? (
                      this.state.properties.map((listing) => (
                        <Col
                          className="sell-listings"
                          key={listing.title}
                          sm={12}
                          md={6}
                        >
                          <Link
                            to={{
                              pathname: "/property",
                              propertyProps: listing.title,
                              currentProperty: listing,
                              search: `${listing.id}`,
                            }}
                          >
                            <Card
                              className="list-card"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              <div>
                                <div className="price">
                                  <NumberFormat
                                    value={
                                      listing.description.price
                                        ? listing.description.price
                                        : ""
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                </div>
                                <Row
                                  className="listing-img"
                                  style={{
                                    backgroundImage: `url(${
                                      listing.images ? listing.images[0] : ""
                                    })`,
                                  }}
                                  className="listing-img"
                                ></Row>
                                <Row className="listing-inner">
                                  <Col>
                                    <Row className="header">
                                      <p>{listing.title}</p>
                                    </Row>
                                    <Row>
                                      <p
                                        style={{
                                          color: "rgba(0,0,0,0.9)",
                                        }}
                                      >
                                        {listing.location &&
                                        listing.location.full
                                          ? listing.location.full
                                          : listing.location &&
                                            listing.location.address
                                          ? listing.location.address
                                          : ""}
                                      </p>
                                    </Row>
                                    <Row className="date-range">
                                      <p>
                                        {listing.timestamp
                                          ? listing.timestamp
                                          : ""}
                                      </p>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          </Link>
                        </Col>
                      ))
                    ) : (
                      <div> Failed to load</div>
                    )}
                  </Row>{" "}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  render() {
    require("./Buy.css");
    return <this.buy />;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    authSuccess: state.auth.authSuccess,
    jwtToken: state.auth.jwtToken,
  };
};

export default connect(mapStateToProps)(Buy);
