import axios from "axios";
import React from "react";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { getFirestore } from "redux-firestore";
import { updateProfile } from "./userActions";
var request = require("request");

const updateUser = (user, cb) => {
  var options = {
    method: "POST",
    url: `${process.env.REACT_APP_BACKEND}/users/update`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user: user }),
  };
  request(options, function (error, response) {
    console.log("Response", response);
    if (error) {
      cb({ error: String(error) });
    } else {
      if (JSON.parse(response.body).success) {
        cb(JSON.parse(response.body));
      } else {
        cb({ error: String(JSON.parse(response.body).msg) });
      }
    }
  });
};

const getUserObject = (user_id, cb) => {
  var options = {
    method: "GET",
    url: `${process.env.REACT_APP_BACKEND}/users/object?user_id=${user_id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  request(options, function (error, response) {
    if (error) {
      console.log("Error", error);
      cb(null);
    } else {
      var res = JSON.parse(response.body);
      if (res.success) {
        cb(res.msg);
      } else {
        cb(null);
      }
    }
    // if (error) {
    //   cb({ error: String(error) })
    // } else {
    //   if (JSON.parse(response).success) {
    //     cb(JSON.parse(response))
    //   } else {
    //     cb({ "error": String(JSON.parse(response).msg) })
    //   }
    // }
  });
};
export const loginUser = (user) => {
  return (dispatch, getState, { getFirebase, getFireStore }) => {
    // Make async call to database
    //   axios.post(`http://localhost:8000/users/authenticate`, user)
    // .then(res => {
    //     if(res.data.success == true){
    //       console.log("Successful Login!");
    //       localStorage.setItem('jwtToken', res.data.token);
    //       dispatch({ type: 'LOGIN_SUCCESS', user: user, token:res.data.token});
    //       dispatch({ type: 'RETURN_USER', user:res.data.user});
    //     }
    //     else if(res.data.success == false){
    //         console.log("Trouble Logging In!");
    //         console.log(res.data.msg)
    //         dispatch({ type: 'LOGIN_ERROR', msg:res.data.msg});
    //     }
    // })

    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase
      .auth()
      .signInWithEmailAndPassword(user.email, user.password)
      .then((response) => {
        firestore
          .collection("Users")
          .doc(response.user.uid)
          .get()
          .then((res) => {
            dispatch({ type: "LOGIN_SUCCESS", userObj: res.data() });
            dispatch({ type: "RETURN_USER", userObj: res.data() });
          });
      })
      .catch((error) => {
        firebase.auth().signOut();
        dispatch({ type: "LOGIN_ERROR", error: error });
      });
  };
};

export const loginWithFaceBook = () => {
  console.log("Login with facebook clicked");
  return (dispatch, getState, { getFirebase, getFireStore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    var provider = new firebase.auth.FacebookAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // The signed-in user info.
        var user = result.user;
        console.log("User Information here", user);

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        var accessToken = credential.accessToken;

        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;

        // ...
      });
  };
};

export const passwordReset = (email) => {
  return (dispatch, getState, { getFirebase, getFireStore }) => {
    // Make async call to database
    const firebase = getFirebase();
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        dispatch({
          type: "PASSWORD_RESET_SUCCESS",
        });
      })
      .catch(function (e) {
        // handle error
        console.log("ESEIEI: ", e);
        // console.log(e['Error'])
        dispatch({
          type: "PASSWORD_RESET_ERROR",
          error: e,
        });
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase, getFireStore }) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "SIGNOUT_SUCCESS" });
      });
  };
};

export const createUser = (user) => {
  return (dispatch, getState, { getFirebase, getFireStore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    console.log(user);
    firebase
      .auth()
      .createUserWithEmailAndPassword(user.email, user.password)
      .then((resp) => {
        user["user_id"] = resp.user.uid;
        user.quickblox_user_id = "";
        user.password = "";
        user.connection_list = {};
        firestore
          .collection("Users")
          .doc(resp.user.uid)
          .set(Object.assign({}, user))
          .then(() => {
            dispatch({ type: "SIGNUP_SUCCESS" });
          })
          .catch((error) => {
            console.log("ERROR");
            console.log(error);
            dispatch({ type: "SIGNUP_ERROR", error: error });
          });
      })
      .catch((error) => {
        console.log("ERROR");
        console.log(error);
        dispatch({ type: "SIGNUP_ERROR", error: error });
      });
  };
};

export const resolveError = () => {
  return (dispatch, getState, { getFirebase, getFireStore }) => {
    dispatch({ type: "RESOLVE_ERROR" });
  };
};

export const displayError = (error) => {
  return (dispatch, getState, { getFirebase, getFireStore }) => {
    // Make async call to database
    console.log(error);
    console.log(typeof error);
    dispatch({ type: "DISPLAY_ERROR", error: error });
  };
};

export const updateAuthSuccess = (bool) => {
  return (dispatch, getState, { getFirebase, getFireStore }) => {
    // Make async call to database
    dispatch({ type: "UPDATE_AUTH", bool: bool });
  };
};

export const resolveField = (field) => {
  return (dispatch, getState, { getFirebase, getFireStore }) => {
    dispatch({ type: "RESOLVE_FIELD", object: field });
  };
};
