
// import { saveState, loadState } from '../localStorage'

import { passwordReset } from "../actions/authActions";

// var initState = loadState()
// initState = initState ? initState : {
//     authError: null,
//     authSuccess: false,
//     jwtToken: null
// }
var initState = {
    authError: null,
    authSuccess: false,
    jwtToken: null,
    passwordResetError: null,
    passwordResetSuccess: false
}


const authReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOGIN_ERROR':
            console.log("Login Error");
            // saveState(null)
            return {
                ...state, authError: action.error.message
            }
        case 'LOGIN_SUCCESS':
            console.log("Login Success");
            // state['user'] = action.userObj
            // saveState({ ...state, authError: null })
            // const serializedState = JSON.stringify({ ...state, authError: null });
            // localStorage.setItem('state', serializedState);
            console.log('here is the state before', state);
            console.log('here is the combined state', {
                ...state, authError: null, user: action.userObj
            })
            return {
                ...state, authError: null, user: action.userObj
            }
        case 'SIGNOUT_SUCCESS':
            console.log("Signout Success");
            // saveState(null)
            return {
                ...state, authSuccess: false
            }
        case 'SIGNUP_SUCCESS':
            // saveState({
            //     ...state, authError: null
            // })
            return {
                ...state, authError: null
            }
        case 'SIGNUP_ERROR':
            // saveState({
            //     ...state, authError: action.error.message
            // })
            console.log(action);
            return {
                ...state, authError: action.error.message
            }
        case 'RESOLVE_ERROR':
            // saveState({
            //     ...state, authError: null
            // })
            return {
                ...state, authError: null,passwordResetError:null
            }
        case 'UPDATE_AUTH':
            // saveState({
            //     ...state, authSuccess: action.bool
            // })
            return {
                ...state, authSuccess: action.bool
            }
        case 'DISPLAY_ERROR':
            // saveState({
            //     ...state, authError: action.error
            // })
            return {
                ...state, authError: action.error
            }
        case 'PASSWORD_RESET_ERROR':
            // saveState({
            //     ...state, authError: action.error
            // })
            return {
                ...state, passwordResetError: action.error.message
            }
        case 'PASSWORD_RESET_SUCCESS':
            // saveState({
            //     ...state, authError: action.error
            // })
            return {
                ...state, passwordResetSuccess: true
            }
        case 'RESOLVE_FIELD':
            // saveState({
            //     ...state, authError: action.error
            // })
            return {
                ...state, passwordResetSuccess: false
            }
        default:
            return state
    }
}


export default authReducer;