import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import {
    Row,
    Col,
    Container,
    Button,
    Spinner,
    Card
} from 'react-bootstrap'
import '../MyProperties/MyProperties.css'
import '../PropertySearch/PropertySearch.css'
import './MyFavorites.css'

import {
    AiFillMinusCircle
} from 'react-icons/ai'
import { AiFillDelete } from 'react-icons/ai'
import { User, CURRENT } from '../../Constructors/user'
import { Error, Warning, Primary, Info, Success } from '../../Alerts'
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import { store } from 'react-notifications-component';
import { Link } from "react-router-dom";
import moment from 'moment'
import { remove } from '@amcharts/amcharts4/.internal/core/utils/Array'
import PropertyListing from '../PropertyListing/PropertyListing'
import Rating from 'react-rating'
import { Favorite } from '../../Constructors/favorite'
import Far from '../../Spinners/Far'
import Avator from './Avator'
import { connect } from 'react-redux';
import axios from 'axios';
import { ResponsiveBreakpoints } from '@amcharts/amcharts4/core'



var request = require('request');


function MyFavorites(props) {
    const [propertyList, setProperties] = useState([])
    const [redirect, setRedirect] = useState(false)
    const [current, setCurrent] = useState({})
    const [deleteProperty, setDeleteProperty] = useState(false)
    const [deleted, setDeleted] = useState(null)
    const [loading, setLoading] = useState(true)
    const [User, setUser] = useState(null)
    const { user, profile } = props;

    var userID = user ? user.user_id : profile ? profile.user_id : null
    console.log('Userid', userID)

    useEffect(() => {
        if (profile !== null) {
            setUser(profile)
        }
        window.scrollTo(0, 0)

        if (userID && userID !== 'undefined') {
            axios.get(`/api/properties/my-favorites?id=${userID}`, {}, { headers: { "content-type": "application/json" } }).then(res => {
                setLoading(false);
                if (res.data) {
                    setProperties(res.data.msg)
                } else {
                    setProperties([])
                }
            })
        } else{
            setProperties([])
        }


    }, [props.profile])

    function currencyFormat(num) {
        return '$' + Number.parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    var remove = (listing) => {
        var body = JSON.stringify({
            "property": listing.id
            , "user_id": userID
        });
        axios.post(`/api/properties/unfavor`, body).then(res => {
            if (res.data.success) {
                Success({ title: 'Success', message: 'Property removed from favorites' })
                var tmp = propertyList
                console.log(tmp)
                setProperties(tmp.filter(el => el !== listing))
            } else {
                Error({ title: 'Failed', message: 'Failed to remove property' });
            }
        }).catch((error) => {
            Error({ title: 'Failed', message: 'Failed to remove property' })
        });
        // var options = {
        //     'method': 'POST',
        //     'url': `/api/properties/unfavor`,
        //     'headers': {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         "property": listing
        //         , "user_id": "100"
        //     })

        // };
        // request(options, function (error, response) {
        //     if (error) {
        //         Error({ title: 'Failed', message: 'Failed to remove property' })
        //     } else {
        //         if (JSON.parse(response.body).success) {
        //             Success({ title: 'Success', message: 'Property removed from favorites' })
        //             var tmp = propertyList
        //             console.log(tmp)
        //             setProperties(tmp.filter(el => el !== listing))
        //             //  setProperties(tmp)
        //         } else {
        //             Error({ title: 'Failed', message: 'Failed to remove property' })
        //         }
        //     }

        // });

    }

    var addFavorite = (property) => {
        var fav = new Favorite(property, new Date().toISOString())
        var tmp = propertyList
        var body = JSON.stringify({
            "property": fav,
            "user_id": "100"
        });
        tmp.push(fav)
        axios.post(`/api/properties/favor`, body).then(res => {
            if (res.data.success) {
                Success({ title: 'Success', message: 'Property added to favorites' })
                setProperties(tmp)
            } else {
                Error({ title: 'Failed', message: 'Failed to add property to favorites' });
            }
            console.log(res.data);
        }).catch((error) => {
            Error({ title: 'Failed', message: 'Failed to add property to favorites' })
        });
        // var options = {
        //     'method': 'POST',
        //     'url': `/api/properties/favor`,
        //     'headers': {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         "property": fav,
        //         "user_id": "100"
        //     })
        // };
        // request(options, function (error, response) {
        //     if (error) {
        //         Error({ title: 'Failed', message: 'Failed to add property to favorites' })
        //     } else {
        //         if (JSON.parse(response.body).success) {
        //             Success({ title: 'Success', message: 'Property added to favorites' })
        //             setProperties(tmp)
        //         } else {
        //             Error({ title: 'Failed', message: 'Failed to add property to favorites' })
        //         }
        //     }
        //     console.log(response.body);
        // });

    }
    var update = (listing) => {
        axios.get(`/api/properties/property?id=${listing.id}`).then(res => {
            if (res.data) {
                var property = []
                try {
                    property = JSON.parse(res.body).msg
                    remove(listing)
                    addFavorite(property)
                    Success({ title: 'Property Updated', message: 'success' })

                } catch (error) {
                    console.log('Error', error)
                    Error({ title: 'Error Encountered', message: 'Failed to update listing' })
                }
            } else {
                console.log('Error', 'Failed to update listing')
                Error({ title: 'Error Encountered', message: 'Failed to update listing' })
            }
        })
        var options = {
            'method': 'GET',
            'url': `/api/properties/property?id=${listing.id}`,
            'headers': {
            }
        };
        // request(options, function (error, response) {
        //     if (error) {
        //         console.log('Error', error)
        //         Error({ title: 'Error Encountered', message: 'Failed to update listing' })
        //     } else {
        //         var property = []
        //         try {
        //             property = JSON.parse(response.body).msg
        //             remove(listing)
        //             addFavorite(property)
        //             Success({ title: 'Property Updated', message: 'success' })

        //         } catch (error) {
        //             console.log('Error', error)
        //             Error({ title: 'Error Encountered', message: 'Failed to update listing' })
        //         }
        //     }
        // });
    }

    require('./MyFavorites.css')
    return (
        <div className='submit-property-page property favorite'>
            <Container fluid className="jumbo">
                <Row>
                    <Col sm={5}>
                        <div className="jumbo-content-left">
                            <h2>MY FAVORITES</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="favorite-properties-body">
                {loading ?
                    (<div style={{ textAlign: 'center' }}>
                        <h5 className='loading'>Loading</h5>
                        <div style={{ height: '20px' }} />
                        {/* <Spinner variant='primary' animation='border' /> */}
                        <Far />
                        <div style={{ height: '100px' }} />

                    </div>
                    )
                    :
                    (
                        <div>
                            {
                                (!propertyList || propertyList.length < 1 && !loading) ?
                                    (
                                        <Container>
                                            <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                                                <Container>
                                                    <h4>NO PROPERTIES</h4>
                                                    <p></p>
                                                    <p></p>
                                                    <p>No properties found</p>                                                <p></p>
                                                    <p></p>
                                                </Container>

                                            </div>
                                        </Container>
                                    ) :
                                    (

                                        <div className='favorite'>
                                            <Container>
                                                <Row><p>{propertyList.length} FOUND</p></Row>
                                                {propertyList.map((listing, key) => {
                                                    console.log(listing.owner)
                                                    return (
                                                        <Row>
                                                            <Card className="favorite-card">
                                                                <Link to={{
                                                                    // pathname: '/property',
                                                                    propertyProps: listing.titile,
                                                                    currentProperty: listing
                                                                }} style={{ textDecoration: 'none', color: 'black' }}>

                                                                    <Row>
                                                                        <Col className='listing-image' style={{
                                                                            backgroundImage: `url(${listing.images ? listing.images[0] : ''})`,
                                                                        }} />
                                                                        <Col lg={5} className='favorite-card-content'>
                                                                            <Row>
                                                                                <h3>{listing.title}</h3>
                                                                            </Row>
                                                                            <Row >
                                                                                <p className='address'>{listing.location.address.replace(', undefined', '')}</p>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col>
                                                                                    <Rating
                                                                                        initialRating={listing.rating ? listing.rating : null}
                                                                                        emptySymbol="fa fa-star-o fa-2x"
                                                                                        fullSymbol="fa fa-star fa-2x"
                                                                                        fractions={2}
                                                                                        readonly={true}
                                                                                    />
                                                                                </Col>
                                                                                <Col>

                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <h4 className='favorite-price'>
                                                                                    ${Number.parseFloat(listing.description.price).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                                                </h4>

                                                                            </Row>
                                                                            <Row>
                                                                                <Button
                                                                                    size='sm'
                                                                                    onClick={() => remove(listing)}
                                                                                    variant='outline-danger'><AiFillDelete /> 
                                                                                </Button>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col style={{ textAlign: 'right', padding: '20px', marginLeft: '20px' }}>
                                                                            <Avator owner={listing.owner} />
                                                                        </Col>
                                                                        <Col sm={1} />

                                                                    </Row>
                                                                </Link>
                                                            </Card>
                                                        </Row>
                                                    )
                                                })}
                                            </Container>
                                        </div>
                                    )
                            }
                        </div>

                    )
                }
                <div>
                </div>
                <br />
            </Container>
        </div>
        //'https://www.w3schools.com/howto/img_avatar2.png'
    )
}


const mapStateToProps = (state) => {
    console.log(state)
    return { auth: state.firebase.auth, profile: state.firebase.profile, user: state.user.user, authSuccess: state.auth.authSuccess, jwtToken: state.auth.jwtToken }
}

export default connect(mapStateToProps)(MyFavorites)