import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Consultation from "./Consultation/Consultation";
import ContractorDirectory from "./Contractor/ContractorDirectory";
import Payment from "./Payment/Payment";
import PropertyListing from "./Property/PropertyListing/PropertyListing";
import PropertySearch from "./Property/PropertySearch/PropertySearch";
import MyProperties from "./Property/MyProperties/MyProperties";
import AddService from "./Services/AddService/AddService";
import MyOfferedServices from "./Services/MyOfferedServices/MyOfferedServices";
import AllBy from "./Property/AllBy/AllBy";
import MyFavorites from "./Property/MyFavorites/MyFavorites";
import Requests from "./Requests/Requests";
// import SubscriptionPlans from './Subscription/SubscriptionPlans'
import Login from "./User/Login/Login";
import UserProfile from "./User/UserProfile/UserProfile";
import NavigationBar from "./NavigationBar/NavigationBar";
import Footer from "./Footer/Footer";
import CompanyRegister from "./Company/CompanyRegister";
import Contractor from "./Contractor/Contractor";
import ContractorRegister from "./Contractor/ContractorRegister";
import Company from "./Company/Company";
import Buy from "./Buy/Buy";
import Sell from "./Sell/Sell";
import Valuation from "./Valuation/Valuation";
import ValuationFunction from "./Valuation/ValuationFunction";
import LocalConsult from "./Local Investor Consultant/LocalConsult";
import LocalConsultantRegister from "./Local Investor Consultant/LocalConsultantRegister";
import OpportunityZones from "./OpportunityZones/OpportunityZones";
import About from "./CMS/About/About";
import Careers from "./CMS/Careers/Careers";
import InvestFar from "./CMS/Investfar/Investfar";
import Contact from "./CMS/Contact/Contact";
import Privacy from "./CMS/Privacy/Privacy";
import Pricing from "./Pricing/Pricing";
import { NoMatch } from "./NoMatch/NoMatch";
import SubmitProperty from "./Property/SubmitProperty/SubmitProperty";
import EditProperty from "./Property/EditProperty/EditProperty";
import EditService from "./Services/EditService/EditService";

// import ReactNotification from "react-notifications-component";
// import "react-notifications-component/dist/theme.css";

import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import { connect } from "react-redux";
// import AddService from './Services/AddService/AddService';
import LegalForms from "./LegalForms/LegalForms";
import HomeImprovement from "./HomeImprovementNetwork/HomeImprovement";
import MyReviews from "./Property/Reviews/MyReviews";
import MyPurchaseHistory from "./Property/MyPurchaseHistory/MyPurchaseHistory";
import Notifications from "./A_NotifcationRemove/Notifications";
import mlsPropertySearch from "./Requests/mlsPropertySearch";
import OpenHomes from "./Requests/OpenHomes";
import FeaturedListing from "./Requests/FeaturedListing";
import OrganizerLogin from "./Requests/OrganizerLogin";
import CompareProperties from "./Property/CompareProperties/CompareProperties";
import success from "./Payment/success";
import checkout from "./Payment/checkout";
import Orders from "./Payment/orders";
import ForgotPassword from "./User/Login/ForgotPassword";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import LandingPage from "./LandingPage/LandingPage";
import Invest from "./Invest/Invest";

function App(props) {
  console.log("prrops===========>",props)
  const { auth } = props;

  return (
    <React.Fragment>
      <div className="page-container">
        <ReactNotifications />
        <div className="content-wrap">
          <Router>
            <NavigationBar />
            {auth.uid ? (
              <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/buy" component={Buy} />
                <Route exact path="/sell" component={Sell} />
                <Route exact path="/buy" component={MyProperties} />
                <Route exact path="/profile" component={UserProfile} />
                <Route exact path="/consultation" component={Consultation} />
                <Route exact path="/valuation" component={ValuationFunction} />
                <Route exact path="/company" component={Company} />
                <Route
                  exact
                  path="/company-register"
                  component={CompanyRegister}
                />
                <Route exact path="/contractor" component={Contractor} />
                <Route
                  exact
                  path="/contractor-register"
                  component={ContractorRegister}
                />
                <Route
                  exact
                  path="/local-investor-consultant"
                  component={LocalConsult}
                />
                <Route
                  exact
                  path="/local-investor-consultant-register"
                  component={LocalConsultantRegister}
                />
                <Route
                  exact
                  path="/ContractorDirectory"
                  component={ContractorDirectory}
                />
                <Route
                  exact
                  path="/OpportunityZones"
                  component={OpportunityZones}
                />
                <Route exact path="/about" component={About} />
                <Route exact path="/careers" component={Careers} />
                <Route exact path="/investfar" component={InvestFar} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/privacypolicy" component={Privacy} />
                <Route exact path="/pricing" component={Pricing} />
                <Route exact path="/payment" component={Payment} />
                {/* <Route exact path='/property/:item' render={(props) => <PropertyListing {...props} />} /> */}
                <Route exact path="/property" component={PropertyListing} />
                <Route
                  exact
                  path="/submit-property/"
                  component={SubmitProperty}
                />
                <Route exact path="/add-service/" component={AddService} />
                <Route
                  exact
                  path="/my-offered-services/"
                  component={MyOfferedServices}
                />
                <Route
                  exact
                  path="/edit-property/"
                  render={(props) => <EditProperty {...props} />}
                />
                <Route
                  exact
                  path="/edit-service/"
                  render={(props) => <EditService {...props} />}
                />
                <Route exact path="/my-properties/" component={MyProperties} />
                <Route exact path="/all-by/" component={AllBy} />
                <Route exact path="/my-reviews/" component={MyReviews} />
                {/* <Route exact path='/my-purchase-history/' component={MyPurchaseHistory} /> */}
                <Route exact path="/my-favorites" component={MyFavorites} />
                <Route exact path="/Requests" component={Requests} />
                <Route
                  exact
                  path="/mlspropertysearch"
                  component={mlsPropertySearch}
                />
                <Route exact path="/openhomes" component={OpenHomes} />
                <Route
                  exact
                  path="/featuredlisting"
                  component={FeaturedListing}
                />
                <Route
                  exact
                  path="/organizerlogin"
                  component={OrganizerLogin}
                />

                {/* <Route exact path='/SubscriptionPlans' component={SubscriptionPlans} /> */}
                <Route exact path="/consultation" component={Consultation} />
                <Route
                  exact
                  path="/forgot-password"
                  component={ForgotPassword}
                />
                <Route exact path="/legal" component={LegalForms} />
                <Route
                  exact
                  path="/homeimprovementnetwork"
                  component={HomeImprovement}
                />
                {/* <Route exact path='/notify' component={Notifications}/> */}
                {/* <Route exact path='/compare/:item' render={(props) => <CompareProperties {...props} />} /> */}
                <Route exact path="/compare" component={CompareProperties} />
                <Route exact path="/success" component={success} />
                <Route exact path="/checkout" component={checkout} />
                <Route exact path="/my-purchase-history/" component={Orders} />
                <Route exact path="/linkedin" component={LinkedInPopUp} />
                <Route exact path="/invest" component={Invest} />
                <Route component={NoMatch} />
              </Switch>
            ) : (
              <Switch>
                {/* Not Allowed Routes. Redirect to Login for now */}
                {/*************************************************/}
                {/*************************************************/}
                <Route exact path="/sell" component={Login} />
                <Route exact path="/buy" component={Login} />
                <Route exact path="/profile" component={Login} />
                <Route exact path="/edit-property/" component={Login} />
                <Route exact path="/my-properties/" component={Login} />
                <Route exact path="/all-by/" component={Login} />
                <Route exact path="/my-favorites" component={Login} />
                <Route exact path="/Payment" component={Login} />
                {/*************************************************/}
                {/*************************************************/}
                {/* Not Allowed Routes. Redirect to Login for now */}
                <Route
                  exact
                  path="/forgot-password"
                  component={ForgotPassword}
                />
                <Route exact path="/" component={LandingPage} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/consultation" component={Consultation} />
                <Route exact path="/valuation" component={Valuation} />
                <Route exact path="/company" component={Company} />
                <Route
                  exact
                  path="/company-register"
                  component={CompanyRegister}
                />
                <Route exact path="/contractor" component={Contractor} />
                <Route
                  exact
                  path="/contractor-register"
                  component={ContractorRegister}
                />
                <Route
                  exact
                  path="/local-investor-consultant"
                  component={LocalConsult}
                />
                <Route
                  exact
                  path="/local-investor-consultant-register"
                  component={LocalConsultantRegister}
                />
                <Route
                  exact
                  path="/ContractorDirectory"
                  component={ContractorDirectory}
                />
                <Route
                  exact
                  path="/OpportunityZones"
                  component={OpportunityZones}
                />
                <Route exact path="/about" component={About} />
                <Route exact path="/careers" component={Careers} />
                <Route exact path="/investfar" component={InvestFar} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/privacypolicy" component={Privacy} />
                <Route exact path="/pricing" component={Pricing} />
                {/* <Route exact path='/property/:item' render={(props) => <PropertyListing {...props} />} /> */}
                <Route exact path="/property" component={PropertyListing} />
                {/* <Route exact path='/submit-property/' component={SubmitProperty} /> */}
                <Route exact path="/Requests" component={Requests} />
                {/* <Route exact path='/SubscriptionPlans' component={SubscriptionPlans} /> */}
                <Route exact path="/consultation" component={Consultation} />
                {/* remove legal forms and home improvement out for prd */}
                <Route exact path="/legal" component={LegalForms} />
                <Route
                  exact
                  path="/homeimprovementnetwork"
                  component={HomeImprovement}
                />
                <Route exact path="/linkedin" component={LinkedInPopUp} />
                <Route exact path="/invest" component={Invest} />
                <Route component={NoMatch} />
              </Switch>
            )}
            <Footer />
          </Router>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    authError: state.auth.authError,
    authSuccess: state.auth.authSuccess,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
