import axios from 'axios';
import { getFirestore } from 'redux-firestore';
var request = require('request');

// var update = (profile, cb) => {
//     var options = {
//         'method': 'POST',
//         'url': `${process.env.REACT_APP_BACKEND}/users/update-profile`,
//         'headers': {
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({ "profile": profile })

//     };
//     request(options, function (error, response) {
//         console.log('Response', response)
//         var response = JSON.parse(response.body)
//         if (error) {
//             cb({ success: false, msg: String(error) })
//         } else {
//             if (response.success) {
//                 cb({ success: true })
//             }
//         }
//     });
// }

var update = (profile, cb) => {
    const firestore = getFirestore();

    firestore.collection('Users').doc(profile.user_Id).update({
        avatar_url: profile.avatar_url
    }).then(response => {
        console.log('Response', response)
        cb({ success: true })
    }).catch(error => {
        console.log('Experienced an error tying to update profile', String(error))
        cb({ success: false })
    })
}

export const uploadImages = (images, location, cb) => {
    return (dispatch, getState, { getFirestore, getFirebase, getDatabase }) => {
        var urls = []
        const firebase = getFirebase();
        const userId = firebase
            .auth()
            .currentUser
            .uid;
        images.map((image, idx) => {
            var uploadTask = firebase
                .storage()
                .ref('users/' + userId + `/${location}/` + image.name)
                .put(image);

            uploadTask.on('state_changed', (snapshot) => {

                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, (error) => {
                // Handle unsuccessful uploads
            }, () => {
                // Handle successful uploads on complete For instance, get the download URL:
                uploadTask
                    .snapshot
                    .ref
                    .getDownloadURL()
                    .then((downloadURL) => {
                        console.log('File available at', downloadURL);
                        urls.push(downloadURL)
                        if (idx == images.length - 1) {
                            cb(urls)
                        }
                    });
            });

        })
    }
};

export const updateProfile = (profile) => {
    return (dispatch, getState, { getFirestore, getFirebase, getDatabase }) => {
        // Make async call to database

        const firebase = getFirebase();
        const firestore = getFirestore();
        // const firestore = getDatabase()
        const userId = firebase
            .auth()
            .currentUser
            .uid;
        if (profile.profileImgData != null) {
            var uploadTask = firebase
                .storage()
                .ref('users/' + userId + '/profile.jpg')
                .put(profile.profileImgData);

            // Register three observers:
            // 1. 'state_changed' observer, called any time the state changes
            // 2. Error observer, called on failure
            // 3. Completion observer, called on successful completion
            uploadTask.on('state_changed', (snapshot) => {
                // Observe state change events such as progress, pause, and resume Get task
                // progress, including the number of bytes uploaded and the total number of bytes
                // to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, (error) => {
                // Handle unsuccessful uploads
                dispatch({ type: 'UPDATE_PROFILE_ERROR', error: error });
            }, () => {
                // Handle successful uploads on complete For instance, get the download URL:
                // https://firebasestorage.googleapis.com/...
                uploadTask
                    .snapshot
                    .ref
                    .getDownloadURL()
                    .then((downloadURL) => {
                        firestore.collection('Users').doc(userId).update({
                            avatar_url: downloadURL
                        }).then(response => {
                            console.log('Response', response)
                            return () => dispatch({ type: 'SIGNUP_SUCCESS' });
                        }).catch(error => {
                            console.log('Experienced an error tying to update profile', String(error))
                            return () => dispatch({ type: 'SIGNUP_ERROR', error: String(error) });
                        })
                    });
            });
        }

        // if(profile.profileImg != null){     firebase.storage().ref('users/' +
        // firebase.auth().currentUser.uid +
        // '/profile.jpg').put(profile.profileImgData).getDownloadURL().then((resp) => {
        //         console.log(resp);     }).catch(error => {         dispatch({ type:
        // 'UPDATE_PROFILE_ERROR'});     }) } dispatch({ type:
        // 'UPDATE_PROFILE_SUCCESS'});
    }
};