import React, { useEffect, useState } from 'react'
import { Form, FormControl, Button, Row, Col, Card, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import moment from 'moment';
import './Payment.css'

class Orders extends React.Component {
    state = {
        userEmail: '',
        orderList: '',
        Loading: '',
    }

    componentDidMount = () => {
        window.scrollTo(0, 0)
        const { profile, user } = this.props;
        this.setState({
            userEmail: profile.email
        })

        console.log('Current User', profile.email)
        axios.get(`/api/payments/orders?email=${profile.email}`).then(res => {
            if (res.data) {
                console.log("ORDER HISTORY: ", res.data.msg);
                this.setState({
                    orderList: (res.data.msg).sort(this.orderSort),
                    Loading: false
                })
            }
        }).catch((error) => {
            console.log("ORDER ERROR: ", error)
            this.setState({
                Loading: false
            })
        })
    }

    orderSort = (a,b) => {
        return new Date(moment(b.timestamp._seconds * 1000).format('MM/DD/YYYY hh:mm A')).getTime() - new Date(moment(a.timestamp._seconds * 1000).format('MM/DD/YYYY hh:mm A')).getTime(); 
    } 

    render() {
        return (
            <div>
                <Container fluid className="jumbo">
                    <Row>
                        <Col sm={5}>
                            <div className="jumbo-content-left">
                                <h2>MY PURCHASE HISTORY</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    {(this.state.orderList.length > 0) ? (
                        <Card className="orders-card">
                            <Card.Body >
                                <Row className="orders-card-header">
                                    <Col>Date</Col>
                                    <Col>ID</Col>
                                    <Col>Items</Col>
                                    <Col>Amount</Col>
                                </Row>
                                {this.state.orderList.map(order => (
                                    <Row className="orders-card-row">
                                        <Col>{moment(order.timestamp._seconds * 1000).format('MM/DD/YYYY hh:mm A')}</Col>
                                        <Col>{order.customer_id}</Col>
                                        <Col>{order.additional == '' ? order.plan : order.plan + ', ' + order.additional}</Col>
                                        <Col>${order.amount}</Col>
                                    </Row>
                                ))}
                            </Card.Body>
                        </Card>
                    ) : (<Container>
                        <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', padding: '100px' }}>
                            <Container>
                                <h4>NO PURCHASE HISTORY</h4>
                                <p></p>
                                <p></p>
                                <p>No Purchase History</p>                                                <p></p>
                                <p></p>
                            </Container>

                        </div>
                    </Container>)}
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        authSuccess: state.auth.authSuccess,
        jwtToken: state.auth.jwtToken,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(Orders)