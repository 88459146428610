import React from "react";
import { Button, Row, Col, Container, Card } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
class Company extends React.Component {
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  company = () => {
    return (
      <div classyName="company-page">
        <Container fluid className="jumbo">
          <Row>
            <Col sm={5}>
              <div className="jumbo-content-left">
                <h2>COMPANIES</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="company-body">
          <Row>
            <Col sm={4}>
              <Card className="company-image" />
            </Col>
            <Col className="company-content">
              <Card className="company-text">
                <p1>
                  Real Estate investing is multifaceted and with that there are
                  multiple avenues one can take in this industry, so here at
                  InvesFar we aim to simplify it for those in this field by
                  building a directory of companies we feel are most
                  advantageous to individuals looking to build their portfolio
                  locally and nationwide. We have also given companies the
                  option to add their own businesses to our directory if they
                  provide a service to the real estate industry. These companies
                  have to be vetted and approved before they are added to the
                  directory.
                  <br />
                  <br />
                  The directory ranges from Private Lenders, Property management
                  companies, Real Estate brokers, Business funding to Family
                  offices. As a real estate investing beginner or veteran the
                  directory contains a listing of companies for whatever stage
                  you are in within the business all in one hub. Under your
                  profile you can save a list of companies to your favorites so
                  you don't have to write the information down on something that
                  can be misplaced later.
                  <br />
                  <br />
                  Our goal is to make it easy for you to InvestFar and give you
                  a directory of nationwide companies to do it.
                </p1>
                <Link to="./login">
                  <Button className="btn-blue register-button">
                    Company Registration <BsArrowRight />
                  </Button>
                </Link>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  render() {
    require("./Company.css");
    return <this.company />;
  }
}

export default Company;
