import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import Avatar from 'react-avatar';



var divstyle = {
    textAlign: 'center',
    margin: 'auto',
    padding: '10px',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";'
}

var nameStyle = {
    margin: ' 0px',
    fontSize: '16px',
    lineHeight: '32px',
    maxWidth: '624px',
    fontWeight: '600',
    textAlign: 'center',
}
export default function Avator(props) {
    return (<Container>
        <div style={divstyle}>
            {props.owner.avator_url ?
                <img className='avatar' src={props.owner.avator_url} /> :
                <Avatar name={props.owner.name} size="80" round={true} />}
            <h3 style={nameStyle}>{props.owner.name}</h3>
        </div>
    </Container>)
}

// export default function Avator(props) {
//     return (<Container>
//         <div style={divstyle}>
//             <Avatar name="Wim Mostmans" size="100" />
//         </div>
//     </Container>)
// }