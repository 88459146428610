import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Slider from "react-slick";
import axios from "axios";
import { NavLink, useHistory } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import { Error, Success } from "../Alerts";

const LandingPage = (props) => {
  console.warn("HIIIII");
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [subscribeEmail, setSubscribeEmail] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const getListings = (cb) => {
    setLoading(true);
    axios
      .get(`/api/properties/most-recent`)
      .then((res) => {
        if (res.data) {
          var properties = [];
          try {
            console.log("Data is :", res.data);
            console.log("Type is:", typeof res.data);
            properties = res.data.msg;
            setLocationList(properties);
            setLoading(false);
          } catch (error) {
            console.log("Error", error);
            Error({
              title: "Error",
              message: "Could not load latest listings",
            });
            setLocationList();
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log("Error", JSON.stringify(error));
        Error({
          title: "Error Encountered",
          message: "Could not load latest listings",
        });
        setLocationList();
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getListings();
  }, []);

  var bannerSlider = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  var howItWorkSlider = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  var marketsPropertySlider = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const handleToggle = () => {
    setOpen(true);
  };

  const Subscribe = () => {
    if (!subscribeEmail) {
      Error({
        title: "Error",
        message: "Email is required.",
      });
    } else if (!regex.test(subscribeEmail)) {
      setSubscribeEmail("");
      Error({
        title: "Error",
        message: "Email is incorrect.",
      });
    } else {
      setSubscribeEmail("");
      Success({
        title: "Success",
        message: "You are successfully subscribed.",
      });
    }
  };

  const goToListing = (listing) => {
    history.push({
      pathname: "/property",
      search: `${listing.id}`,
      propertyProps: listing.name,
      currentProperty: listing,
    });
  };

  // if (auth.id) {
  //   history.goBack();
  // }

  return (
    <div className="newtheme">
      <header className="header-new">
        <div className="header-animation-img">
          <img
            src="images/header-img1.svg"
            alt=""
            className="img1 wow zoomIn"
          />
          <img
            src="images/header-img2.svg"
            alt=""
            className="img2 wow zoomIn"
          />
          <img
            src="images/header-img3.svg"
            alt=""
            className="img3 wow zoomIn"
          />
          <img
            src="images/header-img4.svg"
            alt=""
            className="img4 wow zoomIn"
          />
        </div>

        <div className="banner">
          <div className="row m-0">
            <div className="col-md-6 col-lg-5 col-xl-6">
              <div className="banner-info">
                <span>
                  Global Real Estate
                  <br /> Investing Is Here
                </span>
                <h3>
                  Remote Investing
                  <br />{" "}
                  <label className="d-inline-block d-md-none">
                    Made Safe and Easy
                  </label>{" "}
                  <label className="text-animation">Made Safe and Easy</label>
                </h3>
                <div className="app-download">
                <a
                    href="https://apps.apple.com/us/app/investfar-real-estate/id1327794180"
                    target="_blank"
                  >
                    <img src="images/App_Store_Badge.svg" alt="" />
                  </a> 
                  <a
                    href="https://play.google.com/store/apps/details?id=com.investfar"
                    target="_blank"
                  >
                    <img src="images/play-store-badge-en.svg" alt="" />
                  </a>
                  {/* <NavLink
                    to="/local-investor-consultant-register"
                    className="btn-blue btn"
                  >
                    Become An Investor
                  </NavLink>
                  <NavLink to="/pricing" className="btn-white btn">
                    Browse Properties
                  </NavLink> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-7 col-xl-6 pr-auto pr-md-0 position-relative">
              <div className="b-slider-dot-bg">
                <img
                  src="images/slider-img1.svg"
                  alt=""
                  className="img1 wow zoomIn"
                />
                <img
                  src="images/slider-img2.svg"
                  alt=""
                  className="img2 wow zoomIn"
                />
              </div>

              <Slider {...bannerSlider} className="banner-slider">
                <div className="banner-slider-item">
                  <img
                    src="images/banner1.jpg"
                    alt=""
                    className="img-fluid banner-pic"
                  />
                  <div className="banner-slider-thum">
                    <img
                      src="images/banner-thum1.jpg"
                      alt=""
                      className="banner-thum wow zoomIn"
                    />
                    <div className="banner-contractor wow fadeInUp">
                      <img
                        src="images/contractor.jpg"
                        alt=""
                        className="contractor-img"
                      />
                      <div className="contractor-detail">
                        <h3>
                          General
                          <br /> Contractor
                        </h3>
                        <p>San Leandro,</p>
                        <label htmlFor="">$8,975</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="banner-slider-item">
                  <img
                    src="images/banner2.jpg"
                    alt=""
                    className="img-fluid banner-pic"
                  />
                  <div className="banner-slider-thum">
                    <img
                      src="images/banner-thum1.jpg"
                      alt=""
                      className="banner-thum"
                    />
                    <div className="banner-contractor">
                      <img
                        src="images/contractor.jpg"
                        alt=""
                        className="contractor-img"
                      />
                      <div className="contractor-detail">
                        <h3>
                          General
                          <br /> Contractor
                        </h3>
                        <p>San Leandro,</p>
                        <label htmlFor="">$8,975</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="banner-slider-item">
                  <img
                    src="images/banner3.jpg"
                    alt=""
                    className="img-fluid banner-pic"
                  />
                  <div className="banner-slider-thum">
                    <img
                      src="images/banner-thum3.jpg"
                      alt=""
                      className="banner-thum"
                    />
                    <div className="banner-contractor">
                      <img
                        src="images/contractor.jpg"
                        alt=""
                        className="contractor-img"
                      />
                      <div className="contractor-detail">
                        <h3>
                          General
                          <br /> Contractor
                        </h3>
                        <p>San Leandro,</p>
                        <label htmlFor="">$8,975</label>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </header>
      <div className="container">
        <div className="how-it-work-section">
          <div className="row">
            <div className="col-md-6 wow fadeInLeft">
              <Slider
                {...howItWorkSlider}
                className="howItWork-slider slider-custom-dots"
              >
                <div className="howItWork-slider-item">
                  <img
                    src="images/how-it-slider1.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="howItWork-slider-item">
                  <img
                    src="images/how-it-slider2.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="howItWork-slider-item">
                  <img
                    src="images/how-it-slider3.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="howItWork-slider-item">
                  <img
                    src="images/how-it-slider4.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="howItWork-slider-item">
                  <img
                    src="images/how-it-slider5.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </Slider>
            </div>
            <div className="col-md-6">
              <div className="sectionTitle wow fadeInDown">How It Works</div>
              <ul className="howItList">
                <li className="wow fadeInUp">
                  <div className="howItIcon">
                    <img
                      src="images/icon-investment.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="howItDetail">
                    <h3>Set Investment Goals</h3>
                    <p>
                      Set clear investment portfolio goals then decide what
                      asset type and strategy are right for you. Once defined,
                      search desired markets to Find, Acquire, Renovate, Sell or
                      Manage your portfolio by creating a profile on the
                      InvestFar app.
                    </p>
                  </div>
                </li>
                <li className="wow fadeInUp" data-wow-delay="0.4s">
                  <div className="howItIcon">
                    <img
                      src="images/icon-property-agent.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="howItDetail">
                    <h3>Submit Your Property</h3>
                    <p>
                      Property owners list for free. Sell us or an investor your
                      house, cash for keys instead of commission fees.{" "}
                    </p>
                  </div>
                </li>
                <li className="wow fadeInUp" data-wow-delay="0.8s">
                  <div className="howItIcon">
                    <img
                      src="images/icon-user-network.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="howItDetail">
                    <h3>Build Your Network</h3>
                    <p>
                      Meet investment consultants, home sellers and investors
                      globally! Connect with real estate pros anywhere and build
                      your dream real estate team remotely.
                    </p>
                  </div>
                </li>
                <li className="wow fadeInUp" data-wow-delay="1.2s">
                  <div className="howItIcon">
                    <img
                      src="images/icon-advisory-services.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="howItDetail">
                    <h3>Advisory Services</h3>
                    <p>
                      Schedule a virtual or phone meeting with you or your team
                      to go over Deal Structuring, Financing or Mastermind a
                      growth strategy.{" "}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="services-section">
          <div className="row">
            <div className="col-md-6">
              <div className="service-providers">
                <div className="sectionTitle wow fadeInDown">
                  Service Providers
                </div>
                <p className="wow fadeInDown" data-wow-delay="0.5s">
                  At InvestFar we aim to simplify real estate investing by
                  building a directory of companies we feel are most
                  advantageous to investors building their portfolio. We’ve also
                  given companies the option to add their own businesses to our
                  directory, if they provide a service to the real estate
                  sector. Companies must be vetted before being added to the
                  directory.
                </p>
                <div
                  className="text-center text-md-left ml-md-0 wow fadeInDown"
                  data-wow-delay="1s"
                >
                  <NavLink to="/company-register" className="btn btn-blue">
                    Register Company
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="services-list">
                <div className="services-animation">
                  <img
                    src="images/service.png"
                    alt=""
                    className="service-img wow zoomIn"
                  />
                </div>
                <div className="services-category">
                  <img
                    src="images/services-category1.png"
                    alt=""
                    className="img1 wow fadeInLeft"
                    data-wow-delay="0.4s"
                  />
                  <img
                    src="images/services-category2.png"
                    alt=""
                    className="img2 wow fadeInLeft"
                    data-wow-delay="0.8s"
                  />
                  <img
                    src="images/services-category3.png"
                    alt=""
                    className="img3 wow fadeInRight"
                    data-wow-delay="1.2s"
                  />
                  <img
                    src="images/services-category4.png"
                    alt=""
                    className="img4 wow fadeInRight"
                    data-wow-delay="1.6s"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="service-option">
            <div className="row">
              <div className="col-md-6">
                <div className="service-option-box wow fadeInUp">
                  <div className="service-icon">
                    <img
                      src="images/icon-service-providers1.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="service-option-detail">
                    <h3>Make Predictive Decisions</h3>
                    <p>
                      With our home valuation tool, make a more informed
                      decision with our predictive data analytic valuation
                      report, which supplies you with a 3 year property value
                      forecast, 1 year risk of decline and rental value. Pay one
                      low monthly fee for unlimited or pay per report.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="service-option-box wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <div className="service-icon">
                    <img
                      src="images/icon-service-providers2.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="service-option-detail">
                    <h3>Safe and Easy Transactions</h3>
                    <p>
                      Easily and safely buy or sell properties through our
                      payments gateway, which verifies each party in the
                      transaction automatically once all legal forms have been
                      signed via docusign through InvestFar directly to the
                      title companies or attorney. Even escrow service provider
                      cost for release once job completion is verified.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="service-option-box wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  <div className="service-icon">
                    <img
                      src="images/icon-service-providers3.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="service-option-detail">
                    <h3>Streamline With One Source</h3>
                    <p>
                      Use Advisory Services and take the guesswork out of
                      investing remotely. Schedule a consultation, book a
                      contractor, rehab, property manage or list to sell.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="service-option-box wow fadeInUp"
                  data-wow-delay="1.2s"
                >
                  <div className="service-icon">
                    <img
                      src="images/icon-service-providers4.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="service-option-detail">
                    <h3>LiveChat</h3>
                    <p>
                      Our live chat feature allows you to communicate with
                      buyers, sellers, tenants and management to immediately
                      respond on any subject matter or build your network of
                      investors internationally. Receive regular updates on what
                      needs to be done with your properties directly from
                      tenants.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="service-option-box wow fadeInUp"
                  data-wow-delay="1.6s"
                >
                  <div className="service-icon">
                    <img
                      src="images/icon-service-providers5.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="service-option-detail">
                    <h3>Receive World Class Service</h3>
                    <p>
                      Work with expert real estate investment advisors and
                      service providers carefully vetted by InvestFar.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="service-option-box wow fadeInUp"
                  data-wow-delay="2s"
                >
                  <div className="service-icon">
                    <img
                      src="images/icon-service-providers6.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="service-option-detail">
                    <h3>Simplified Reporting</h3>
                    <p>
                      Investors can keep records of all the invoices for service
                      and maintenance work on each property and communication
                      with tenants. Service providers can schedule, cancel and
                      manage appointment request.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="investing-section">
        <div className="container">
          <div className="row px-0 px-lg-5">
            <div className="col-md-6">
              <div className="sectionTitle wow fadeInLeft">
                Remote Investing
                <br /> In 2 minutes or less.
              </div>
            </div>
            <div className="col-md-6">
              <div className="investing-video wow fadeInRight">
                <img
                  src="images/investing-video-thum.jpg"
                  alt=""
                  className="img-fluid"
                />
                <span onClick={handleToggle} className="video-playicon">
                  <img
                    src="images/icon-play.svg"
                    alt=""
                    className="img-fluid"
                  />
                </span>
                {/* Modal */}
                <Modal
                  show={open}
                  onHide={() => setOpen(false)}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  className="investingModal"
                >
                  <Modal.Body>
                    <video
                      className="investing-video-iframe"
                      controls
                      autoPlay
                      loop
                    >
                      <source
                        src="images/investfar_video.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="property-sell">
          <div className="row">
            <div className="col-md-6">
              <div className="property-sell-picture wow fadeInLeft">
                <img
                  src="images/property-sell.svg"
                  alt=""
                  className="img-fluid"
                />
                <img
                  src="images/icon-dollar.svg"
                  alt=""
                  className="icon-dollar"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="property-sell-info wow fadeInRight">
                <div className="sectionTitle">
                  Sell Your Home Without Commission Fees
                </div>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    Brokers/Agents charge 3%-6% just to sell your property. We
                    charge 0%. Sell today, to us or our community and more for
                    free.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="investing-process">
        <div className="container wow fadeInUp">
          <div className="sectionTitle">Getting Started Is Simple</div>
          <p className="mt-2 mt-xl-3 mb-0">
            InvestFar is an all-in-one marketplace created for real estate
            investors with one thing in mind, keep
            <br /> everything simple, accessible and safe for longevity
            investing.
          </p>
          <div className="row mt-4 mt-lg-5">
            <div className="col-md-4">
              <div className="investing-flow">
                <div className="investing-process-icon">
                  <img src="images/icon-investing-process1.svg" alt="" />
                </div>
                <h3>Let us know your preference.</h3>
                <p>Use Smart Investing to tailor investment recommendations.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="investing-flow">
                <div className="investing-process-icon">
                  <img src="images/icon-investing-process2.svg" alt="" />
                </div>
                <h3>Choose the type of investor you are.</h3>
                <p>
                  Select New investor, Apprentice or Investors Club strategy to
                  meet your goals.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="investing-flow">
                <div className="investing-process-icon">
                  <img src="images/icon-investing-process3.svg" alt="" />
                </div>
                <h3>Start building your portfolio</h3>
                <p>
                  Manage and grow your real estate holdings from anywhere in the
                  world in one place.
                </p>
              </div>
            </div>
            <div className="col-md-12 mt-0 mt-md-4 mt-lg-5">
              <NavLink to="/pricing" className="btn-blue btn">
                Get Started
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="markets-property text-center">
        <div className="container">
          <div className="sectionTitle wow zoomIn" data-wow-delay="0.4s">
            Consider Our Main Markets
          </div>
          <p className="mt-2 mt-xl-3 mb-0 wow zoomIn" data-wow-delay="0.8s">
            Find your next investment property with diversification and
            performance on InvestFar in over 30 markets
          </p>
          {loading === false ? (
            <>
              {locationList.length > 0 ? (
                <Slider
                  {...marketsPropertySlider}
                  className="markets-property-slider slider-custom-dots wow zoomIn"
                  data-wow-delay="1.2s"
                >
                  {locationList.map((item, index) => (
                    <div
                      className="mp-slider-item"
                      onClick={() => goToListing(item)}
                    >
                      <img src={item.images[0]} alt="" />
                      <span>
                        {item.location && item.location.full
                          ? item.location.full.replace(", undefined", "")
                          : item.location && item.location.address
                          ? item.location.address.replace(", undefined", "")
                          : ""}
                      </span>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div className="py-5">No Record Found</div>
              )}
            </>
          ) : (
            <div className="landing_loader">
              <HashLoader color="#1c75bc" size={55} />
            </div>
          )}
          <div>
            <NavLink to="/pricing" className="btn-blue btn">
              Browse Properties
            </NavLink>
          </div>
        </div>
      </div>
      <div className="property-deals">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="property-deal-category property-deal-agent wow fadeInLeft">
                <h3>Real Estate Agent</h3>
                <p>
                  Are you a real estate agent that’s an expert in your market
                  and tired of competing with thousandsof other agents? We want
                  you as a consultant, only 5 agents per market.
                </p>
                <div className="d-flex">
                  <NavLink to="/login" className="btn-blue btn">
                    Register
                  </NavLink>
                  <NavLink to="/login" className="btn-blue btn">
                    Log On
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="property-deal-category property-deal-owner wow fadeInRight">
                <h3>Property Owner</h3>
                <p>
                  Aren’t you tired of losing 3%-6% of your equity in your assets
                  just to sell them?
                </p>
                <div className="d-flex">
                  <NavLink to="/sell" className="btn-blue btn">
                    Sell Us Your Property
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="subscribe-section">
          <div className="sectionTitle">Stay Connected</div>
          <p className="mb-0 mt-1">Get Latest News and Updates</p>
          <div className="subscribe-form">
            <img src="images/icon-email.svg" alt="" />
            <input
              type="email"
              placeholder="Email Address"
              className="form-control"
              value={subscribeEmail}
              onChange={(e) => setSubscribeEmail(e.target.value)}
            />
            <button className="btn btn-blue" onClick={Subscribe}>
              Subscribe
            </button>
          </div>
        </div>
      </div>

      <span className="scroll-top-arrow">
        <i className="fas fa-angle-up" />
      </span>
      {/*  */}
    </div>
  );
};

export default LandingPage;
