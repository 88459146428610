import React from "react";
import {
InputGroup,
Form,
FormControl,
Dropdown,
DropdownButton,
Card,
Button,
Row,
Col,
Container,
} from "react-bootstrap";
import { FiDownload } from "react-icons/fi";
import { Link,useHistory,Route } from "react-router-dom";
import LandingPage from "../../src/LandingPage/LandingPage";

import Slider from "react-slick";
import axios from "axios";
import { Error, Primary, Success, Info, Warning } from "../Alerts";
import firebase from "firebase";

const nodemailer = require('nodemailer');

let transporter = nodemailer.createTransport({
   host: "smtp.gmail.com",
   port: 465,
   auth: {
     user: "investfarfund@gmail.com", 
     pass: "IFglobal#1" 
   }
 });
 console.log("transporter========>",transporter)
class Invest extends React.Component {
   

   constructor(props) {
      super(props);
      this.submitValuation1 = this.submitValuation1.bind(this);
      this.state = {
            model: false,
            model2:false,
            submitData:false,
            howItWorkSlider:{
                  arrows: false,
                  dots: true,
                  infinite: true,
                  speed: 500,
                  fade: true,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  autoplay: true,
                  autoplaySpeed: 3000,
            },
            investortype: "Regular",
            email:'',
            fullname:'',
            phone:'',
            firstname:'',
            lastname:''
      };
   }

   componentDidMount = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setTimeout(() => {
         this.setState({model:true})
         document.body.classList.add('modal-open');
      }, 1000);
   }

   changeHandler = (event) => {
      this.setState({
      [event.target.name]: event.target.value,
      });
   };

   openInNewTab = (url) => {
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
   };

   closepopup = () => {
      this.setState({model:false})
      this.setState({investortype: "Regular",email:'',fullname:'',phone:'',firstname:'',lastname:''});
      document.body.classList.remove('modal-open');
   };

   submitValuation1 = () => {
      this.setState({model2:false})
         document.body.classList.add('documentMessage');
         let path = `/`;
    this.props.history.push(path);
 
   }
   
   submitValuation = async(req,resp) => {
      this.setState({
         submitData:true
      })
         // if(pramval=='form'){
      //    alert(this.state.firstname);
      //    alert(this.state.lastname);
      //    alert(this.state.email);
      //    alert(this.state.investortype);
      //    alert(pramval);
      // }else{
      //    alert(this.state.fullname);
      //    alert(this.state.phone);
      //    alert(this.state.email);
      //    alert(this.state.investortype);
      //    alert(pramval);
      // }
    axios
      .post("https://us-central1-investfar-b494a.cloudfunctions.net/signUpMail", {
         name: `${this.state.fullname}`,
         firstname: `${this.state.firstname}`,
         lastname: `${this.state.lastname}`,
         phone: `${this.state.phone}`,
         email: `${this.state.email}`,
         investor_type: `${this.state.investortype}`
      })
      .then((response) => {
         this.setState({
            submitData:false
         })
         this.setState({model:false})
         document.body.classList.remove('modal-open');
         this.setState({investortype: "Regular",email:'',fullname:'',phone:'',firstname:'',lastname:''});
         
         
      if (response) {
         console.log(response);
         this.setState({model2:true})
         document.body.classList.add('documentMessage'); 
         // Info({
         //    title: "Invest Email Sent!",
         //    message:
         //    "An InvestFar representative will be in contact with you shortly! ",
         // });
      }
      })
      .catch((error) => {
         console.log(error);
         Error({
            title: "Error Encountered",
            message: "Error encountered when sending Invest Email!",
         });
      });
   }



      invest = () => {
      return (
      <div className="invest-page">
         <Container fluid className="jumbo">
            <Row>
               <Col sm={5}>
               <div className="jumbo-content-left">
                  <h2>INVEST IN INVESTFAR</h2>
               </div>
               </Col>
            </Row>
         </Container>
         <div className="invest-body">
            <div className="invest-text pt-5">
               <div class="container">
                  <div class="row">
                     <div class="col-lg-8">
                        <div class="investleft">
                           <h1>Global Real Estate Investing Is Here. <br></br> Remote Investing Made Safe & Easy</h1>
                           <div class="image-invest"><img src="images/Buyshares.jpg"/></div>
                           <div class="invest-boxable">
                              <h4>InvestFar is the first all-in-one marketplace created for real estate investors with one thing in mind, keep everything simple, accessible and safe for longevity investing.</h4>
                              <p>Our mission is to unlock the  <b>$10.5 trillion Global Real Estate Investment Market</b> so anyone regardless of social class or economic status can invest in real estate based where and what makes sense for them financially.
                                 InvestFar’s marketplace allows regular everyday people, service providers, both accredited and unaccredited investors to gain exposure and generate income from exclusive off market properties located in multiple destinations around the world,  <b>the first marketplace to do so.</b>
                              </p>
                              <p>We believe that  <b>real estate investing is fundamentally broken</b>. The barrier for entry is becoming increasingly more difficult as returns are rapidly shrinking, cost of living is on the rise making it challenging to save enough money to invest locally and the perception that it’s only for the rich. <b> Our goal is to significantly reduce as many barriers as possible to become a real estate investor</b> , while pioneering an entirely new category of real estate investing remote investing.</p>
                           </div>
                           <div class="image-invest"><img src="images/medellin.jpg"/></div>
                           <div class="invest-boxable">
                              <p>InvestFar allows newbie or veteran investors to gain exposure to an untouched market due to properties not listed with agents, in other countries and connect with investors anywhere in the world. Commission Free. Real estate historically has been available to a select few, due to limited knowledge, availability and access. Traditional investing models require you to have high credit scores, 20%-30% down, paying an agent just to send you a property, tons of experience and requiring tax returns and tons of paperwork with minimum investments nowadays as much as $375k+. </p>
                              <p>Not only that, but real estate investing also hasn’t been social, it’s been seen as very exclusive even though many are interested, they fear making a mistake or needing to know someone already doing or paying exorbitant fees for expert advice. Typically it also involves sourcing for the properties, managing the repairs and upkeep of the properties, scheduling and paying for cleaning services, and host of other time consuming things online.</p>
                           </div>
                           <div class="image-invest"><img src="images/why-broken.jpg"/></div>
                           <div class="invest-boxable">
                              <h4>Why InvestFar? </h4>
                              <p>InvestFar is an easy-to-use experience for  <b>any member who’s looking to invest remotely</b> where numbers make sense for them.</p>
                              <p><b>Individual real estate investment portfolios around the world, by the end of 2020, directly measured was $2.3 trillion.</b> InvestFar makes remote investing safe and easy by providing all the tools.</p>
                              <p>But why invest remotely? As cost of living and home prices are rising coupled with remote work, people are looking for other places to relocate out of expensive cities and even countries. Investing in markets that are seeing an influx in remote workers or digital nomads provide opportunities for vacation rentals and properties being paid down or off by tenants while generating income. <b>Vacation rentals generate up to 160% more revenue on average than traditional long-term rentals!</b></p>
                           </div>
                           <div class="image-invest"><img src="images/why-vacation.jpg"/></div>
                           <div class="invest-boxable">
                              <p>Members browse InvestFar’s marketplace, if they have questions on how to structure or finance the deal, they can use our advisory services or if they want to do an inspection before purchasing, they can order an interior and exterior inspection. But if they are ready to buy without assistance, click buy now or make an offer and close remotely from their home or office. Once purchased  <b>our teams can take care of the rehab, selling or refinancing after the property is fully rehabbed, and turn it into a vacation rental.</b> Our members sit back, relax, and collect passive income on their new vacation properties. </p>
                           </div>
                           <div class="image-invest"><img src="images/handleimg.jpg"/></div>
                           <div class="invest-boxable">
                              <h4>How it Works</h4>
                           </div>
                           <div className="how-it-work-section invest-how-it-work">
                              <div className="col-md-6 wow">
                                 <Slider
                                    {...this.state.howItWorkSlider}
                                    className="howItWork-slider slider-custom-dots">
                                    <div className="howItWork-slider-item">
                                       <img
                                          src="images/how-it-slider1.png"
                                          alt=""
                                          className="img-fluid"
                                          />
                                    </div>
                                    <div className="howItWork-slider-item">
                                       <img
                                          src="images/how-it-slider2.png"
                                          alt=""
                                          className="img-fluid"
                                          />
                                    </div>
                                    <div className="howItWork-slider-item">
                                       <img
                                          src="images/how-it-slider3.png"
                                          alt=""
                                          className="img-fluid"
                                          />
                                    </div>
                                    <div className="howItWork-slider-item">
                                       <img
                                          src="images/how-it-slider4.png"
                                          alt=""
                                          className="img-fluid"
                                          />
                                    </div>
                                    <div className="howItWork-slider-item">
                                       <img
                                          src="images/how-it-slider5.png"
                                          alt=""
                                          className="img-fluid"
                                          />
                                    </div>
                                 </Slider>
                              </div>
                           </div>
                           <div class="invest-boxable">
                              <h4>Competition</h4>
                              <p>While there have been several Fintech incumbents entering the real estate investment market, there is no other product focused exclusively on remote investing, not to mention members own <b>full rights</b> to the property instead of fractional or partial investing. <b>ALL</b> the returns and revenue are yours <b>with no 5-year catches or stipulations or 3%-6% commission fees.</b> </p>
                           </div>
                           <div class="image-invest"><img src="images/competition.jpg"/></div>
                           <div class="invest-boxable">
                              <h4>Why InvestFar Wins</h4>
                              <p>InvestFar changes the traditional model of real estate investing. Our members have exclusive access to off market properties, international reach, social investing, get the tax benefits of owning a home and in some cases residency in another country.</p>
                           </div>
                           <div class="image-invest"><img src="images/whywewin.jpg"/></div>
                           <div class="invest-boxable">
                              <h4>But Why Now?</h4>
                              <p>With the rise of “fractional ownership” models, we want to lower the barriers to entry for investors who want access to full ownership of real estate property in the largest asset class in the world. We want to give access to the investors who don’t want just part of a deal but all of the deal. </p>
                              <p>InvestFar is positioned to be the  <b>outright winner</b> in a market that we see as being one of the  <b>largest emerging focus in Fintech and Proptech</b>  over the next decade or more.</p>
                              <p>Our target markets in global real estate investment were $10.5 trillion at the start of 2021. </p>
                              <p><b>Americas $4.1T, EMEA $3.6T and APAC $2.8T </b> <br></br>
                                 We anticipate that micro segments in these markets will continue to <b>grow beyond their 3.4% annual growth rate</b> as Millennials and Gen Z continue to drive travel demand in a “post-covid” world.
                              </p>
                           </div>
                           <div class="image-invest"><img src="images/whynow.jpg"/></div>
                           <div class="invest-boxable">
                              <h4>Our Business Model</h4>
                              <p>InvestFar is able to <b>drive revenue throughout our entire customer lifecycle.</b></p>
                              <p>Our team has been driving revenue from day one and throughout the relationship with our “members,” all the way to the time of closing the properties. <b>95% of our members return for any number of our products or services and refer new members.</b> </p>
                              <p>We source revenue from <b>four different categories:</b></p>
                              <ul>
                                 <li><b>Subscription Fees:</b>  This fee is based on monthly investor plans, New Investor, Active Investor or Portfolio builder. These plans are designed to meet our members where they are on their investing journey and provide value beyond properties offered on the platform and vary from person to person. This fee covers services such as our property acquisition and financial structuring calls, discount on due diligence services, rehab project completion monitoring, joint ventures on deal, real estate transactions and legal documents & property analytic reports.</li>
                                 <li><b>Financial Services Fee:</b>  We collect a 2%-4% fee on any loans we originate for members to purchase investment properties from our marketplace or properties not listed on our marketplace. Our average loan size is $295k and our fees range between $3,500-$68,000 for one financial service transaction. Our fee is based on the total asset value of each property we finance. The fee is paid out at the closing of each property transaction. This fee covers services such as asset underwriting, advisory services, deal structuring, due diligence services and investor relations.</li>
                                 <li><b>Ancillary Service Fees:</b>  We make it easy for members to acquire properties remotely with less risk as we provide all the tools and resources for due diligence to analyze properties before purchases. From interior/exterior property inspections, valuation reports, mobile notary, virtual or remote closings all the way to advisory services for deal structuring, deal financing and even a mastermind call with your company team members. This fee covers the cost associated with staff members providing these benefits for seamless remote closing services.</li>
                                 <li><b>Distressed Asset Purchases:</b>  We acquire properties that are in distress which range from pre-foreclosures, short sales, motivated sellers or properties that are off market requiring value add. Once purchased we rehab the properties and list them on the market for sale. In addition, some of the properties we rehab are sold to hedge funds or institutions looking to acquire turnkey properties for their clients. We have net income based on our acquisition price, less the renovation cost based on what the after-repair value sold price is minus any fees. This fee is paid out at the closing of each property whenever we exit. This fee covers services such as property acquisition, contractor fees, asset holding cost, project management and other operational responsibilities.</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-4 invest-side-bar-form">
                        <Form method="POST">
                           <div class="right-side">
                              <h6>"Secure Your Place in InvestFar Real Estate app: Reserve Your Position Today"</h6>
                              <div class="text-center PositionsLimited"> 
                                 <label for="">Positions Are Limited</label> 
                              </div>
                              <div class="form-group">
                                 <label for="phone" class="">First Name</label>
                                 <input onChange={this.changeHandler} type="text" id="firstname" name="firstname"/>
                              </div>
                              <div class="form-group">
                                 <label for="lastname">Last Name</label>
                                 <input  onChange={this.changeHandler} type="text" id="lastname" name="lastname"  />
                              </div>
                              <div class="form-group">
                                 <label for="email">Email Address</label>
                                 <input onChange={this.changeHandler} type="email" id="email" name="email" autocomplete="email" />
                              </div>
                              <div class="form-group">
                                 <label for="email">What type of investor are you?</label>
                                 <div class="checkboxform">
                                    <label for="bordered-radio-1">
                                    <input onChange={this.changeHandler} type="radio" checked id="bordered-radio-1" value="Regular" name="investortype"/>
                                    <span>Regular</span>
                                    </label>
                                    <label for="bordered-radio-2">
                                    <input onChange={this.changeHandler} type="radio" id="bordered-radio-2" value="Accredited" name="investortype" />
                                    <span>Accredited</span>
                                    </label>
                                 </div>
                              </div>
                              <div class="Disclaimer">
                                 <label for="Disclaimer">Disclaimer</label>  
                                 <p>
                                    NO MONEY OR OTHER CONSIDERATION IS BEING SOLICITED, AND IF SENT IN RESPONSE, WILL NOT BE ACCEPTED. NO OFFER TO BUY THE SECURITIES CAN BE ACCEPTED AND NO PART OF THE PURCHASE PRICE CAN BE RECEIVED UNTIL THE OFFERING STATEMENT IS FILED AND ONLY THROUGH AN INTERMEDIARY’S PLATFORM. AN INDICATION OF INTEREST INVOLVES NO OBLIGATION OR COMMITMENT OF ANY KIND. "RESERVING" SECURITIES IS SIMPLY AN INDICATION OF INTEREST.
                                 </p>
                              </div>
                              <button disabled={this.state.submitData}  onClick={()=> this.submitValuation('form')} type="button" class="btn"><strong>
                        {this.state.submitData ? "Sign Me Up" : "Sign Me Up"}
                      </strong></button>
                              {/* <ul>
                                 <li><a href="#"><img src="images/facebook.png"/></a></li>
                                 <li><a href="#"><img src="images/twiter.png"/></a></li>
                                 <li><a href="#"><img src="images/insta.png"/></a></li>
                                 <li><a href="#"><img src="images/in.png"/></a></li>
                              </ul>  */}
                           </div>
                        </Form>
                     </div>
                  </div>
                  <div class="row buttom-section">
                     <div class="col-lg-12">
                        <div class="investleft">
                           <h1>RAPID SCALE-UP TIMELINE</h1>
                        </div>
                     </div>
                  </div>
                  <div class="row lastsection">
                     <div class="col-lg-4">
                        <div class="investleft text-center">
                           <h1>2023</h1>
                        </div>
                        <p><b>Expand Platform Services</b></p>
                        <div class="invest-boxable">
                           <ul>
                              <li>Add ancillary services support for commercial real estate</li>
                           </ul>
                           <p><b>Grow International Markets*</b></p>
                           <ul>
                              <li>Latin America</li>
                              <li>Southern Europe</li>
                           </ul>
                           <p><b>Form Strategic Local Partnerships</b></p>
                           <ul>
                              <li>Real estate investment associations, Meetup groups & REI clubs</li>
                              <li>BiggerPockets.com Partnership</li>
                           </ul>
                           <p><b>Reach New Users & Agents</b></p>
                           <ul>
                              <li>Exhibits at conventions that draw combined annual attendees of 200K+</li>
                              <li>10M-viewer digital advertising campaign: AdWords; AdRoll; Social Media; Remarketing; Targeted Mobile Ads</li>
                           </ul>
                        </div>
                     </div>
                     <div class="col-lg-4">
                        <div class="investleft text-center">
                           <h1>2024</h1>
                        </div>
                        <p><b>Add New Revenue Stream</b></p>
                        <div class="invest-boxable">
                           <ul>
                              <li>Sell digital ads on platform</li>
                           </ul>
                           <p><b>Grow International Markets*</b></p>
                           <ul>
                              <li>Southeast Asia</li>
                           </ul>
                           <p><b>Reach New Users & Agents</b></p>
                           <ul>
                              <li>Exhibits at conventions that draw combined annual attendees of 200K+</li>
                              <li>75M-viewer digital advertising campaign</li>
                           </ul>
                        </div>
                     </div>
                     <div class="col-lg-4">
                        <div class="investleft text-center">
                           <h1>2025</h1>
                        </div>
                        <p><b>IPO</b></p>
                        <div class="invest-boxable">
                           <ul>
                              <li>Nasdaq</li>
                           </ul>
                           <p><b>Add New Revenue Stream</b></p>
                           <ul>
                              <li>Investment education products</li>
                              <li>“Google” for real estate </li>
                           </ul>
                           <p><b>Grow International Markets*</b></p>
                           <ul>
                              <li>Caribbean Islands</li>
                              <li>75M-viewer digital advertising campaign</li>
                           </ul>
                           <p><b>Reach New Users & Agents</b></p>
                           <ul>
                              <li>Exhibits at conventions that draw combined annual attendees of 200K+</li>
                              <li>200M-viewer digital advertising campaign</li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         { this.state.model &&  
         <div class="modal-main">
            <div class="modal-dialog" role="document">
               <div class="modal-content">
                  <button type="button" onClick={this.closepopup} class="close closemodel" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                  </button>
                  <div class="text-center click-proceed"> <a href="#"> </a></div>
                  <div class="modal-body pt-0">
                     <div>
                        <Form method="post">
                           <div class="form-group">
                              <label for="phone" class="">Full Name</label>
                              <input type="text" class="" onChange={this.changeHandler}  placeholder="Full Name" id="fullname" name="fullname"/>
                           </div>
                           <div class="form-group">
                              <label for="phone" class="">Phone</label>
                              <input type="tel" onChange={this.changeHandler}  class="" placeholder="Phone Number" id="phone" name="phone"/>
                              <div class="flag-dropdown ">
                                 <div class="selected-flag" title="India: + 91" tabindex="0" role="button" aria-haspopup="listbox">
                                    <div class="flag in">
                                       <div class="arrow"></div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="form-group">
                              <label for="email">Email Address</label>
                              <input type="email" onChange={this.changeHandler}  id="email" name="email" placeholder="Email Address" autocomplete="email" />
                           </div>
                           <div class="form-group">
                              <label for="email">What type of investor are you?</label>
                              <div class="checkboxform">
                                 <label for="bordered-radio1-1">
                                 <input onChange={this.changeHandler} checked type="radio"  id="bordered-radio1-1" value="Regular" name="investortype"/>
                                 <span>Regular</span>
                                 </label>
                                 <label for="bordered-radio2-2">
                                 <input onChange={this.changeHandler} type="radio" id="bordered-radio2-2" value="Accredited" name="investortype" />
                                 <span>Accredited</span>
                                 </label>
                              </div>
                              <div class="Disclaimer">
                                 <label for="Disclaimer">Disclaimer</label>  
                                 <p>
                                    NO MONEY OR OTHER CONSIDERATION IS BEING SOLICITED, AND IF SENT IN RESPONSE, WILL NOT BE ACCEPTED. NO OFFER TO BUY THE SECURITIES CAN BE ACCEPTED AND NO PART OF THE PURCHASE PRICE CAN BE RECEIVED UNTIL THE OFFERING STATEMENT IS FILED AND ONLY THROUGH AN INTERMEDIARY’S PLATFORM. AN INDICATION OF INTEREST INVOLVES NO OBLIGATION OR COMMITMENT OF ANY KIND. "RESERVING" SECURITIES IS SIMPLY AN INDICATION OF INTEREST.
                                 </p>
                              </div>
                           </div>
                           <div class="accreditedlink text-center">
                              <a target="_blank" href="https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3" rel="noreferrer">
                                 <span>What is an accredited investor?</span>
                                 <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M14 1H2a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1V2a1 1 0 00-1-1zM2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2H2z" clip-rule="evenodd"></path>
                                    <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"></path>
                                    <circle cx="8" cy="4.5" r="1"></circle>
                                 </svg>
                              </a>
                           </div>
                           <div class="learmorebtn">
                              <button disabled={this.state.submitData} onClick={()=> this.submitValuation('model')} type="button"><strong>
                        {this.state.submitData ? "Sign Me Up" : "Sign Me Up"}
                      </strong></button>
                              <ul>
                                 <li><a target="_blank" href="https://investfar.com/privacypolicy" >Privacy Policy</a></li>
                                 <li><a target="_blank" href="https://investfar.com/privacypolicy">Terms &amp; Conditions</a></li>
                              </ul>
                           </div>
                        </Form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         }
         { this.state.model2 &&  
            <div class="modal-main learmore-modal">
                  <div class="modal-dialog modal-dialog-centered" role="documentMessage">
                     <div className="modal-body">
                        <div class="learmorebtn learmore-modal">
                           <span>Welcome to being a part of the future of real estate investing. You’ll receive an email soon with next steps.</span>
                           <button onClick={()=> this.submitValuation1('model')} type="button" className="mt-3 px-5 rounded">Ok</button>
                        </div>
                     </div>
                  </div>
               </div>
         }
      </div>
      );
      };
      render() {
         require("./Invest.css");
            return <this.invest />;
         }
      }
   
      export default Invest;
